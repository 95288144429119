import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Menu from './Menu';
import '../styles/AgregaIncidencias.css'; 

const AgregarIncidencias = () => {
    const navigate = useNavigate();
    const [tipoIncidencia, setTipoIncidencia] = useState('');
    const [afectaDisponibilidad, setAfectaDisponibilidad] = useState('');
    const [piso, setPiso] = useState('-5');
    const [equipo, setEquipo] = useState('');
    const [userName, setUserName] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [idu, setIdu] = useState(''); 
	const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decodedToken = JSON.parse(atob(token.split('.')[1]));
            const nombre = decodeURIComponent(escape(decodedToken.nombre));
            const apellido = decodeURIComponent(escape(decodedToken.apellido));
            setIdu(decodeURIComponent(escape(decodedToken.idu))); 
            setUserName(`${nombre} ${apellido}`);
        } else {
            navigate('/'); 
        }
    }, [navigate]); 
	
	const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/'); // Cambia esto
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen); 
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        const response = await fetch(`${process.env.REACT_APP_API_ADD_INCI}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                id_usuario: idu, 
                tipoincisala: tipoIncidencia,
                dispoincisala: afectaDisponibilidad, 
                pisoincisala: piso,
                equipoincisala: equipo,
                descripincisala: descripcion,
                edificincisala: 'TITANIUM',
            }),
        });
        
        if (response.ok) {
            alert('Incidencia creada con éxito');
            const lugar = localStorage.getItem('lugar'); 
			if (lugar === 'TITANIUM'){
				navigate('/titanium'); 
			} else if(lugar === 'CORPORATIVO'){
				navigate('/corporativo'); 
			} else if(lugar === 'TODOSE'){
				navigate('/admin'); 
			}else {
				navigate('/zona-norte'); 
			}
        } else {
            alert('Error al crear la incidencia, por favor intenta nuevamente');
        }
    };

    return (
        <div className="agregar-container">
            <Menu userName={userName} isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} handleLogout={handleLogout} />
            <main className="content">
                <div className="dashboard-container">
					<div className="form-container" >
						<h2>Crear Incidencia - Edificio Titanium</h2>
						
						<form onSubmit={handleSubmit}>
							<label>
								Tipo de incidencia:
								<select value={tipoIncidencia} onChange={(e) => setTipoIncidencia(e.target.value)} required>
									<option value="">Seleccionar</option>
									<option value="AGUA POTABLE">AGUA POTABLE</option>
									<option value="ASCENSORES">ASCENSORES</option>
									<option value="CERRAJERÍA">CERRAJERÍA</option>
									<option value="CLIMA">CLIMA</option>
									<option value="CORTINAS">CORTINAS</option>
									<option value="ELECTRICIDAD">ELECTRICIDAD</option>
									<option value="GASFITERÍA">GASFITERÍA</option>
									<option value="ILUMINACIÓN">ILUMINACIÓN</option>
									<option value="INCENDIOS">INCENDIOS</option>
									<option value="MOBILIARIO">MOBILIARIO</option>
									<option value="LOCKERS">LOCKERS</option>
									<option value="JARDINERÍA">JARDINERÍA</option>
									<option value="SISTEMA SANITARIO">SISTEMA SANITARIO</option>
									<option value="OBRA CIVIL">OBRA CIVIL</option>
									<option value="OBRAS MENORES">OBRAS MENORES</option>
								</select>
							</label>

							<label>
								Afecta disponibilidad:
								<select value={afectaDisponibilidad} onChange={(e) => setAfectaDisponibilidad(e.target.value)} required>
									<option value="">Seleccionar</option>
									<option value="SI">SI</option>
									<option value="NO">NO</option>
								</select>
							</label>

							<label>
								PISO:
								<select value={piso} onChange={(e) => setPiso(e.target.value)} required>
									{Array.from({ length: 30 }, (_, i) => (
										<option key={i} value={i - 5}>{i - 5}</option>
									))}
								</select>
							</label>

							{tipoIncidencia === 'ASCENSORES' ? (
								<label>
									Equipo:
									<select value={equipo} onChange={(e) => setEquipo(e.target.value)} required>
										<option value="">Seleccionar</option>
										<option value="ASCENSOR A">ASCENSOR A</option>
										<option value="ASCENSOR B">ASCENSOR B</option>
										<option value="ASCENSOR C">ASCENSOR C</option>
										<option value="ASCENSOR D">ASCENSOR D</option>
										<option value="ASCENSOR E">ASCENSOR E</option>
										<option value="ASCENSOR F">ASCENSOR F</option>
										<option value="ASCENSOR G">ASCENSOR G</option>
										<option value="ASCENSOR H">ASCENSOR H</option>
										<option value="ASCENSOR PANORAMICO">ASCENSOR PANORAMICO</option>
									</select>
								</label>
							) : (
								<label>
									Equipo:
									<input
										type="text"
										value={equipo}
										onChange={(e) => setEquipo(e.target.value)}
										placeholder="Ingrese el equipo"
									/>
													   </label>
							)}

							<label>
								Descripción incidencia:
								<input
									type="text"
									value={descripcion}
									onChange={(e) => setDescripcion(e.target.value)}
									placeholder="Descripción de la incidencia" required
								/>
							</label>
							<input type="hidden" name="edificio" value="TITANIUM"/>
							<button type="submit" className="btn-create-incidencia">Crear Incidencia</button>
						</form>
				</div>			
            </div>
			</main>
        </div>
		
    );
};

export default AgregarIncidencias;	