import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../images/logo_grande.png'; // Asegúrate de que la ruta sea correcta
import '../styles/Menu.css';

const Menu = ({ userName, isMenuOpen, toggleMenu, handleLogout }) => {
    const navigate = useNavigate();
    const lugar = localStorage.getItem('lugar');
    const tipo = localStorage.getItem('tipo');

    // Redirección si no hay lugar en localStorage
    useEffect(() => {
        if (!lugar) {
            navigate('/');
        }
    }, [lugar, navigate]);

    return (
        <header className="header">
            <div className="logo">
                <img src={logo} alt="Logo" />
            </div>
            <div className="menu-icon" onClick={toggleMenu}>
                <i className={`fas ${isMenuOpen ? 'fa-times' : 'fa-bars'}`}></i>
            </div>
            <nav className={`navbar ${isMenuOpen ? 'open' : ''}`}>
                <ul>
                    {lugar === 'TODOSE' && (
                        <>
                            <li><Link to="/admin"><i className="fas fa-exclamation-circle"></i> Incidencias</Link></li>
                            <li><Link to="/equipos"><i className="fas fa-cogs"></i> Equipos</Link></li>
                            <li><Link to="/gantt"><i className="fas fa-calendar-check"></i> Gantt</Link></li>
                            <li><Link to="/disponibilidad"><i className="fas fa-circle-check"></i> Disponibilidad</Link></li>
                            <li><Link to="/extraprogramaticos"><i className="fas fa-clipboard-list"></i> Extraprogramáticos</Link></li>
                        </>
                    )}
                    {lugar === 'TITANIUM' && (
                        <>
                            <li><Link to="/titanium"><i className="fas fa-exclamation-circle"></i> Incidencias</Link></li>
                            <li><Link to="/equipos"><i className="fas fa-cogs"></i> Equipos</Link></li>
                            <li><Link to="/disponibilidad"><i className="fas fa-circle-check"></i> Disponibilidad</Link></li>
                            <li><Link to="/extraprogramaticos"><i className="fas fa-clipboard-list"></i> Extraprogramáticos</Link></li>
                        </>
                    )}
                    {lugar === 'CORPORATIVO' && (
                        <>
                            <li><Link to="/corporativo"><i className="fas fa-exclamation-circle"></i> Incidencias</Link></li>
                            <li><Link to="/disponibilidad"><i className="fas fa-circle-check"></i> Disponibilidad</Link></li>
                            <li><Link to="/extraprogramaticos"><i className="fas fa-clipboard-list"></i> Extraprogramáticos</Link></li>
                        </>
                    )}
                    {tipo === 'TECNICO' && (
                        <>
                            <li><Link to="/zona-norte"><i className="fas fa-exclamation-circle"></i> Incidencias</Link></li>
                            <li><Link to="/equipos-zn"><i className="fas fa-cogs"></i> Equipos</Link></li>
                            <li><Link to="/disponibilidad"><i className="fas fa-circle-check"></i> Disponibilidad</Link></li>
                        </>
                    )}
                </ul>
            </nav>
            <div className="user-info">
                <i className="fa-solid fa-user"></i>&nbsp;<span>{userName}</span>
                <button className="logout-button" onClick={handleLogout}><i className="fas fa-sign-out-alt"></i> Salir</button>
            </div>
        </header>
    );
};

export default Menu;
