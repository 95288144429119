import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Menu from './Menu';
import '../styles/AgregaIncidencias.css'; 

const AgregarMantenimiento = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const location = useLocation(); 
    const queryParams = new URLSearchParams(location.search); 
    const checklist = queryParams.get('tipo'); 
	const [userName, setUserName] = useState('');
    const [tipoMantenimiento, setTipoMantenimiento] = useState('');
    const [observacionInicial, setObservacionInicial] = useState('');
    const [fotoInicial, setFotoInicial] = useState(null);
    const [idu, setIdu] = useState(''); 
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [mantenimientoIniciado, setMantenimientoIniciado] = useState(false);
    const [idcheck, setIdCheck] = useState('');
	const [fotoLuminaria, setFotoLuminaria] = useState(null); 
	const [fotoBombas, setFotoBombas] = useState(null); 
	const [fotoEstanque, setFotoEstanque] = useState(null); 
	const [fotoEstanqueRegistro, setFotoRegistroEstanque] = useState(null); 	
	const [fotoInfraestructura, setFotoInfraestructura] = useState(null); 
	const [fotoGasfiteria, setFotoGasfiteria] = useState(null); 
	const [fotoTableros, setFotoTableros] = useState(null);
	const [fotoExtintores, setFotoExtintores] = useState(null);	
	const [fotoTermoelectrico, setFotoTermoelectrico] = useState(null);	
	const [fotoMontaplatos, setFotoMontaplatos] = useState(null);	
	const [fotoPuertasEmergenciaNorte, setFotoPuertasEmergenciaNorte] = useState(null);	
	const [fotoPuertasEmergenciaSur, setFotoPuertasEmergenciaSur] = useState(null);	
	const [fotoLimpiezaEstanque, setFotoLimpiezaEstanque] = useState(null);	
	const [fotoElectrodomesticos, setFotoElectrodomesticos] = useState(null);
	const [fotoPuertasGiratorias, setFotoPuertasGiratorias] = useState(null);
	const [fotoPuertasAutomaticas, setFotoPuertasAutomaticas] = useState(null);	
	const [fotoPuertasAbatibles, setFotoPuertasAbatibles] = useState(null);	
	const [fotoLavado, setFotoLavado] = useState(null);	
	const [fotoLinterior, setFotoLinterior] = useState(null);	
    const [fotoLcuadro, setFotoLcuadro] = useState(null);	
    const [fotoLfiltros, setFotoLfiltros] = useState(null);	
    const [fotoLbandeja, setFotobandeja] = useState(null);	
    const [fotoLalabes, setFotoLalabes] = useState(null);	
    const [fotoLrejillas, setFotoLrejillas] = useState(null);		
	
	
    const [checklistBombas, setChecklistBombas] = useState({
        carcaza_motor: '',
        exterior_impulsor: '',
        caneria_impulsion: '',
        sala_bombas: '',
        funcionamiento_correcto: '',
        ausencia_filtaciones_bombas: '',
        amortiguadores_vibracion: '',
        ruido_anormales: '',
        operacion_valvulas_corte: '',
        estado_juntas_flexibles: '',
        cambios_elementos_defectuosos: '',
        funcionamiento_valvulas_retencion: '',
        estado_bombas: '',
        observaciones_bombas: '',
        foto_bombas: null,
    });
    const [checklistIluminaria, setChecklistIluminaria] = useState({
        pantalla: '',
        difusor: '',
        lamparas: '',
        cuerpo_equipo: '',
        estado_enchufes: '',
        estado_lamparas: '',
        estado_difusor: '',
        estado_pantalla: '',
        estado_cuerpo_equipo: '',
        estado_conexiones_electricas: '',
        estado_ballasts: '',
        estado_puntos_electricos: '',
        estado_base_lamparas: '',
        conexiones: '',
        componentes_defectuosos: '',
        estado_luminaria: '',
        observaciones_luminaria: '',
        foto_luminaria: null,
    });
    const [checklistEstanque, setChecklistEstanque] = useState({
        estanque: '',
		piping: '',
		valvulas: '',
		manometros: '', 
		presion_retorno: '',
		estado_estanque_exp: '', 
		observaciones_estanque_exp: '', 
		foto_estanque: null, 
		foto_registro_exp: null 
    });
	
	const [checklistInfraestructura, setChecklistInfraestructura] = useState({
		puerta_escalera_emergencia: '',
		portones_revision_lubricacion: '',
		puertas_acceso_engrase: '',
		puertas_acceso_aplica: '',
		cajon_percheros: '',
		inspeccion_mobiliario: '',
		piso_alfombras: '',
		marmol_ceramica: '',
		estado_general_cielo: '',
		revision_estado_pinturas: '',
		aperturas_cables: '',
		separador_modulos: '',
		espacio_almacenamiento: '',
		base_estable: '',
		reposapies: '',
		cajones_funcionales: '',
		perchero_espacio_abrigos: '',
		estado_infraestructura:'',
		observaciones_infraestructura:'',
		foto_infraestructura: null,
	});

	const [checklistGasfiteria, setChecklistGasfiteria] = useState({
		aireadores: '',
		boton_fluxometro: '',
		cuerpo_fluxometro: '',
		lubricacion_piezas_moviles: '',
		ajuste_cuerpo_wc_lavamanos: '',
		ajuste_fisico_boton_panel: '',
		ajuste_parametros_control_remoto: '',
		griferia: '',
		llave_automatica_vanitorio: '',
		trizaduras: '',
		valvula_descarga_inodoros: '',
		funcionamiento_llave_paso: '',
		flexibles: '',
		ausencia_filtaciones: '',
		bateria_transformador: '',
		mariposa_llave: '',
		registro_vertical_aguas_servidas: '',
		estado_gasfiteria:'',
		observaciones_gasfiteria:'',
		foto_gasfiteria: null,
	});
	
	const [checklistTableros, setChecklistTableros] = useState({
		interior_con_soplador: '',
		instrumentos_medicion: '',
		dispositivos_interiores: '',
		contactos_electricos: '',
		equipo_zona_general: '',
		comunicacion_centralizado: '',
		estado_transformadores: '',
		conexiones_electricas_borneras: '',
		activacion_test_breaker: '',
		activacion_protector_termico: '',
		indicadores_luz_piloto: '',
		chapa_puerta: '',
		conexiones_electricas: '',
		contactores_protectores_termicos: '',
		instrumentos_medicionr: '',
		consumo_breaker_general_fase1: '',
		consumo_breaker_general_fase2: '',
		consumo_breaker_general_fase3: '',
		voltaje_entre_fases_rs: '',
		voltaje_entre_fases_rt: '',
		voltaje_entre_fases_st: '',
		voltaje_entre_rn: '',
		voltaje_entre_sn: '',
		voltaje_entre_tn: '',
		voltaje_salida_transformador: '',
		foto_tableros: null,
	});

	const [checklistExtintores, setChecklistExtintores] = useState({
		esta_certificado: '',
		desmontaje_exterior: '',
		manometro: '',
		gatillo_percutor: '',
		etiqueta_modo_uso: '',
		extintor_en_lugar: '',
		completamente_cargado: '',
		acceso_libre_obstrucciones: '',
		sello_seguridad: '',
		pasador_seguridad: '',
		pintura_buen_estado: '',
		cilindro_presenta_oxidacion: '',
		manguera_roturas: '',
		corneta_presenta_fisuras: '',
		valvula_presenta_danos: '',
		calcomanias_legibles: '',
		foto_extintores: null,
	});
	
	const [checklistTermoelectrico, setChecklistTermoelectrico] = useState({
		componentes_electricos: '',
		termo: '',
		voltaje_parametros: '',
		corriente_parametros: '',
		resistencia: '',
		foto_termoelectrico: null,
	});

	const [checklistMontaplatos, setChecklistMontaplatos] = useState({
		archivo_proveedor: null,
	});

	const [checklistLimpiezaEstanques, setChecklistLimpiezaEstanques] = useState({
		lavado_completo_estanque_limpieza: '',
		sanitizacion_hipoclorito_limpieza: '',
		extraccion_lodo_residuos_limpieza: '',
		observaciones_limpieza_estanques: '',
		estado_limpieza_estanques: '',
		foto_limpieza_estanque: null,
	});
	
	const [checklistPuertasEmergenciaNorte, setChecklistPuertasEmergenciaNorte] = useState({
		norte_piso_1: '',
		norte_piso_3: '',
		norte_piso_4: '',
		norte_piso_5: '',
		norte_piso_6: '',
		norte_piso_7: '',
		norte_piso_8: '',
		norte_piso_9: '',
		norte_piso_10: '',
		norte_piso_11: '',
		norte_piso_12: '',
		norte_piso_13: '',
		norte_piso_14: '',
		norte_piso_15: '',
		norte_piso_16: '',
		norte_piso_17: '',
		norte_piso_18: '',
		norte_piso_19: '',
		norte_piso_20: '',
		norte_piso_21: '',
		norte_piso_22: '',
		norte_piso_23: '',
		norte_piso_s2: '',
		norte_piso_s3: '',
		norte_piso_s4: '',
		norte_piso_s5: '',
	});

	const [checklistPuertasEmergenciaSur, setChecklistPuertasEmergenciaSur] = useState({
		surhall_piso_1: '',
		surhall_piso_3: '',
		surhall_piso_4: '',
		surhall_piso_5: '',
		surhall_piso_6: '',
		surhall_piso_7: '',
		surhall_piso_8: '',
		surhall_piso_9: '',
		surhall_piso_10: '',
		surhall_piso_11: '',
		surhall_piso_12: '',
		surhall_piso_13: '',
		surhall_piso_14: '',
		surhall_piso_15: '',
		surhall_piso_16: '',
		surhall_piso_17: '',
		surhall_piso_18: '',
		surhall_piso_19: '',
		surhall_piso_20: '',
		surhall_piso_21: '',
		surhall_piso_22: '',
		surhall_piso_23: '',
		surcaja_piso_1: '',
		surcaja_piso_3: '',
		surcaja_piso_4: '',
		surcaja_piso_5: '',
		surcaja_piso_6: '',
		surcaja_piso_7: '',
		surcaja_piso_8: '',
		surcaja_piso_9: '',
		surcaja_piso_10: '',
		surcaja_piso_11: '',
		surcaja_piso_12: '',
		surcaja_piso_13: '',
		surcaja_piso_14: '',
		surcaja_piso_15: '',
		surcaja_piso_16: '',
		surcaja_piso_17: '',
		surcaja_piso_18: '',
		surcaja_piso_19: '',
		surcaja_piso_20: '',
		surcaja_piso_21: '',
		surcaja_piso_22: '',
		surcaja_piso_23: '',
	});

	const [checklistPuertasAbatibles, setChecklistPuertasAbatibles] = useState({
		limpieza_quicio_hidraulico: '',
		inspeccion_funcionamiento_quicio_hidraulico: '',
		ajuste_tiradores: '',
		ajuste_gomas: '',
		estado_puertas_abatibles: '',
		observaciones_puertas_abatibles: '',
		foto_puertas_abatibles: null,
	});

	const [checklistPuertasGiratorias, setChecklistPuertasGiratorias] = useState({
		limpieza_superficies: '',
		lubricacion_partes_moviles: '',
		revisar_cables: '',
		revisar_sistema_electrico: '',
		revisar_vidrios_panelex: '',
		inspeccionar_estructura_soporte: '',
		estado_puertas_giratorias: '',
		observaciones_puertas_giratorias: '',
		foto_puertas_giratorias: null,
	});

	const [checklistPuertasAutomaticas, setChecklistPuertasAutomaticas] = useState({
		revisar_rodamientos: '',
		lubricacion_partes_moviles: '',
		revisar_cables: '',
		revisar_sistema_electrico: '',
		inspeccionar_cristales_paneles: '',
		estado_puertas_automaticas: '',
		observaciones_puertas_automaticas: '',
		foto_puertas_automaticas: null,
	});
	
	const [checklistClimaInterior, setChecklistClimaInterior] = useState({
		lavado: "",
        foto_lavado: null,
        linterior: "",
        foto_linterior: null,
        lcuadro: "",
        foto_lcuadro: null,
        lfiltros: "",
        foto_lfiltros: null,
        reapriete: "",
        chequeopcv: "",
        chequeoamortigua: "",
        lbandeja: "",
        foto_lbandeja: null,
        lalabes: "",
        foto_lalabes: null,
        lrejillas: "",
        foto_lrejillas: null,
        drenajes: "",
        aislacion: "",
        rodamiento: "",
        termostato: "",
        electrico: "",
        voltaje: "",
        inyeccion: "",
        caudal1: "",
        caudal2: "",
        caudal3: "",
        rotulacion: "",
        obs_lavado: "",
        obs_linterior: "",
        obs_lcuadro: "",
        obs_lfiltros: "",
        obs_reapriete: "",
        obs_chequeopcb: "",
        obs_chequeoamorti: "",
        obs_lbandeja: "",
        obs_lalabes: "",
        obs_lrejillas: "",
        obs_drenajes: "",
        obs_aislacion: "",
        obs_rodamiento: "",
        obs_termostato: "",
        obs_general: "",
        estado_climaint: "",
		
	});
	
	
	const [checklistElectrodomesticos, setChecklistElectrodocmesticos] = useState({
		archivo_proveedor_electrodomesticos: null,
	});
	
	
	
	
	
	
	useEffect(() => {
		console.log('Estado de checklsit actualizado:', checklistClimaInterior);
	}, [checklistClimaInterior]);
	
	
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decodedToken = JSON.parse(atob(token.split('.')[1]));
            const nombre = decodeURIComponent(escape(decodedToken.nombre));
            const apellido = decodeURIComponent(escape(decodedToken.apellido));
            setIdu(decodeURIComponent(escape(decodedToken.idu))); 
            setUserName(`${nombre} ${apellido}`);
        } else {
            navigate('/'); 
        }
        checkMantenimientoIniciado();
    }, [navigate]); 
	
	const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/'); // Cambia esto
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen); 
    };

    const checkMantenimientoIniciado = async () => {
        const token = localStorage.getItem('token'); 
        const formData = new FormData();
        formData.append('id_equipo', id);
        formData.append('checklist', checklist);
        
        try {
            const response = await fetch(`${process.env.REACT_APP_API_REACT_ESTADO_MANTENIMIENTO_EQUIPO}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            });

            if (response.ok) {
                const data = await response.json();
                if (data && data.data) {
                    setMantenimientoIniciado(data.data.mantenimientoIniciado); 
                    setIdCheck(data.data.id_check);
					console.error('se asigna idcheck', data.data.id_check);
                } else {
                    console.error('No se recibieron datos válidos');
                }
            } else {
                const errorData = await response.json();
                console.error('Error al verificar el estado del mantenimiento:', errorData.message || 'Error desconocido');
                alert('Error al verificar el estado del mantenimiento: ' + (errorData.message || 'Error desconocido'));
            }
        } catch (error) {
            console.error('Error en la solicitud:', error);
            alert('Error en la solicitud: ' + error.message);
        }
    };

    const handleSubmitMantenimiento = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token'); 
		console.log('checklist de envío'+checklist)
        const formData = new FormData();
        formData.append('id_usuario', idu);
        formData.append('id_equipo', id);
        formData.append('tipo_mantenimiento', tipoMantenimiento);
        formData.append('notas_inicio', observacionInicial);
        formData.append('archivo', fotoInicial);
		formData.append('checklist', checklist);
		

        const response = await fetch(`${process.env.REACT_APP_API_REACT_MANTENIMIENTO_EQUIPO_INICIO}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
				
            },
            body: formData,
        });

        if (response.ok) {
            alert('Mantenimiento iniciado con éxito');
            checkMantenimientoIniciado();
        } else {
            alert('Error al iniciar el mantenimiento, por favor intenta nuevamente');
        }
    };

    const handleChecklistChange = (e) => {
		const { name, value, type } = e.target; 

		if (type === 'file') {
			const file = e.target.files[0]; 
			if (file) {

				
				if (checklist === 'BOMBAS') {
					setChecklistBombas(prevState => ({
						...prevState,
						[name]: file, 
					}));
				} else if (checklist === 'ILUMINARIA') {
					setChecklistIluminaria(prevState => ({
						...prevState,
						[name]: file, 
					}));
				} else if (checklist === 'ESTANQUE EXPANCION') {
					setChecklistEstanque(prevState => ({
						...prevState,
						[name]: file, 
					}));
				} else if (checklist === 'INFRAESTRUCTURA') {
					setChecklistInfraestructura(prevState => ({
						...prevState,
						[name]: file, 
					}));
				} else if (checklist === 'GASFITERIA') {
					setChecklistGasfiteria(prevState => ({
						...prevState,
						[name]: file, 
					}));
				} else if (checklist === 'EXTINTORES') {
					setChecklistExtintores(prevState => ({
						...prevState,
						[name]: file, 
					}));
				} else if (checklist === 'TABLEROS') {
					setChecklistTableros(prevState => ({
						...prevState,
						[name]: file, 
					}));
				} else if (checklist === 'TERMOELECTRICO') {
					setChecklistTermoelectrico(prevState => ({
						...prevState,
						[name]: file, 
					}));
				} else if (checklist === 'MONTAPLATOS') {
					setChecklistMontaplatos(prevState => ({
						...prevState,
						[name]: file, 
					}));
				} else if (checklist === 'ESCALERA EMERGENCIA SUR') {
					setChecklistPuertasEmergenciaSur(prevState => ({
						...prevState,
						[name]: file, 
					}));
				} else if (checklist === 'ESCALERA EMERGENCIA NORTE') {
					setChecklistPuertasEmergenciaNorte(prevState => ({
						...prevState,
						[name]: file, 
					}));	
				} else if (checklist === 'LIMPIEZA ESTANQUE') {
					setChecklistLimpiezaEstanques(prevState => ({
						...prevState,
						[name]: file, 
					}));
				} else if (checklist === 'ELECTRODOMESTICOS') {
					setChecklistElectrodocmesticos(prevState => ({
						...prevState,
						[name]: file, 
					}));
				} else if (checklist === 'PUERTA GIRATORIA') {
					setChecklistPuertasGiratorias(prevState => ({
						...prevState,
						[name]: file, 
					}));
				} else if (checklist === 'PUERTAS AUTOMATICAS') {
					setChecklistPuertasAutomaticas(prevState => ({
						...prevState,
						[name]: file, 
					}));
				} else if (checklist === 'PUERTA ABATIBLE') {
					setChecklistPuertasAbatibles(prevState => ({
						...prevState,
						[name]: file, 
					}));
				} else if (checklist === 'Unidad Interior') {
					setChecklistClimaInterior(prevState => ({
						...prevState,
						[name]: file, 
					}));
				}
				
				
			}
		} else {
		   
			if (checklist === 'BOMBAS') {
				setChecklistBombas(prevState => ({
					...prevState,
					[name]: value, 
				}));
			} else if (checklist === 'ILUMINARIA') {
				setChecklistIluminaria(prevState => ({
					...prevState,
					[name]: value, 
				}));
			} else if (checklist === 'ESTANQUE EXPANCION') {
				setChecklistEstanque(prevState => ({
					...prevState,
					[name]: value, 
				}));
			} else if (checklist === 'INFRAESTRUCTURA') {
				setChecklistInfraestructura(prevState => ({
					...prevState,
					[name]: value, 
				}));
			} else if (checklist === 'GASFITERIA') {
				setChecklistGasfiteria(prevState => ({
					...prevState,
					[name]: value, 
				}));
			} else if (checklist === 'TABLEROS') {
				setChecklistTableros(prevState => ({
					...prevState,
					[name]: value, 
				}));
			}else if (checklist === 'EXTINTORES') {
				setChecklistExtintores(prevState => ({
					...prevState,
					[name]: value, 
				}));
			} else if (checklist === 'TERMOELECTRICO') {
				setChecklistTermoelectrico(prevState => ({
					...prevState,
					[name]: value, 
				}));
			} else if (checklist === 'MONTAPLATOS') {
				setChecklistMontaplatos(prevState => ({
					...prevState,
					[name]: value, 
				}));
			} else if (checklist === 'ESCALERA EMERGENCIA NORTE') {
				setChecklistPuertasEmergenciaNorte(prevState => ({
					...prevState,
					[name]: value, 
				}));
			} else if (checklist === 'ESCALERA EMERGENCIA SUR') {
				setChecklistPuertasEmergenciaSur(prevState => ({
					...prevState,
					[name]: value, 
				}));	
			} else if (checklist === 'LIMPIEZA ESTANQUE') {
				setChecklistLimpiezaEstanques(prevState => ({
					...prevState,
					[name]: value, 
				}));
			} else if (checklist === 'ELECTRODOMESTICOS') {
				setChecklistElectrodocmesticos(prevState => ({
					...prevState,
					[name]: value, 
				}));
			} else if (checklist === 'PUERTA GIRATORIA') {
				setChecklistPuertasGiratorias(prevState => ({
					...prevState,
					[name]: value, 
				}));
			} else if (checklist === 'PUERTAS AUTOMATICAS') {
				setChecklistPuertasAutomaticas(prevState => ({
					...prevState,
					[name]: value, 
				}));
			} else if (checklist === 'PUERTA ABATIBLE') {
				setChecklistPuertasAbatibles(prevState => ({
					...prevState,
					[name]: value, 
				}));
			} else if (checklist === 'Unidad Interior') {
				setChecklistClimaInterior(prevState => ({
					...prevState,
					[name]: value, 
				}));
			}
			
		}
	};

    const handleSubmitChecklist = async (e) => {
		e.preventDefault();
		const token = localStorage.getItem('token'); 
		const formData = new FormData(); 


		formData.append('id_equipo', id);
		formData.append('id_usuario', idu);
		formData.append('checklist', checklist);
		formData.append('id_check', idcheck);

		
		if (checklist === 'BOMBAS') {
			Object.entries(checklistBombas).forEach(([key, value]) => {
				formData.append(key, value); 
			});
		} else if (checklist === 'ILUMINARIA') {
			Object.entries(checklistIluminaria).forEach(([key, value]) => {
				formData.append(key, value); 
			});
		} else if (checklist === 'ESTANQUE EXPANCION') {
			Object.entries(checklistEstanque).forEach(([key, value]) => {
				formData.append(key, value); 
			});
		} else if (checklist === 'GASFITERIA') {
			Object.entries(checklistGasfiteria).forEach(([key, value]) => {
				formData.append(key, value); 
			});
		} else if (checklist === 'INFRAESTRUCTURA') {
			Object.entries(checklistInfraestructura).forEach(([key, value]) => {
				formData.append(key, value); 
			});
		} else if (checklist === 'TABLEROS') {
			Object.entries(checklistTableros).forEach(([key, value]) => {
				formData.append(key, value); 
			});
		} else if (checklist === 'EXTINTORES') {
			Object.entries(checklistExtintores).forEach(([key, value]) => {
				formData.append(key, value); 
			});
		} else if (checklist === 'TERMOELECTRICO') {
			Object.entries(checklistTermoelectrico).forEach(([key, value]) => {
				formData.append(key, value); 
			});
		} else if (checklist === 'MONTAPLATOS') {
			Object.entries(checklistMontaplatos).forEach(([key, value]) => {
				formData.append(key, value); 
			});
		} else if (checklist === 'ESCALERA EMERGENCIA NORTE') {
			Object.entries(checklistPuertasEmergenciaNorte).forEach(([key, value]) => {
				formData.append(key, value); 
			});
		} else if (checklist === 'ELECTRODOMESTICOS') {
			Object.entries(checklistElectrodomesticos).forEach(([key, value]) => {
				formData.append(key, value); 
			});
		} else if (checklist === 'PUERTA GIRATORIA') {
			Object.entries(checklistPuertasGiratorias).forEach(([key, value]) => {
				formData.append(key, value); 
			});
		} else if (checklist === 'PUERTAS AUTOMATICAS') {
			Object.entries(checklistPuertasAutomaticas).forEach(([key, value]) => {
				formData.append(key, value); 
			});
		} else if (checklist === 'LIMPIEZA ESTANQUE') {
			Object.entries(checklistLimpiezaEstanques).forEach(([key, value]) => {
				formData.append(key, value); 
			});
		} else if (checklist === 'PUERTA ABATIBLE') {
			Object.entries(checklistPuertasAbatibles).forEach(([key, value]) => {
				formData.append(key, value); 
			});
		} else if (checklist === 'ESCALERA EMERGENCIA SUR') {
			Object.entries(checklistPuertasEmergenciaSur).forEach(([key, value]) => {
				formData.append(key, value); 
			});
		} else if (checklist === 'Unidad Interior') {
			Object.entries(checklistClimaInterior).forEach(([key, value]) => {
				formData.append(key, value); 
			});				
		}
		

		// Agregar archivos al FormData
		if (fotoLuminaria) {
			
			formData.append('foto_luminaria', fotoLuminaria); 
		}
		if (fotoBombas) {
			formData.append('foto_bombas', fotoBombas);
			
		}
		if (fotoInfraestructura) {
			formData.append('foto_infraestructura', fotoInfraestructura);
			
		}
		
		if (fotoGasfiteria) {
			formData.append('foto_gasfiteria', fotoGasfiteria);
			
		}
		
		if (fotoTableros) {
			formData.append('foto_tableros', fotoTableros);
			
		}
		if (fotoEstanque) {
			formData.append('foto_estanque', fotoEstanque);
			
		}
		
		if (fotoEstanqueRegistro) {
			formData.append('foto_estanque_registro', fotoEstanqueRegistro);
			
		}
		if (fotoExtintores) {
			formData.append('foto_extintores', fotoExtintores);
			
		}
		if (fotoTermoelectrico) {
			formData.append('foto_termoelectrico', fotoTermoelectrico);
			
		}
		
		if (fotoMontaplatos) {
			formData.append('foto_montaplatos', fotoMontaplatos);
			
		}
		
		if (fotoElectrodomesticos) {
			formData.append('foto_electrodomesticos', fotoElectrodomesticos);
			
		}
		if (fotoPuertasEmergenciaNorte) {
			formData.append('foto_puertas_emergencia_norte', fotoPuertasEmergenciaNorte);
			
		}
		
		if (fotoPuertasEmergenciaSur) {
			formData.append('foto_puertas_emergencia_sur', fotoPuertasEmergenciaSur);
			
		}
		
		if (fotoPuertasGiratorias) {
			formData.append('foto_puertas_giratorias', fotoPuertasGiratorias);
			
		}
		
		if (fotoPuertasAbatibles) {
			formData.append('foto_puertas_abatibles', fotoPuertasAbatibles);
			
		}
		
		if (fotoLimpiezaEstanque) {
			formData.append('foto_limpieza_estanque', fotoLimpiezaEstanque);
			
		}
		
		if (fotoPuertasAutomaticas) {
			formData.append('foto_puertas_automaticas', fotoPuertasAutomaticas);
			
		}
		
		if (fotoLavado) {
			formData.append('foto_lavado', fotoLavado);
			
		}
		
		if (fotoLinterior) {
			formData.append('foto_linterior', fotoLinterior);
			
		}
		
		if (fotoLcuadro) {
			formData.append('foto_lcuadro', fotoLcuadro);
			
		}
		
		if (fotoLfiltros) {
			formData.append('foto_lfiltros', fotoLfiltros);
			
		}
		
		if (fotoLbandeja) {
			formData.append('foto_lbandeja', fotoLbandeja);
			
		}
		
		if (fotoLalabes) {
			formData.append('foto_lalabes', fotoLalabes);
			
		}
		
		if (fotoLrejillas) {
			formData.append('foto_lrejillas', fotoLrejillas);
			
		}
		
		//console.log('Datos del FormData antes de enviar:', Array.from(formData.entries()));
		// Verifica el contenido de formData
		console.log('Datos del FormData:', Array.from(formData.entries())); // Muestra los datos que se están enviando
			console.log('idcheckparaenviarya',idcheck);
			console.log('foto_infraestructura',fotoInfraestructura);
		// Realiza la solicitud POST para guardar el checklist
		try {
			
			const response = await fetch(`${process.env.REACT_APP_API_REACT_GUARDA_MANTENIMIENTO}`, {
				method: 'POST',
				headers: {
					'Authorization': `Bearer ${token}`, // Agrega el token de autorización
				},
				body: formData, // Envía el FormData con todos los datos
			});
			
			const responseText = await response.text(); // Obtiene la respuesta como texto
			console.log('Respuesta del servidor:', responseText); // Muestra la respuesta en la consola


			if (response.ok) {
				alert('Checklist guardado con éxito'); // Mensaje de éxito
				
				 navigate(`/detequipo-titan/${id}?tipo=${checklist}`); 
			} else {
				const errorData = await response.json(); // Obtiene los datos de error
				alert('Error al guardar el checklist: ' + (errorData.message || 'Por favor intenta nuevamente')); // Mensaje de error
			}
		} catch (error) {
			console.error('Error en la solicitud:', error); // Muestra el error en la consola
			alert('Error al guardar el checklist, por favor intenta nuevamente'); // Mensaje de error
		}
	};
    return (
        <div className="agregar-container" >
            <Menu userName={userName} isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} handleLogout={handleLogout} />
            <main className="content" >
                <div className="dashboard-container" >
					<div className="form-container" >
					 <h2>{mantenimientoIniciado ? 'Completar Checklist' : 'Iniciar Mantenimiento'}</h2>
                    {!mantenimientoIniciado ? (
                        <form onSubmit={handleSubmitMantenimiento}>
                            <label>
                                Tipo de mantenimiento:
                                <select value={tipoMantenimiento} onChange={(e) => setTipoMantenimiento(e.target.value)} required>
                                    <option value="">Seleccionar</option>
                                    <option value="MANTENIMIENTO PREVENTIVO">Mantenimiento Preventivo</option>
                                    <option value="MANTENIMIENTO CORRECTIVO">Mantenimiento Correctivo</option>
                                </select>
                            </label>

                            <label>
                                Observación inicial (Opcional):
                                <input
                                    type="text"
                                    value={observacionInicial}
                                    onChange={(e) => setObservacionInicial(e.target.value)}
                                    placeholder="Observación inicial"
                                />
                            </label>

                            <label>
                                Foto inicial:
                                <input
                                    type="file"
                                    accept=".png, .jpg, .jpeg"
                                    onChange={(e) => setFotoInicial(e.target.files[0])}
                                    required
                                />
                            </label>

                            <button type="submit" className="btn-create-incidencia" >Iniciar Mantenimiento</button>
                        </form>
                    ) : (
                        <form onSubmit={handleSubmitChecklist}>
                            {checklist === 'BOMBAS' && (
                                <>
                                    <h3>Checklist Bombas</h3>
                                    <div>
                                        <label>
                                            Carcaza del motor:
                                            <select name="carcaza_motor" value={checklistBombas.carcaza_motor} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Exterior de impulsor:
                                            <select name="exterior_impulsor" value={checklistBombas.exterior_impulsor} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                                                               <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Cañería de impulsión:
                                            <select name="caneria_impulsion" value={checklistBombas.caneria_impulsion} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Sala de bombas:
                                            <select name="sala_bombas" value={checklistBombas.sala_bombas} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Funcionamiento correcto:
                                            <select name="funcionamiento_correcto" value={checklistBombas.funcionamiento_correcto} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Ausencia de filtraciones:
                                            <select name="ausencia_filtaciones_bombas" value={checklistBombas.ausencia_filtaciones_bombas} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Amortiguadores de vibración:
                                            <select name="amortiguadores_vibracion" value={checklistBombas.amortiguadores_vibracion} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Ruido anormales equipo y rodamientos:
                                            <select name="ruido_anormales" value={checklistBombas.ruido_anormales} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Operación de válvulas de corte:
                                            <select name="operacion_valvulas_corte" value={checklistBombas.operacion_valvulas_corte} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Estado de juntas flexibles:
                                            <select name="estado_juntas_flexibles" value={checklistBombas.estado_juntas_flexibles} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Cambios de elementos defectuosos:
                                                                                        <select name="cambios_elementos_defectuosos" value={checklistBombas.cambios_elementos_defectuosos} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Funcionamiento de válvulas de retención:
                                            <select name="funcionamiento_valvulas_retencion" value={checklistBombas.funcionamiento_valvulas_retencion} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
									<div>
                                        <label>
                                            Estado en que deja el equipo:
                                            <select name="estado_bombas" value={checklistBombas.estado_bombas} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OPERATIVO">OPERATIVO</option>
                                                <option value="FALLA">FALLA</option>
                                               
                                            </select>
                                        </label>
                                    </div>
									<div>
										<label>
											Observaciones del mantenimiento:
											<input
												type="text"
												name="observaciones_bombas"
												value={checklistBombas.observaciones_bombas || ''} 
												onChange={handleChecklistChange}
											/>
										</label>
									</div>
									<div>
										<label>
											Foto mantenimiento:
											<input
												type="file"
												accept=".png, .jpg, .jpeg"
												name="foto_bombas"
												onChange={(e) => setFotoBombas(e.target.files[0])} 
												required
											/>
										</label>
									</div>
                                </>
                            )}

                            {checklist === 'ILUMINARIA' && (
                                <>
                                    <h3>Checklist Iluminaria</h3>
                                    <div>
                                        <label>
                                            Pantalla:
                                            <select name="pantalla" value={checklistIluminaria.pantalla} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Difusor:
                                            <select name="difusor" value={checklistIluminaria.difusor} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Lámparas:
                                            <select name="lamparas" value={checklistIluminaria.lamparas} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Cuerpo equipo:
                                            <select name="cuerpo_equipo" value={checklistIluminaria.cuerpo_equipo} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Estado enchufes:
                                            <select name="estado_enchufes" value={checklistIluminaria.estado_enchufes} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Estado lámparas:
                                            <select name="estado_lamparas" value={checklistIluminaria.estado_lamparas} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Estado difusor:
                                            <select name="estado_difusor" value={checklistIluminaria.estado_difusor} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Estado pantalla:
                                                                                        <select name="estado_pantalla" value={checklistIluminaria.estado_pantalla} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Estado cuerpo equipo:
                                            <select name="estado_cuerpo_equipo" value={checklistIluminaria.estado_cuerpo_equipo} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Estado conexiones eléctricas:
                                            <select name="estado_conexiones_electricas" value={checklistIluminaria.estado_conexiones_electricas} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Estado ballasts:
                                            <select name="estado_ballasts" value={checklistIluminaria.estado_ballasts} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Estado puntos eléctricos (enchufes, tapas, etc.):
                                            <select name="estado_puntos_electricos" value={checklistIluminaria.estado_puntos_electricos} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Estado base lámparas:
                                            <select name="estado_base_lamparas" value={checklistIluminaria.estado_base_lamparas} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Conexiones:
                                            <select name="conexiones" value={checklistIluminaria.conexiones} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Componentes defectuosos:
                                            <select name="componentes_defectuosos" value={checklistIluminaria.componentes_defectuosos} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
									<div>
                                        <label>
                                            Estado en que deja el equipo:
                                            <select name="estado_luminaria" value={checklistIluminaria.estado_luminaria} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OPERATIVO">OPERATIVO</option>
                                                <option value="FALLA">FALLA</option>
                                               
                                            </select>
                                        </label>
                                    </div>
									<div>
										<label>
											Observaciones del mantenimiento:
											<input
												type="text"
												name="observaciones_luminaria"
												value={checklistIluminaria.observaciones_luminaria || ''} 
												onChange={handleChecklistChange}
											/>
										</label>
									</div>
									<div>
										<label>
											Foto mantenimiento:
											<input
											type="file"
											accept=".png, .jpg, .jpeg"
											name="foto_luminaria" 
											onChange={(e) => setFotoLuminaria(e.target.files[0])}
											
											required
										/>
										</label>
									</div>
                                </>
                            )}

                            {checklist === 'ESTANQUE EXPANCION' && (
                                <>
                                    <h3>Checklist Estanque de Expansión</h3>
                                    <div>
                                        <label>
                                            Registro fotográfico de placas de estanque:
                                            <input
                                                type="file"
												accept=".png, .jpg, .jpeg"
												name="foto_estanque_registro"
												onChange={(e) => setFotoRegistroEstanque(e.target.files[0])} 
												required
                                            />
                                        </label>
                                    </div>
                                    <div>
								<label>
                                            Limpieza superficie - Estanque:
                                            <select name="estanque" value={checklistEstanque.estanque} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Limpieza superficie - Piping:
                                            <select name="piping" value={checklistEstanque.piping} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Limpieza superficie - Válvulas:
                                            <select name="valvulas" value={checklistEstanque.valvulas} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Limpieza superficie - Manómetros:
                                            <select name="manometros" value={checklistEstanque.manometros} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Presión de retorno de sistema secundario (en manifold de retorno):
                                            <input
                                                type="number"
                                                name="presion_retorno"
                                                value={checklistEstanque.presion_retorno}
                                                onChange={handleChecklistChange}
                                                placeholder="Ingrese la presión"
                                                required
                                            />
                                        </label>
                                    </div>
									<div>
                                        <label>
                                            Estado en que deja el equipo:
                                            <select name="estado_estanque_exp" value={checklistEstanque.estado_estanque_exp} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OPERATIVO">OPERATIVO</option>
                                                <option value="FALLA">FALLA</option>
                                               
                                            </select>
                                        </label>
                                    </div>
									<div>
										<label>
											Observaciones del mantenimiento:
											<input
												type="text"
												name="observaciones_estanque_exp"
												value={checklistEstanque.observaciones_estanque_exp || ''} 
												onChange={handleChecklistChange}
											/>
										</label>
									</div>
									<div>
										<label>
											Foto mantenimiento:
											<input
												type="file"
												accept=".png, .jpg, .jpeg"
												name="foto_estanque"
												onChange={(e) => setFotoEstanque(e.target.files[0])} 
												required
											/>
											
										</label>
									</div>
                                </>
                            )}
							
							{checklist === 'INFRAESTRUCTURA' && (
							<>
								<h3>Checklist Infraestructura</h3>
								<div>
									<label>
										Puerta de escalera de emergencia:
										<select name="puerta_escalera_emergencia" value={checklistInfraestructura.puerta_escalera_emergencia} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Portones (revisión y lubricación):
										<select name="portones_revision_lubricacion" value={checklistInfraestructura.portones_revision_lubricacion} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Puertas y acceso (engrase y/o lubricación de bujes):
										<select name="puertas_acceso_engrase" value={checklistInfraestructura.puertas_acceso_engrase} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Puertas y/o accesos (si aplica):
										<select name="puertas_acceso_aplica" value={checklistInfraestructura.puertas_acceso_aplica} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Cajón y percheros:
										<select name="cajon_percheros" value={checklistInfraestructura.cajon_percheros} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Inspección mobiliario (sillas, mesas, estantes, etc.):
										<select name="inspeccion_mobiliario" value={checklistInfraestructura.inspeccion_mobiliario} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Piso y alfombras:
										<select name="piso_alfombras" value={checklistInfraestructura.piso_alfombras} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Mármol y cerámica (si aplica):
										<select name="marmol_ceramica" value={checklistInfraestructura.marmol_ceramica} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Estado general de cielo:
										<select name="estado_general_cielo" value={checklistInfraestructura.estado_general_cielo} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Revisión estado general de pinturas:
										<select name="revision_estado_pinturas" value={checklistInfraestructura.revision_estado_pinturas} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Aperturas para cables:
										<select name="aperturas_cables" value={checklistInfraestructura.aperturas_cables} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Separador de módulos:
										<select name="separador_modulos" value={checklistInfraestructura.separador_modulos} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Espacio para almacenamiento:
										<select name="espacio_almacenamiento" value={checklistInfraestructura.espacio_almacenamiento} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Base estable:
										<select name="base_estable" value={checklistInfraestructura.base_estable} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Reposapiés:
										<select name="reposapies" value={checklistInfraestructura.reposapies} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Cajones funcionales:
										<select name="cajones_funcionales" value={checklistInfraestructura.cajones_funcionales} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Perchero o espacio para colgar abrigos:
										<select name="perchero_espacio_abrigos" value={checklistInfraestructura.perchero_espacio_abrigos} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
                                        <label>
                                            Estado en que deja el equipo:
                                            <select name="estado_infraestructura" value={checklistInfraestructura.estado_infraestructura} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OPERATIVO">OPERATIVO</option>
                                                <option value="FALLA">FALLA</option>
                                               
                                            </select>
                                        </label>
                                    </div>
									<div>
										<label>
											Observaciones del mantenimiento:
											<input
												type="text"
												name="observaciones_infraestructura"
												value={checklistInfraestructura.observaciones_infraestructura || ''} 
												onChange={handleChecklistChange}
											/>
										</label>
								</div>
								<div>
									<label>
										Foto Mantenimiento:
										<input
												type="file"
												accept=".png, .jpg, .jpeg"
												name="foto_infraestructura"
												onChange={(e) => setFotoInfraestructura(e.target.files[0])} 
												required
											/>
									</label>
								</div>
							</>
						)}

						{checklist === 'GASFITERIA' && (
							<>
								<h3>Checklist Gasfitería</h3>
								<div>
									<label>
										Aireadores (si aplica):
										<select name="aireadores" value={checklistGasfiteria.aireadores} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Botón de fluxómetro:
										<select name="boton_fluxometro" value={checklistGasfiteria.boton_fluxometro} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Cuerpo de fluxómetro (interior):
										<select name="cuerpo_fluxometro" value={checklistGasfiteria.cuerpo_fluxometro} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Lubricación piezas móviles (grasa silicona):
										<select name="lubricacion_piezas_moviles" value={checklistGasfiteria.lubricacion_piezas_moviles} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Ajuste cuerpo WC y lavamanos:
										<select name="ajuste_cuerpo_wc_lavamanos" value={checklistGasfiteria.ajuste_cuerpo_wc_lavamanos} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Ajuste físico de botón y panel:
										<select name="ajuste_fisico_boton_panel" value={checklistGasfiteria.ajuste_fisico_boton_panel} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Ajuste parámetros (control remoto):
										<select name="ajuste_parametros_control_remoto" value={checklistGasfiteria.ajuste_parametros_control_remoto} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Grifería:
										<select name="griferia" value={checklistGasfiteria.griferia} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Llave automática vanitorio:
										<select name="llave_automatica_vanitorio" value={checklistGasfiteria.llave_automatica_vanitorio} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Trizaduras:
										<select name="trizaduras" value={checklistGasfiteria.trizaduras} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Válvula descarga inodoros (si aplica):
										<select name="valvula_descarga_inodoros" value={checklistGasfiteria.valvula_descarga_inodoros} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Funcionamiento llave de paso:
										<select name="funcionamiento_llave_paso" value={checklistGasfiteria.funcionamiento_llave_paso} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Flexibles (si aplica):
										<select name="flexibles" value={checklistGasfiteria.flexibles} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Ausencia de filtraciones:
										<select name="ausencia_filtaciones" value={checklistGasfiteria.ausencia_filtaciones} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Batería y/o transformador:
										<select name="bateria_transformador" value={checklistGasfiteria.bateria_transformador} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Mariposa llave:
										<select name="mariposa_llave" value={checklistGasfiteria.mariposa_llave} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Registro vertical aguas servidas (si aplica):
										<select name="registro_vertical_aguas_servidas" value={checklistGasfiteria.registro_vertical_aguas_servidas} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
                                        <label>
                                            Estado en que deja el equipo:
                                            <select name="estado_gasfiteria" value={checklistGasfiteria.estado_gasfiteria} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OPERATIVO">OPERATIVO</option>
                                                <option value="FALLA">FALLA</option>
                                               
                                            </select>
                                        </label>
                                    </div>
									<div>
										<label>
											Observaciones del mantenimiento:
											<input
												type="text"
												name="observaciones_gasfiteria"
												value={checklistGasfiteria.observaciones_gasfiteria || ''} 
												onChange={handleChecklistChange}
											/>
										</label>
								</div>
								<div>
									<label>
										Foto Mantenimiento:
										<input
												type="file"
												accept=".png, .jpg, .jpeg"
												name="foto_gasfiteria"
												onChange={(e) => setFotoGasfiteria(e.target.files[0])} 
												required
											/>
									</label>
								</div>
							</>
						)}
						
						{checklist === 'TABLEROS' && (
						<>
							<h3>Checklist Tableros</h3>
							<div>
								<label>
									Interior con soplador:
									<select name="interior_con_soplador" value={checklistTableros.interior_con_soplador} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Instrumentos de medición:
									<select name="instrumentos_medicion" value={checklistTableros.instrumentos_medicion} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Dispositivos interiores:
									<select name="dispositivos_interiores" value={checklistTableros.dispositivos_interiores} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Contactos eléctricos:
									<select name="contactos_electricos" value={checklistTableros.contactos_electricos} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Equipo y zona en general:
									<select name="equipo_zona_general" value={checklistTableros.equipo_zona_general} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Comunicación centralizado (si aplica):
									<select name="comunicacion_centralizado" value={checklistTableros.comunicacion_centralizado} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Estado transformadores:
									<select name="estado_transformadores" value={checklistTableros.estado_transformadores} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Conexiones eléctricas – borneras:
									<select name="conexiones_electricas_borneras" value={checklistTableros.conexiones_electricas_borneras} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Activación test breaker:
									<select name="activacion_test_breaker" value={checklistTableros.activacion_test_breaker} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Activación protector térmico:
									<select name="activacion_protector_termico" value={checklistTableros.activacion_protector_termico} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Instrumentos de medición:
									<select name="instrumentos_medicionr" value={checklistTableros.instrumentos_medicionr} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Indicadores luz piloto:
									<select name="indicadores_luz_piloto" value={checklistTableros.indicadores_luz_piloto} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Chapa puerta:
									<select name="chapa_puerta" value={checklistTableros.chapa_puerta} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Conexiones eléctricas:
									<select name="conexiones_electricas" value={checklistTableros.conexiones_electricas} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Contactores - protectores térmicos:
									<select name="contactores_protectores_termicos" value={checklistTableros.contactores_protectores_termicos} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Consumo breaker general (fase 1):
									<input
										type="number"
										step="0.01"
										name="consumo_breaker_general_fase1"
										value={checklistTableros.consumo_breaker_general_fase1}
										onChange={handleChecklistChange}
										required
									/>
								</label>
							</div>
							<div>
								<label>
									Consumo breaker general (fase 2):
									<input
										type="number"
										step="0.01"
										name="consumo_breaker_general_fase2"
										value={checklistTableros.consumo_breaker_general_fase2}
										onChange={handleChecklistChange}
										required
									/>
								</label>
							</div>
							<div>
								<label>
									Consumo breaker general (fase 3):
									<input
										type="number"
										step="0.01"
										name="consumo_breaker_general_fase3"
										value={checklistTableros.consumo_breaker_general_fase3}
										onChange={handleChecklistChange}
										required
									/>
								</label>
							</div>
							<div>
								<label>
									Voltaje entre fases RS:
									<input
										type="number"
										step="0.01"
										name="voltaje_entre_fases_rs"
										value={checklistTableros.voltaje_entre_fases_rs}
										onChange={handleChecklistChange}
										required
									/>
								</label>
							</div>
							<div>
								<label>
									Voltaje entre fases RT:
									<input
										type="number"
										step="0.01"
										name="voltaje_entre_fases_rt"
										value={checklistTableros.voltaje_entre_fases_rt}
										onChange={handleChecklistChange}
										required
									/>
								</label>
							</div>
							<div>
								<label>
									Voltaje entre fases ST:
									<input
										type="number"
										step="0.01"
										name="voltaje_entre_fases_st"
										value={checklistTableros.voltaje_entre_fases_st}
										onChange={handleChecklistChange}
										required
									/>
								</label>
							</div>
							<div>
								<label>
									Voltaje entre RN:
									<input
										type="number"
										step="0.01"
										name="voltaje_entre_rn"
										value={checklistTableros.voltaje_entre_rn}
										onChange={handleChecklistChange}
										required
									/>
								</label>
							</div>
							<div>
								<label>
									Voltaje entre SN:
									<input
										type="number"
										step="0.01"
										name="voltaje_entre_sn"
										value={checklistTableros.voltaje_entre_sn}
										onChange={handleChecklistChange}
										required
									/>
								</label>
							</div>
							<div>
								<label>
									Voltaje entre TN:
									<input
										type="number"
										step="0.01"
										name="voltaje_entre_tn"
										value={checklistTableros.voltaje_entre_tn}
										onChange={handleChecklistChange}
										required
									/>
								</label>
							</div>
							<div>
								<label>
									Voltaje salida transformador:
									<input
										type="number"
										step="0.01"
										name="voltaje_salida_transformador"
										value={checklistTableros.voltaje_salida_transformador}
										onChange={handleChecklistChange}
										required
									/>
								</label>
							</div>
							<div>
                                        <label>
                                            Estado en que deja el equipo:
                                            <select name="estado_tableros" value={checklistTableros.estado_tableros} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OPERATIVO">OPERATIVO</option>
                                                <option value="FALLA">FALLA</option>
                                               
                                            </select>
                                        </label>
                                    </div>
									<div>
										<label>
											Observaciones del mantenimiento:
											<input
												type="text"
												name="observaciones_tableros"
												value={checklistTableros.observaciones_tableros || ''} 
												onChange={handleChecklistChange}
											/>
										</label>
								</div>
							<div>
								<label>
									Foto mantenimiento:
									<input
										type="file"
										accept=".png, .jpg, .jpeg"
										name="foto_tableros"
										onChange={handleChecklistChange}
									/>
								</label>
							</div>
						</>
					)}

					{checklist === 'EXTINTORES' && (
						<>
							<h3>Checklist Extintores</h3>
							<div>
								<label>
									Está certificado:
									<select name="esta_certificado" value={checklistExtintores.esta_certificado} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Desmontaje del exterior:
									<select name="desmontaje_exterior" value={checklistExtintores.desmontaje_exterior} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Manómetro:
									<select name="manometro" value={checklistExtintores.manometro} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Gatillo percutor:
									<select name="gatillo_percutor" value={checklistExtintores.gatillo_percutor} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Etiqueta de modo de uso:
									<select name="etiqueta_modo_uso" value={checklistExtintores.etiqueta_modo_uso} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Indicar si el extintor se encuentra en su lugar:
									<select name="extintor_en_lugar" value={checklistExtintores.extintor_en_lugar} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Indicar si se encuentra completamente cargado y operable:
									<select name="completamente_cargado" value={checklistExtintores.completamente_cargado} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Indicar si el acceso al extintor está libre de obstrucciones:
									<select name="acceso_libre_obstrucciones" value={checklistExtintores.acceso_libre_obstrucciones} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Indicar si tiene el sello de seguridad:
									<select name="sello_seguridad" value={checklistExtintores.sello_seguridad} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Indicar si tiene el pasador de seguridad:
									<select name="pasador_seguridad" value={checklistExtintores.pasador_seguridad} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Indicar si la pintura está en buen estado:
									<select name="pintura_buen_estado" value={checklistExtintores.pintura_buen_estado} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									El cilindro presenta oxidación, roturas, abolladuras, golpes o deformaciones:
									<select name="cilindro_presenta_oxidacion" value={checklistExtintores.cilindro_presenta_oxidacion} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Indicar si la manguera tiene roturas, poros, agrietamientos u obstrucciones:
									<select name="manguera_roturas" value={checklistExtintores.manguera_roturas} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Indicar si la corneta presenta fisuras, cristalización, y defectos en acoples:
									<select name="corneta_presenta_fisuras" value={checklistExtintores.corneta_presenta_fisuras} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									La válvula presenta oxidación, daños en la manija, deformaciones que impidan su funcionamiento:
									<select name="valvula_presenta_danos" value={checklistExtintores.valvula_presenta_danos} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Indicar si las calcomanías y las placas de instrucción están legibles:
									<select name="calcomanias_legibles" value={checklistExtintores.calcomanias_legibles} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
                                        <label>
                                            Estado en que deja el equipo:
                                            <select name="estado_extintores" value={checklistExtintores.estado_extintores} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OPERATIVO">OPERATIVO</option>
                                                <option value="FALLA">FALLA</option>
                                               
                                            </select>
                                        </label>
                                    </div>
									<div>
										<label>
											Observaciones del mantenimiento:
											<input
												type="text"
												name="observaciones_extintores"
												value={checklistExtintores.observaciones_extintores || ''} 
												onChange={handleChecklistChange}
											/>
										</label>
								</div>
							<div>
								<label>
									Foto mantenimiento:
									<input
										type="file"
										accept=".png, .jpg, .jpeg"
										name="foto_extintores"
										onChange={handleChecklistChange}
									/>
								</label>
							</div>
						</>
					)}
					
					{checklist === 'TERMOELECTRICO' && (
						<>
							<h3>Checklist Termoeléctrico</h3>
							<div>
								<label>
									Componentes eléctricos:
									<select name="componentes_electricos" value={checklistTermoelectrico.componentes_electricos} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Termo:
									<select name="termo" value={checklistTermoelectrico.termo} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Voltaje:
									<input
										type="number"
										step="0.01"
										name="voltaje_parametros"
										value={checklistTermoelectrico.voltaje_parametros}
										onChange={handleChecklistChange}
										required
									/>
								</label>
							</div>
							<div>
								<label>
									Corriente:
									<input
										type="number"
										step="0.01"
										name="corriente_parametros"
										value={checklistTermoelectrico.corriente_parametros}
										onChange={handleChecklistChange}
										required
									/>
								</label>
							</div>
							<div>
								<label>
									Resistencia:
									<input
										type="number"
										step="0.01"
										name="resistencia"
										value={checklistTermoelectrico.resistencia}
										onChange={handleChecklistChange}
										required
									/>
								</label>
							</div>
							<div>
                                        <label>
                                            Estado en que deja el equipo:
                                            <select name="estado_termoelectrico" value={checklistTermoelectrico.estado_termoelectrico} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OPERATIVO">OPERATIVO</option>
                                                <option value="FALLA">FALLA</option>
                                               
                                            </select>
                                        </label>
                                    </div>
									<div>
										<label>
											Observaciones del mantenimiento:
											<input
												type="text"
												name="observaciones_termoelectrico"
												value={checklistTermoelectrico.observaciones_termoelectrico || ''} 
												onChange={handleChecklistChange}
											/>
										</label>
								</div>
							<div>
								<label>
									Foto mantenimiento :
									<input
										type="file"
										accept=".png, .jpg, .jpeg"
										name="foto_termoelectrico"
										onChange={handleChecklistChange}
									/>
								</label>
							</div>
						</>
					)}

					{checklist === 'MONTAPLATOS' && (
						<>
							<h3>Checklist Montaplatos</h3>
							<div>
								<label>
									Adjuntar respaldo de proveedor:
									<input
										type="file"
										accept=".pdf"
										name="archivo_proveedor"
										onChange={handleChecklistChange}
										required
									/>
								</label>
							</div>
							<div>
                                        <label>
                                            Estado en que deja el equipo:
                                            <select name="estado_monoplatos" value={checklistMontaplatos.estado_monoplatos} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OPERATIVO">OPERATIVO</option>
                                                <option value="FALLA">FALLA</option>
                                               
                                            </select>
                                        </label>
                                    </div>
									<div>
										<label>
											Observaciones del mantenimiento:
											<input
												type="text"
												name="observaciones_monoplatos"
												value={checklistMontaplatos.observaciones_monoplatos || ''} 
												onChange={handleChecklistChange}
											/>
										</label>
								</div>
							<div>
								<label>
									Foto Mantenimiento:
									<input
										type="file"
										accept=".png, .jpg, .jpeg"
										name="foto_montaplatos"
										onChange={handleChecklistChange}
									/>
								</label>
							</div>
						</>
					)}

					{checklist === 'LIMPIEZA ESTANQUE' && (
						<>
							<h3>Checklist Limpieza de Estanques</h3>
							<div>
								<label>
									Lavado completo estanque con hidrolavadora:
									<select name="lavado_completo_estanque_limpieza" value={checklistLimpiezaEstanques.lavado_completo_estanque_limpieza} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Sanitización con hipoclorito:
									<select name="sanitizacion_hipoclorito_limpieza" value={checklistLimpiezaEstanques.sanitizacion_hipoclorito_limpieza} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Extracción de lodo y residuos orgánicos:
									<select name="extraccion_lodo_residuos_limpieza" value={checklistLimpiezaEstanques.extraccion_lodo_residuos_limpieza} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
                                        <label>
                                            Estado en que deja el equipo:
                                            <select name="estado_limpieza_estanques" value={checklistLimpiezaEstanques.estado_limpieza_estanques} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OPERATIVO">OPERATIVO</option>
                                                <option value="FALLA">FALLA</option>
                                               
                                            </select>
                                        </label>
                                    </div>
									<div>
										<label>
											Observaciones del mantenimiento:
											<input
												type="text"
												name="observaciones_limpieza_estanques"
												value={checklistLimpiezaEstanques.observaciones_limpieza_estanques || ''} 
												onChange={handleChecklistChange}
											/>
										</label>
							</div>
							<div>
								<label>
									Foto mantenimiento:
									<input
										type="file"
										accept=".png, .jpg, .jpeg"
										name="foto_limpieza_estanque"
										onChange={handleChecklistChange}
									/>
								</label>
							</div>
						</>
					)}
					
					{checklist === 'ELECTRODOMESTICOS' && (
						<>
							<h3>Checklist Electrodomésticos</h3>
							<div>
								<label>
									Adjuntar respaldo de proveedor:
									<input
										type="file"
										accept=".pdf"
										name="archivo_proveedor_electrodomesticos"
										onChange={handleChecklistChange}
										required
									/>
								</label>
							</div>
							<div>
                                        <label>
                                            Estado en que deja el equipo:
                                            <select name="estado_electrodomesticos" value={checklistElectrodomesticos.estado_electrodomesticos} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OPERATIVO">OPERATIVO</option>
                                                <option value="FALLA">FALLA</option>
                                               
                                            </select>
                                        </label>
                                    </div>
									<div>
										<label>
											Observaciones del mantenimiento:
											<input
												type="text"
												name="observaciones_electrodomesticos"
												value={checklistElectrodomesticos.observaciones_electrodomesticos || ''} 
												onChange={handleChecklistChange}
											/>
										</label>
							</div>
							<div>
								<label>
									Foto Matenimiento:
									<input
										type="file"
										accept=".png, .jpg, .jpeg"
										name="foto_montaplatos"
										onChange={handleChecklistChange}
									/>
								</label>
							</div>
						</>
					)}
					
					{checklist === 'ESCALERA EMERGENCIA NORTE' && (
						<>
							<h3>Checklist Puertas de Emergencia Norte</h3>
							{Array.from({ length: 23 }, (_, i) => (
								<div key={`norte_piso_${i + 1}`}>
									<label>
										{`NORTE PISO ${i + 1}:`}
										<select name={`norte_piso_${i + 1}`} value={checklistPuertasEmergenciaNorte[`norte_piso_${i + 1}`]} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
											<option value="SI">SI</option>
											<option value="NO">NO</option>
										</select>
									</label>
								</div>
							))}
							{Array.from({ length: 5 }, (_, i) => (
								<div key={`norte_piso_s${i + 2}`}>
									<label>
										{`NORTE PISO S${i + 2}:`}
										<select name={`norte_piso_s${i + 2}`} value={checklistPuertasEmergenciaNorte[`norte_piso_s${i + 2}`]} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
											<option value="SI">SI</option>
											<option value="NO">NO</option>
										</select>
									</label>
								</div>
							))}
							<div>
                                        <label>
                                            Estado en que deja las puertas:
                                            <select name="estado_puertas_emergencia_norte" value={checklistPuertasEmergenciaNorte.estado_puertas_emergencia_norte} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OPERATIVO">OPERATIVO</option>
                                                <option value="FALLA">FALLA</option>
                                               
                                            </select>
                                        </label>
                                    </div>
									<div>
										<label>
											Observaciones del mantenimiento:
											<input
												type="text"
												name="observaciones_puertas_emergencia_norte"
												value={checklistPuertasEmergenciaNorte.observaciones_puertas_emergencia_norte|| ''} 
												onChange={handleChecklistChange}
											/>
										</label>
							</div>
						</>
					)}

					{checklist === 'ESCALERA EMERGENCIA SUR' && (
						<>
							<h3>Checklist Puertas de Emergencia Sur</h3>
							{Array.from({ length: 11 }, (_, i) => (
								<div key={`surhall_piso_${i + 1}`}>
									<label>
										{`SURHALL PISO ${i + 1}:`}
										<select name={`surhall_piso_${i + 1}`} value={checklistPuertasEmergenciaSur[`surhall_piso_${i + 1}`]} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
											<option value="SI">SI</option>
											<option value="NO">NO</option>
										</select>
									</label>
								</div>
							))}
							{Array.from({ length: 11 }, (_, i) => (
								<div key={`surcaja_piso_${i + 1}`}>
									<label>
										{`SURCAJA PISO ${i + 1}:`}
										<select name={`surcaja_piso_${i + 1}`} value={checklistPuertasEmergenciaSur[`surcaja_piso_${i + 1}`]} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
											<option value="SI">SI</option>
											<option value="NO">NO</option>
										</select>
									</label>
								</div>
							))}
							<div>
                                        <label>
                                            Estado en que deja las puertas:
                                            <select name="estado_puertas_emergencia_sur" value={checklistPuertasEmergenciaSur.estado_puertas_emergencia_sur} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OPERATIVO">OPERATIVO</option>
                                                <option value="FALLA">FALLA</option>
                                               
                                            </select>
                                        </label>
                                    </div>
									<div>
										<label>
											Observaciones del mantenimiento:
											<input
												type="text"
												name="observaciones_puertas_emergencia_sur"
												value={checklistPuertasEmergenciaSur.observaciones_puertas_emergencia_sur|| ''} 
												onChange={handleChecklistChange}
											/>
										</label>
							</div>
						</>
					)}

					{checklist === 'PUERTA ABATIBLE' && (
						<>
							<h3>Checklist Puertas Abatibles</h3>
							<div>
								<label>
									Limpieza quicio hidráulico:
									<select name="limpieza_quicio_hidraulico" value={checklistPuertasAbatibles.limpieza_quicio_hidraulico} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Inspección y funcionamiento quicio hidráulico:
									<select name="inspeccion_funcionamiento_quicio_hidraulico" value={checklistPuertasAbatibles.inspeccion_funcionamiento_quicio_hidraulico} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Ajuste de tiradores:
									<select name="ajuste_tiradores" value={checklistPuertasAbatibles.ajuste_tiradores} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Ajuste de gomas:
									<select name="ajuste_gomas" value={checklistPuertasAbatibles.ajuste_gomas} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
                                        <label>
                                            Estado en que deja el equipo:
                                            <select name="estado_puertas_abatibles" value={checklistPuertasAbatibles.estado_puertas_abatibles} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OPERATIVO">OPERATIVO</option>
                                                <option value="FALLA">FALLA</option>
                                               
                                            </select>
                                        </label>
                                    </div>
									<div>
										<label>
											Observaciones del mantenimiento:
											<input
												type="text"
												name="observaciones_puertas_abatibles"
												value={checklistPuertasAbatibles.observaciones_puertas_abatibles || ''} 
												onChange={handleChecklistChange}
											/>
										</label>
							</div>
							<div>
								<label>
									Foto mantenimiento:
									<input
										type="file"
										accept=".png, .jpg, .jpeg"
										name="foto_puertas_abatibles"
										onChange={handleChecklistChange}
									/>
								</label>
							</div>
						</>
					)}

					{checklist === 'PUERTA GIRATORIA' && (
						<>
							<h3>Checklist Puertas Giratorias</h3>
							<div>
								<label>
									Limpieza de superficies, paneles y el eje central:
									<select name="limpieza_superficies" value={checklistPuertasGiratorias.limpieza_superficies} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Lubricación en partes móviles del mecanismo:
									<select name="lubricacion_partes_moviles" value={checklistPuertasGiratorias.lubricacion_partes_moviles} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Revisar cables:
									<select name="revisar_cables" value={checklistPuertasGiratorias.revisar_cables} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Revisar sistema eléctrico:
									<select name="revisar_sistema_electrico" value={checklistPuertasGiratorias.revisar_sistema_electrico} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Revisar vidrios y paneles:
									<select name="revisar_vidrios_panelex" value={checklistPuertasGiratorias.revisar_vidrios_panelex} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Inspeccionar estructura de soporte:
									<select name="inspeccionar_estructura_soporte" value={checklistPuertasGiratorias.inspeccionar_estructura_soporte} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
                                        <label>
                                            Estado en que deja el equipo:
                                            <select name="estado_puertas_giratorias" value={checklistPuertasGiratorias.estado_puertas_giratorias} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OPERATIVO">OPERATIVO</option>
                                                <option value="FALLA">FALLA</option>
                                               
                                            </select>
                                        </label>
                                    </div>
									<div>
										<label>
											Observaciones del mantenimiento:
											<input
												type="text"
												name="observaciones_puertas_giratorias"
												value={checklistPuertasGiratorias.observaciones_puertas_giratorias || ''} 
												onChange={handleChecklistChange}
											/>
										</label>
							</div>
							<div>
								<label>
									Foto mantenimiento:
									<input
										type="file"
										accept=".png, .jpg, .jpeg"
										name="foto_puertas_giratorias"
										onChange={handleChecklistChange}
									/>
								</label>
							</div>
						</>
					)}

					{checklist === 'PUERTAS AUTOMATICAS' && (
						<>
							<h3>Checklist Puertas Automáticas</h3>
							<div>
								<label>
									Revisar rodamientos, bisagras y mecanismos de deslizamiento:
									<select name="revisar_rodamientos" value={checklistPuertasAutomaticas.revisar_rodamientos} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Lubricación en partes móviles del mecanismo:
									<select name="lubricacion_partes_moviles" value={checklistPuertasAutomaticas.lubricacion_partes_moviles} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Revisar cables:
									<select name="revisar_cables" value={checklistPuertasAutomaticas.revisar_cables} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Revisar sistema eléctrico:
									<select name="revisar_sistema_electrico" value={checklistPuertasAutomaticas.revisar_sistema_electrico} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Inspeccionar cristales o paneles:
									<select name="inspeccionar_cristales_paneles" value={checklistPuertasAutomaticas.inspeccionar_cristales_paneles} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
                                        <label>
                                            Estado en que deja el equipo:
                                            <select name="estado_puertas_automaticas" value={checklistPuertasAutomaticas.estado_puertas_automaticas} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OPERATIVO">OPERATIVO</option>
                                                <option value="FALLA">FALLA</option>
                                               
                                            </select>
                                        </label>
                                    </div>
									<div>
										<label>
											Observaciones del mantenimiento:
											<input
												type="text"
												name="observaciones_puertas_automaticas"
												value={checklistPuertasAutomaticas.observaciones_puertas_automaticas || ''} 
												onChange={handleChecklistChange}
											/>
										</label>
							</div>
							<div>
								<label>
									Foto mantenimiento:
									<input
										type="file"
										accept=".png, .jpg, .jpeg"
										name="foto_puertas_automaticas"
										onChange={handleChecklistChange}
									/>
								</label>
							</div>
						</>
					)}
					
					{checklist === 'Unidad Interior' && (
                                <>
                                    <h3>Checklist Equipo Clima</h3>
                                    <div>
										<label>
											Lavado H/E:
											<select name="lavado" value={checklistClimaInterior.lavado} onChange={handleChecklistChange} required>
												<option value="">Seleccionar</option>
												<option value="OK">OK</option>
												<option value="NO">NO</option>
											</select>
										</label>
										<label>
											Observaciones Lavado:
											<input type="text" name="obs_lavado" value={checklistClimaInterior.obs_lavado} onChange={handleChecklistChange}/>
										</label>
										<label>
											Foto Lavado:
											<input type="file" accept=".png, .jpg, .jpeg" name="foto_lavado" onChange={handleChecklistChange} required/>
										</label>
									</div>
									<div>
										<label>
											Limpieza Interior:
											<select name="linterior" value={checklistClimaInterior.linterior} onChange={handleChecklistChange} required>
												<option value="">Seleccionar</option>
												<option value="OK">OK</option>
												<option value="NO">NO</option>
											</select>
										</label>
										<label>
											Observaciones Limpieza Interior:
											<input type="text" name="obs_linterior" value={checklistClimaInterior.obs_linterior} onChange={handleChecklistChange}/>
										</label>
										<label>
											Foto Limpieza Interior:
											<input type="file" accept=".png, .jpg, .jpeg" name="foto_linterior" onChange={handleChecklistChange} required/>
										</label>
									</div>
									<div>
										<label>
											Limpieza Cuadro Eléctrico:
											<select name="lcuadro" value={checklistClimaInterior.lcuadro} onChange={handleChecklistChange} required>
												<option value="">Seleccionar</option>
												<option value="OK">OK</option>
												<option value="NO">NO</option>
											</select>
										</label>
										<label>
											Observaciones Limpieza Cuadro:
											<input type="text" name="obs_lcuadro" value={checklistClimaInterior.obs_lcuadro} onChange={handleChecklistChange}/>
										</label>
										<label>
											Foto Limpieza Cuadro:
											<input type="file" accept=".png, .jpg, .jpeg" name="foto_lcuadro" onChange={handleChecklistChange} required/>
										</label>
									</div>
									<div className="form-section">
										<label>
											Lavado Filtros:
											<select name="lfiltros" value={checklistClimaInterior.lfiltros} onChange={handleChecklistChange} required>
												<option value="">Seleccionar</option>
												<option value="OK">OK</option>
												<option value="NO">NO</option>
											</select>
										</label>
										<label>
											Observaciones Lavado Filtros:
											<input type="text" name="obs_lfiltros" value={checklistClimaInterior.obs_lfiltros} onChange={handleChecklistChange}/>
										</label>
										<label>
											Foto Lavado Filtros:
											<input type="file" accept="image/*" name="foto_lfiltros" onChange={handleChecklistChange} required/>
										</label>
									</div>

									{/* Reapriete Cuadro Eléctrico */}
									<div className="form-section">
										<label>
											Reapriete Cuadro:
											<select name="reapriete" value={checklistClimaInterior.reapriete} onChange={handleChecklistChange} required>
												<option value="">Seleccionar</option>
												<option value="OK">OK</option>
												<option value="NO">NO</option>
											</select>
										</label>
										<label>
											Observaciones Reapriete:
											<input type="text" name="obs_reapriete" value={checklistClimaInterior.obs_reapriete} onChange={handleChecklistChange}/>
										</label>
									</div>

									{/* Chequeo PCB */}
									<div className="form-section">
										<label>
											Chequeo PCB:
											<select name="chequeopcv" value={checklistClimaInterior.chequeopcv} onChange={handleChecklistChange} required>
												<option value="">Seleccionar</option>
												<option value="OK">OK</option>
												<option value="NO">NO</option>
											</select>
										</label>
										<label>
											Observaciones Chequeo PCB:
											<input type="text" name="obs_chequeopcb" value={checklistClimaInterior.obs_chequeopcb} onChange={handleChecklistChange}/>
										</label>
									</div>

									{/* Chequeo Amortiguadores */}
									<div className="form-section">
										<label>
											Chequeo Amortiguadores:
											<select name="chequeoamortigua" value={checklistClimaInterior.chequeoamortigua} onChange={handleChecklistChange} required>
												<option value="">Seleccionar</option>
												<option value="OK">OK</option>
												<option value="NO">NO</option>
											</select>
										</label>
										<label>
											Observaciones Amortiguadores:
											<input type="text" name="obs_chequeoamorti" value={checklistClimaInterior.obs_chequeoamorti} onChange={handleChecklistChange}/>
										</label>
									</div>

									{/* Limpieza Bandeja */}
									<div className="form-section">
										<label>
											Limpieza Bandeja:
											<select name="lbandeja" value={checklistClimaInterior.lbandeja} onChange={handleChecklistChange} required>
												<option value="">Seleccionar</option>
												<option value="OK">OK</option>
												<option value="NO">NO</option>
											</select>
										</label>
										<label>
											Observaciones Bandeja:
											<input type="text" name="obs_lbandeja" value={checklistClimaInterior.obs_lbandeja} onChange={handleChecklistChange}/>
										</label>
										<label>
											Foto Bandeja:
											<input type="file" accept="image/*" name="foto_lbandeja" onChange={handleChecklistChange} required/>
										</label>
									</div>

									{/* Limpieza Álaves */}
									<div className="form-section">
										<label>
											Limpieza Álaves:
											<select name="lalabes" value={checklistClimaInterior.lalabes} onChange={handleChecklistChange} required>
												<option value="">Seleccionar</option>
												<option value="OK">OK</option>
												<option value="NO">NO</option>
											</select>
										</label>
										<label>
											Observaciones Álaves:
											<input type="text" name="obs_lalabes" value={checklistClimaInterior.obs_lalabes} onChange={handleChecklistChange}/>
										</label>
										<label>
											Foto Álaves:
											<input type="file" accept="image/*" name="foto_lalabes" onChange={handleChecklistChange} required/>
										</label>
									</div>

									{/* Limpieza Rejillas */}
									<div className="form-section">
										<label>
											Limpieza Rejillas:
											<select name="lrejillas" value={checklistClimaInterior.lrejillas} onChange={handleChecklistChange} required>
												<option value="">Seleccionar</option>
												<option value="OK">OK</option>
												<option value="NO">NO</option>
											</select>
										</label>
										<label>
											Observaciones Rejillas:
											<input type="text" name="obs_lrejillas" value={checklistClimaInterior.obs_lrejillas} onChange={handleChecklistChange}/>
										</label>
										<label>
											Foto Rejillas:
											<input type="file" accept="image/*" name="foto_lrejillas" onChange={handleChecklistChange} required/>
										</label>
									</div>

									{/* Drenajes */}
									<div className="form-section">
										<label>
											Verificación Drenajes:
											<select name="drenajes" value={checklistClimaInterior.drenajes} onChange={handleChecklistChange} required>
												<option value="">Seleccionar</option>
												<option value="OK">OK</option>
												<option value="NO">NO</option>
											</select>
										</label>
										<label>
											Observaciones Drenajes:
											<input type="text" name="obs_drenajes" value={checklistClimaInterior.obs_drenajes} onChange={handleChecklistChange}/>
										</label>
									</div>

									{/* Aislación */}
									<div className="form-section">
										<label>
											Estado Aislación:
											<select name="aislacion" value={checklistClimaInterior.aislacion} onChange={handleChecklistChange} required>
												<option value="">Seleccionar</option>
												<option value="OK">OK</option>
												<option value="NO">NO</option>
											</select>
										</label>
										<label>
											Observaciones Aislación:
											<input type="text" name="obs_aislacion" value={checklistClimaInterior.obs_aislacion} onChange={handleChecklistChange}/>
										</label>
									</div>

									{/* Rodamiento */}
									<div className="form-section">
										<label>
											Chequeo Rodamiento:
											<select name="rodamiento" value={checklistClimaInterior.rodamiento} onChange={handleChecklistChange} required>
												<option value="">Seleccionar</option>
												<option value="OK">OK</option>
												<option value="NO">NO</option>
											</select>
										</label>
										<label>
											Observaciones Rodamiento:
											<input type="text" name="obs_rodamiento" value={checklistClimaInterior.obs_rodamiento} onChange={handleChecklistChange}/>
										</label>
									</div>

									{/* Termostato */}
									<div className="form-section">
										<label>
											Cambio Pasta Térmica:
											<select name="termostato" value={checklistClimaInterior.termostato} onChange={handleChecklistChange} required>
												<option value="">Seleccionar</option>
												<option value="OK">OK</option>
												<option value="NO">NO</option>
											</select>
										</label>
										<label>
											Observaciones Pasta Térmica:
											<input type="text" name="obs_termostato" value={checklistClimaInterior.obs_termostato} onChange={handleChecklistChange}/>
										</label>
									</div>
									<div className="form-section">
										<h4>Mediciones Técnicas</h4>
										
										<label>
											Consumo Eléctrico (kW):
											<input
												type="number"
												step="0.01"
												name="electrico"
												value={checklistClimaInterior.electrico}
												onChange={handleChecklistChange}
												required
											/>
										</label>

										<label>
											Voltaje Entrada (V):
											<input
												type="number"
												step="0.01"
												name="voltaje"
												value={checklistClimaInterior.voltaje}
												onChange={handleChecklistChange}
												required
											/>
										</label>

										<label>
											Temperatura Inyección (°C):
											<input
												type="number"
												step="0.01"
												name="inyeccion"
												value={checklistClimaInterior.inyeccion}
												onChange={handleChecklistChange}
												required
											/>
										</label>
									</div>

									{/* Caudales de Aire */}
									<div className="form-section">
										<h4>Caudales de Aire</h4>
										
										<label>
											Difusor 1 (m³/h):
											<input
												type="number"
												step="0.01"
												name="caudal1"
												value={checklistClimaInterior.caudal1}
												onChange={handleChecklistChange}
												required
											/>
										</label>

										<label>
											Difusor 2 (m³/h):
											<input
												type="number"
												step="0.01"
												name="caudal2"
												value={checklistClimaInterior.caudal2}
												onChange={handleChecklistChange}
												required
											/>
										</label>

										<label>
											Difusor 3 (m³/h):
											<input
												type="number"
												step="0.01"
												name="caudal3"
												value={checklistClimaInterior.caudal3}
												onChange={handleChecklistChange}
												required
											/>
										</label>
									</div>

									{/* Rotulación */}
									<div className="form-section">
										<label>
											Rotulación Correcta:
											<select name="rotulacion" value={checklistClimaInterior.rotulacion} onChange={handleChecklistChange} required>
												<option value="">Seleccionar</option>
												<option value="OK">OK</option>
												<option value="NO">NO</option>
											</select>
										</label>
									</div>

									{/* Observaciones Generales */}
									<div className="form-section">
										<label>
											Observaciones Generales:
											<textarea
												name="obs_general"
												value={checklistClimaInterior.obs_general}
												onChange={handleChecklistChange}
												rows="3"
											/>
										</label>
									</div>

									{/* Estado Final del Equipo */}
									<div className="form-section">
										<label>
											Estado del Equipo:
											<select name="estado_climaint" value={checklistClimaInterior.estado_climaint} onChange={handleChecklistChange} required>
												<option value="">Seleccionar</option>
												<option value="OPERATIVO">OPERATIVO</option>
												<option value="FALLA">FALLA</option>
											</select>
										</label>
									</div>
                                    
                                </>
                            )}
												

                            <button type="submit" className="btn-create-incidencia">Guardar Checklist</button>
                        </form>
                    )}
					</div>
                </div>
            </main>
        </div>
    );
};

export default AgregarMantenimiento;