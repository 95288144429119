import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../styles/Login.css'; 
import logo from '../images/logo_grande.png';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate(); 

    const handleLogin = async (e) => {
        e.preventDefault();
        if (!username || !password) {
            setMessage('Usuario y contraseña son requeridos');
            return;
        }

        try {
            const response = await axios.post(process.env.REACT_APP_API_URL, {
                username,
                password,
            });
            localStorage.setItem('token', response.data.token);
			localStorage.setItem('lugar', response.data.lugar);
			localStorage.setItem('tipo', response.data.tipo);
            setMessage('Login exitoso');

            const userType = response.data.tipo;
			const userLugar = response.data.lugar; console.log('inta', userLugar);
		
            if (userType === 'ADMINI') {
				
                navigate(process.env.REACT_APP_ADMIN_REDIRECT);
				
            } else if (userType === 'SALA CONTROL' && userLugar === 'TITANIUM') {
				
				navigate(process.env.REACT_APP_USER_REDIRECT_TITANIUM); 
				
			} else if (userType === 'TECNICO CLIMA') {
				
				navigate(process.env.REACT_APP_USER_REDIRECT_TITANIUM); 
				
			} else if (userType === 'SALA CONTROL' && userLugar === 'CORPORATIVO') {
				
				navigate(process.env.REACT_APP_USER_REDIRECT_CORPORATIVO); 
				
			} else if (userType === 'TECNICO') {
				
				navigate(process.env.REACT_APP_USER_REDIRECT_ZN); 
				
			} else {
				
				setMessage('Tipo de usuario no reconocido user', userLugar);
			}
        } catch (error) {
            
            setMessage(error.response?.data?.message || 'Error en el login');
        }
    };

    return (
        <div className="login-container">
            <div className="login-box">
                <img src={logo} alt="EntelServices" className="logo" />
                <form onSubmit={handleLogin}>
                    <div className="input-group">
                        <label>Usuario:</label>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div className="input-group">
                        <label>Contraseña:</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="login-button">Ingresar</button>
                </form>
                {message && <p className="message">{message}</p>}
            </div>
        </div>
    );
};

export default Login;