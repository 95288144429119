import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../styles/IncidenciasGrupo.css'; 
import axios from 'axios';
import Slider from 'react-slick'; 
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css'; 
import DataTable from 'react-data-table-component'; 
import Menu from './Menu';
import { Line, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, Title, Tooltip, Legend } from 'chart.js'; 


const IncidenciasGrupo = () => {
    const { tipo } = useParams(); 
    const [incidencias, setIncidencias] = useState([]);
    const [userName, setUserName] = useState('');
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/'); 
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen); 
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decodedToken = JSON.parse(atob(token.split('.')[1]));
            const nombre = decodeURIComponent(escape(decodedToken.nombre));
            const apellido = decodeURIComponent(escape(decodedToken.apellido));
            setUserName(`${nombre} ${apellido}`);
        } else {
            navigate('/'); 
        }

        const fetchIncidencias = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(process.env.REACT_APP_API_INCI_GRUPO, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const filteredIncidencias = response.data.filter(inc => inc.tipoincisala === tipo);
                // Ordenar las incidencias: primero por estado (ACTIVO primero) y luego por fecha (más reciente primero)
                const sortedIncidencias = filteredIncidencias.sort((a, b) => {
                    if (a.estadoincisala === 'ACTIVO' && b.estadoincisala !== 'ACTIVO') return -1;
                    if (a.estadoincisala !== 'ACTIVO' && b.estadoincisala === 'ACTIVO') return 1;
                    return new Date(b.fecha_ingreso) - new Date(a.fecha_ingreso); // Ordenar por fecha
                });
                setIncidencias(sortedIncidencias);
            } catch (error) {
                console.error('Error al obtener las incidencias:', error);
            }
        };

        fetchIncidencias();
    }, [tipo, navigate]);
	
	// Función para obtener datos de incidencias diarias de la última semana
    const getWeeklyData = () => {
        const today = new Date();
        const lastWeekData = Array(7).fill(0);
        const lastWeekDates = Array.from({ length: 7 }, (_, i) => {
            const date = new Date();
            date.setDate(today.getDate() - i);
            return date.toLocaleDateString();
        });

        incidencias.forEach(inc => {
            const incDate = new Date(inc.fecha_ingreso).toLocaleDateString();
            const index = lastWeekDates.indexOf(incDate);
            if (index !== -1) {
                lastWeekData[index]++;
            }
        });

        return {
            labels: lastWeekDates.reverse(),
            data: lastWeekData.reverse(),
        };
    };
	
	

	// Función para obtener datos de incidencias mensuales del año en curso
	const getMonthlyData = () => {
		const currentYear = new Date().getFullYear();
		const monthlyData = Array(12).fill(0);
		const months = Array.from({ length: 12 }, (_, i) => new Date(currentYear, i).toLocaleString('default', { month: 'long' }));

		incidencias.forEach(inc => {
			const incDate = new Date(inc.fecha_ingreso);
			if (incDate.getFullYear() === currentYear) {
				monthlyData[incDate.getMonth()]++;
			}
		});

		// Filtrar los meses que han transcurrido hasta el día actual
		const currentMonth = new Date().getMonth();
		return {
			labels: months.slice(0, currentMonth + 1), // Solo mostrar meses hasta el actual
			data: monthlyData.slice(0, currentMonth + 1),
		};
	};
	
	

	// Función para obtener datos de incidencias por piso en el último mes
    const getIncidenciasPorPiso = () => {
        const currentDate = new Date();
        const lastMonthData = {};
        
        incidencias.forEach(inc => {
            const incDate = new Date(inc.fecha_ingreso);
            if (incDate.getMonth() === currentDate.getMonth() && incDate.getFullYear() === currentDate.getFullYear()) {
                const piso = inc.pisoincisala;
                lastMonthData[piso] = (lastMonthData[piso] || 0) + 1;
            }
        });

        return {
            labels: Object.keys(lastMonthData),
            data: Object.values(lastMonthData),
        };
    };
	
	
	const weeklyData = getWeeklyData();
    const monthlyData = getMonthlyData();
	const incidenciasPorPiso = getIncidenciasPorPiso();
	
	
	const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    // Columnas para la tabla
    const columns = [
        {
            name: 'Fecha',
            selector: row => {
                const date = new Date(row.fecha_ingreso);
                return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`; // Formato d-m-Y
            },
            sortable: true,
            width: '100px', // Ancho mínimo
        },
		{
            name: 'ID',
            selector: row => row.id_inci_sala,
            sortable: true,
            cell: row => <div className="ID">{row.id_inci_sala}</div>,
            width: '70px', // Ancho fijo
        },
        {
            name: 'Descripción',
            selector: row => row.descripincisala,
            sortable: true,
            cell: row => <div className="description">{row.descripincisala}</div>,
            width: '500px', // Ancho fijo
        },
        {
            name: 'Piso',
            selector: row => row.pisoincisala,
            sortable: true,
            width: '75px', // Ancho mínimo
        },
        {
            name: 'Estado',
            selector: row => (
                <span className={row.estadoincisala === 'ACTIVO' ? 'pill-active' : 'pill-finalized'}>
                    {row.estadoincisala}
                </span>
            ),
            sortable: true,
            width: '110px', // Ancho mínimo
        },
        {
            name: 'Detalles',
            cell: row => (
                <button 
                   onClick={() => navigate(`/detincidencia/${row.id_inci_sala}`)} 
                    style={{ 
                        backgroundColor: '#002eff', 
                        color: 'white', 
                        border: 'none', 
                        borderRadius: '5px', 
                        padding: '5px 10px', 
                        cursor: 'pointer' 
                    }}
                >
                    &nbsp;<i className="fa fa-eye" style={{ marginRight: '5px' }}></i>
                </button>
            ),
            width: '100px', // Ancho mínimo
        },
    ];

    return (
		<div className="incidencias-grupo-container">
            <Menu userName={userName} isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} handleLogout={handleLogout} />
            <main className="content">
                <div className="dashboard-container">
                    <div className="tendencias">
                        <h2>&nbsp;</h2>
                        <Slider {...settings}>
                            {/* Aquí puedes agregar los gráficos de tendencias cuando estén disponibles */}
                            <div>
                                <h3>Incidencias Diarias (Última Semana)</h3>
                                <Line 
                                    data={{
                                        labels: weeklyData.labels,
                                        datasets: [{
                                            label: 'Incidencias Diarias',
                                            data: weeklyData.data,
                                            backgroundColor: 'rgba(0, 46, 255, 0.6)',
                                            borderColor: 'rgba(0, 46, 255, 1)',
                                            borderWidth: 2,
                                            fill: false,
                                        }],
                                    }} 
                                    options={{
                                        responsive: true,
                                        plugins: {
                                            legend: {
												position: 'top',
                                            },
                                            title: {
                                                display: true,
                                                text: 'Tendencia de Incidencias Diarias',
                                            },
                                        },
                                        scales: {
                                            y: {
                                                beginAtZero: true,
                                                ticks: {
                                                    
                                                    callback: function(value) {
                                                        return Number.isInteger(value) ? value : '';
                                                    }
                                                }
                                            },
                                        },
                                    }} 
                                />
                            </div>
                            <div>
                               <h3>Incidencias Mensuales 2025</h3>
                                <Bar 
                                    data={{
                                        labels: monthlyData.labels,
                                        datasets: [{
                                            label: 'Incidencias Mensuales',
                                            data: monthlyData.data,
                                            backgroundColor: 'rgba(0, 46, 255, 0.6)', 
											borderColor: 'rgba(0, 46, 255, 1)',
                                            borderWidth: 1,
                                        }],
                                    }} 
                                    options={{
                                        responsive: true,
                                        plugins: {
                                            legend: {
                                                position: 'top',
                                            },
                                            title: {
                                                display: true,
                                                text: 'Tendencia de Incidencias Mensuales',
                                            },
                                        },
                                        scales: {
                                            y: {
                                                beginAtZero: true,
                                                ticks: {
                                                    
                                                    callback: function(value) {
                                                        return Number.isInteger(value) ? value : '';
                                                    }
                                                }
                                            },
                                        },
                                    }} 
                                />
                            </div>
							<div>
								<h3>Incidencias por Piso (Último Mes)</h3>
                                <Bar 
                                    data={{
                                        labels: incidenciasPorPiso.labels,
                                        datasets: [{
                                            label: 'Incidencias por Piso',
                                            data: incidenciasPorPiso.data,
                                            backgroundColor: 'rgba(0, 46, 255, 0.6)', // Color de las barras
											borderColor: 'rgba(0, 46, 255, 1)',
                                            borderWidth: 1,
                                        }],
                                    }} 
                                    options={{
                                        responsive: true,
                                        plugins: {
                                            legend: {
                                                position: 'top',
                                            },
                                            title: {
                                                display: true,
                                                text: 'Incidencias por Piso en el Último Mes',
                                            },
                                        },
                                        scales: {
                                            y: {
                                                beginAtZero: true,
                                                ticks: {
                                                    
                                                    callback: function(value) {
                                                        return Number.isInteger(value) ? value : '';
                                                    }
                                                }
                                            },
                                        },
                                    }} 
                                />
							</div>
                        </Slider>
                    </div>
                    <div className="incidencias-table">
                        <h2>Incidencias {tipo} Edificio Titanium</h2>
                        <DataTable
                            columns={columns}
                            data={incidencias} 
                            pagination
                            sortServer
                            defaultSortFieldId="fecha_ingreso"
                            defaultSortAsc={false} 
                            subHeader
                            subHeaderComponent={
                                <input
                                    type="text"
                                    placeholder="Buscar..."
                                    onChange={e => {
                                        const value = e.target.value.toLowerCase();
                                        const filteredData = incidencias.filter(incidencia => {
                                            const descripcion = incidencia.descripincisala ? incidencia.descripincisala.toLowerCase() : '';
                                            const piso = incidencia.pisoincisala ? incidencia.pisoincisala.toString() : ''; 
                                            return descripcion.includes(value) || piso.includes(value);
                                        });
                                        setIncidencias(filteredData); 
                                    }}
                                    style={{
                                        marginBottom: '10px',
                                        padding: '5px',
                                        width: '100%',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc'
                                    }}
                                />
                            }
                        />
                    </div>
                </div>
            </main>
        </div>
    );
};

export default IncidenciasGrupo;