import Chart from 'react-apexcharts';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/AdminDashborad.css'; 
import axios from 'axios'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'; 
import { Link } from 'react-router-dom';
import Menu from './Menu';


import { 
    faDoorOpen, 
    faWater, 
    faTachometerAlt, 
    faFireExtinguisher, 
    faLightbulb, 
    faBuilding, 
    faChair, 
    faThLarge, 
    faFire, 
    faThermometerHalf, 
    faElevator, 
    faWrench,
	faTrowelBricks,
	faPlug ,
	faToilet,
	faBolt	
} from '@fortawesome/free-solid-svg-icons';

import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend } from 'chart.js'; 
import Slider from 'react-slick'; 


ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend);

const EquiposTitan = () => {
    const [userName, setUserName] = useState('');
    const [isMenuOpen, setIsMenuOpen] = useState(false); 
    const [equipos, setEquipos] = useState([]);
    const history = useNavigate();
	
	const goToEquiposTitan = () => {
        history('/equipos'); 
    };

    const goToEquiposZn = () => {
        history('/equipos-zn'); 
    };
	
	const sliderCategories = [
		  {
			title: 'Slider 1',
			items: ['CLIMA', 'ESTANQUE', 'EXTINTOR', 'GASFITERIA']
		  },
		  {
			title: 'Slider 2',
			items: ['ILUMINACIÓN', 'INFRAESTRUCTURA', 'TABLERO']
		  },
		  
	];
	
	const sliderSettings = {
	  dots: true,
	  infinite: true,
	  speed: 500,
	  slidesToShow: 1,
	  slidesToScroll: 1,
	  autoplay: true,
	  autoplaySpeed: 5000,
	  arrows: true
	};


    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decodedToken = JSON.parse(atob(token.split('.')[1]));
            const nombre = decodeURIComponent(escape(decodedToken.nombre));
            const apellido = decodeURIComponent(escape(decodedToken.apellido));
            setUserName(`${nombre} ${apellido}`);
        } else {
            history.push('/');
        }

        fetchEquipos();
		

        const intervalId = setInterval(() => {
            fetchEquipos();
			
        }, 60000); 

        return () => clearInterval(intervalId); 
    }, [history]);

    const fetchEquipos = async () => {
    try {
        const token = localStorage.getItem('token');
        const response = await axios.get(process.env.REACT_APP_API_MANTE_EQUIPOS_ZN, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        // Verifica que response y response.data existan
        if (response && response.data) {
            setEquipos(response.data); // Asumiendo que response.data es un array de equipos
        } else {
            console.error('La respuesta no contiene datos:', response);
        }
        
    } catch (error) {
        console.error('Error al obtener los equipos:', error);
    }
};
	
	
	
	

    const handleLogout = () => {
        localStorage.removeItem('token');
        history.push('/');
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen); 
    };
	

    // Función para contar equipos por tipo
    const countEquiposByType = (type) => {
		const todos = equipos.lenght;
        const total = equipos.filter(equ => equ.checklist === type).length;
        const finalized = equipos.filter(equ => equ.checklist === type && equ.realizado === 'SI').length;
        return { total, finalized, todos };
    };	
	

    // Tipos de equipos y sus íconos
    const tiposEquipos = [
		{ name: 'CLIMA', icon: faDoorOpen },		
		{ name: 'ESTANQUE', icon: faWater },
		{ name: 'EXTINTOR', icon: faFireExtinguisher },
		{ name: 'GASFITERIA', icon: faToilet },
		{ name: 'ILUMINACIÓN', icon: faLightbulb },
		{ name: 'INFRAESTRUCTURA', icon: faBuilding },
		{ name: 'TABLERO', icon: faBolt },
	
	];

    
   const lugar = localStorage.getItem('lugar'); 
    // Configuración del carrusel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };
	
	
	//datos del gráfico
	const getRadialBarData = (items) => {
		const series = items.map(item => {
			const equipoData = countEquiposByType(item);
			const finalized = equipoData.finalized;
			const total = equipoData.total;

			// Calcula el porcentaje
			return total === 0 ? 100 : parseFloat(((finalized / total) * 100).toFixed(2));
		});

		return {
			series: series,
			options: {
				chart: {
					type: 'radialBar',
					height: 500
				},
				plotOptions: {
					radialBar: {
						hollow: {
							size: '30%'
						},
						dataLabels: {
							name: {
								fontSize: '14px',
								formatter: (seriesName, opts) => {
									const index = opts.seriesIndex;
									const percentage = series[index]; // Obtiene el porcentaje calculado
									return `${seriesName}\n(${percentage}%)`;
								}
							},
							value: {
								fontSize: '24px',
								formatter: (val) => `${val}%`
							}
						}
					}
				},
				colors: ['#002EFF', '#00E396', '#FEB019', '#FF4560', '#775DD0'],
				labels: items,
				legend: {
					show: true,
					position: 'bottom',
					horizontalAlign: 'center',
					floating: false,
					fontSize: '14px',
					formatter: (seriesName, opts) => {
						const index = opts.seriesIndex;
						const percentage = series[index]; // Obtiene el porcentaje correspondiente
						return `${seriesName} (${percentage}%)`;
					}
				},
				responsive: [{
					breakpoint: 768,
					options: {
						chart: { height: 400 },
						plotOptions: {
							radialBar: {
								dataLabels: {
									name: { fontSize: '12px' },
									value: { fontSize: '18px' }
								}
							}
						}
					}
				}]
			}
		};
	};

const getBarChartData = () => {
    const labels = tiposEquipos.map(tipo => tipo.name);
    const totalEquipos = tiposEquipos.map(tipo => countEquiposByType(tipo.name).total);

    return {
        labels: labels,
        datasets: [
            {
                label: 'Total de Equipos',
                data: totalEquipos,
                backgroundColor: 'rgba(0, 46, 255, 0.6)', 
                borderColor: 'rgba(0, 46, 255, 1)',
                borderWidth: 1,
            },
        ],
    };
};

// En el componente de renderizado
<Bar data={getBarChartData()} options={{
    responsive: true,
    plugins: {
        legend: {
            position: 'top', // Cambia la posición de la leyenda
            labels: {
                font: {
                    size: 14 // Tamaño de la fuente de la leyenda
                }
            }
        },
        title: {
            display: true,
            text: 'Total de Equipos por Tipo',
        },
    },
    scales: {
        y: {
            ticks: {
                callback: function(value) {
                    return Number.isInteger(value) ? value : ''; 
                }
            }
        }
    }
}} />
    return (
        <div className="admin-dashboard">
            <Menu userName={userName} isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} handleLogout={handleLogout} />
            <main className="content">
                <div className="dashboard-container">
                    <div className="tendencias">
                        <h2>Mantenimiento Equipos Edificio Titanium</h2>
						<Slider {...sliderSettings}>
                            {sliderCategories.map((slider, index) => {
                                const chartData = getRadialBarData(slider.items);
                                
                                return (
                                    <div key={index} className="radial-slide">
									
                                        <Chart
                                            options={chartData.options}
                                            series={chartData.series}
                                            type="radialBar"
                                            height={chartData.options.chart.height}
                                            width="100%"
                                        />
                                    </div>
                                );
                            })}
                        </Slider>
                        
                        
                    </div>
                    <div className="incidencias-grid">
						{tiposEquipos.map((tipo) => {
							let total = 0;
							let finalized = 0;
							let iconColor = '#ff3d00'; // Color por defecto
							let textColor = '#ff3d00'; // Color por defecto
							const textSize = { fontSize: '1.3em' };

							
								const { total: totalEquipos, finalized: finalizedEquipos } = countEquiposByType(tipo.name);
								total = totalEquipos;
								finalized = finalizedEquipos;
								iconColor = finalized === total ? '#002eff' : '#ff3d00';
								textColor = finalized === total ? '#002eff' : '#ff3d00';
							

							return (
								<Link to={`/equiposgrupozn/${tipo.name}`} key={tipo.name} className="incidencia-card">
									<FontAwesomeIcon icon={tipo.icon} size="3x" style={{ color: iconColor }} />
									<h4>{tipo.name}</h4>
									<p style={{ color: textColor, ...textSize }}>{finalized}/{total}</p>
								</Link>
							);
						})}
					</div>
                </div>
            </main>
			{lugar === 'TODOSE' && (
			 <>
				<footer className="footer">
					<div className="footer-item" onClick={goToEquiposTitan}>Titanium</div>
					<div className="footer-item" onClick={goToEquiposZn}>Zona Norte</div>
					
				</footer>
			</>
            )}
        </div>
    );
};

export default EquiposTitan;