import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import '../styles/DetIncidencia.css'; 
import Menu from './Menu';

const Disponibilidad = () => {
    const { id } = useParams();
    const location = useLocation(); 
    const queryParams = new URLSearchParams(location.search); 
    const tipo = queryParams.get('tipo'); 
    const [userName, setUserName] = useState('');
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [disponibilidad, setDisponibilidad] = useState(null);
    const [detalles, setDetalles] = useState([]);
	const [detallesequipo, setDetallesequipo] = useState([]);
    const [idu, setIdu] = useState(''); 
    const [botonTexto, setBotonTexto] = useState('');
    const [botonDeshabilitado, setBotonDeshabilitado] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
           
			const decodedToken = JSON.parse(atob(token.split('.')[1]));
            const nombre = decodeURIComponent(escape(decodedToken.nombre));
            const apellido = decodeURIComponent(escape(decodedToken.apellido));
            setIdu(decodeURIComponent(escape(decodedToken.idu))); 
            setUserName(`${nombre} ${apellido}`);
            
        } else {
            navigate('/'); 
        }
        
		
        fetchDisponibilidad();
        fetchHistoriaDisponibilidad();
		fetchHistoriaDisponibilidadEquipo();
    }, [id, tipo, navigate]);

    const fetchDisponibilidad = async () => {
        try {
           
			const token = localStorage.getItem('token');
			
			if (token) {
				const decodedToken = JSON.parse(atob(token.split('.')[1]));
				
				const response = await axios.get(`${process.env.REACT_APP_API_REACT_VER_ESTADO_DISPONIBILIDAD_USUARIO}/${decodedToken.idu}`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				setDisponibilidad(response.data);
				// Establecer el texto del botón según el último registro
				if (response.data && response.data.tipo) {
					setBotonTexto(response.data.tipo === 'SALIDA' ? 'INGRESO' : 'SALIDA');
				}
			} else {
				navigate('/'); 
			}
			
            
        } catch (error) {
            console.error('Error al obtener la disponibilidad:', error);
            navigate('/');
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/');
    };
    
    const agregaMantenimiento = () => {
        navigate(`/agrega-mantenimiento/${id}?tipo=${tipo}`); 
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen); 
    };
    
    const fetchHistoriaDisponibilidad = async () => {
        try {
            const token = localStorage.getItem('token');
			const decodedToken = JSON.parse(atob(token.split('.')[1]));
			
            const response = await axios.get(`${process.env.REACT_APP_API_HIST_DISPONIBILIDAD_USUARIO}/${decodedToken.idu}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setDetalles(response.data); 
        } catch (error) {
            console.error('Error al obtener la disponibilidad:', error);
        }
    };
	
	const fetchHistoriaDisponibilidadEquipo = async () => {
        try {
            const token = localStorage.getItem('token');
			const decodedToken = JSON.parse(atob(token.split('.')[1]));
			
            const response = await axios.get(`${process.env.REACT_APP_API_HIST_DISPONIBILIDAD_EQUIPO}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setDetallesequipo(response.data); 
        } catch (error) {
            console.error('Error al obtener disponibilidad:', error);
        }
    };

    const manejarDisponibilidad = async () => {
        const tipoDisponibilidad = botonTexto === 'INGRESO' ? 'INGRESO' : 'SALIDA';
        try {
            const token = localStorage.getItem('token');
            await axios.post(process.env.REACT_APP_API_INGRESAR_DISPONIBILIDAD, {
                idu,
                tipo_disponibilidad: tipoDisponibilidad,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            // Deshabilitar el botón por 2 minutos
            setBotonDeshabilitado(true);
            setTimeout(() => {
                setBotonDeshabilitado(false);
            }, 120000); // 120000 ms = 2 minutos
            // Volver a obtener la disponibilidad después de registrar
            fetchDisponibilidad();
			fetchHistoriaDisponibilidad();
        } catch (error) {
            console.error('Error al registrar la disponibilidad:', error);
        }
    };

    return (
        <div className="det-incidencia-container">
            <div className="menu-container">
                <Menu userName={userName} isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} handleLogout={handleLogout} />
            </div>
            <div className="content-container">
                <div className="incidencia-details">
                    {disponibilidad ? (
                        <>
                            <h2>Disponibilidad</h2>
                            <button className="btn-create-incidencia"
                                onClick={manejarDisponibilidad} 
                                disabled={botonDeshabilitado}
                            >
                                {botonTexto}
                            </button>
                        </>
                    ) : (
                        <p>Cargando detalles del equipo...</p>
                    )}
					<h2>Disponibilidad Equipo</h2>
					<table className="actividad-table">
					<thead>
						<tr>
									<th>Nombre</th>
									<th>Estado</th>
						</tr>			
					
					 </thead>
                        <tbody>
							
						</tbody>
                    </table>
					
                </div>
				
				

                <div className="historia-incidencia">
                    <h2>Historial de Disponibilidad</h2>
                    <table className="history-table">
                        <thead>
                            <tr>
                                <th>Fecha</th>
                                <th>Hora</th>
                                <th>Registro</th>
                            </tr>
                        </thead>
                        <tbody>
                            {detalles.map((detalle, index) => (
                                <tr key={index}>
                                    <td>
                                        {(() => {
                                            return new Date(detalle.fecha).toLocaleString('es-ES', { 
                                                day: '2-digit', 
                                                month: '2-digit', 
                                                year: 'numeric', 
                                            });
                                        })()}
                                    </td>
                                    <td>
										{(() => {
											const fechaCompleta = `${detalle.fecha.split('T')[0]}T${detalle.hora}`;
											return new Date(fechaCompleta).toLocaleString('es-ES', { 
												hour: '2-digit', 
												minute: '2-digit', 
												second: '2-digit', 
												hour12: false 
											});
										})()}
									</td>
                                    <td>{detalle.tipo}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Disponibilidad;