import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../styles/DetIncidencia.css'; 
import Menu from './Menu';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logo from '../images/logo_grande.png';

const DetIncidencia = () => {
    const { id } = useParams(); 
    const [userName, setUserName] = useState('');
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [incidencia, setIncidencia] = useState(null);
    const [detalles, setDetalles] = useState([]);
    const [observacion, setObservacion] = useState('');
    const [archivo, setArchivo] = useState(null);
    const [idu, setIdu] = useState(''); 
    const [temperaturaInicial, setTemperaturaInicial] = useState('');
    const apiUrl = process.env.REACT_APP_API_FOTOS_INCI_SALA;
    const navigate = useNavigate();
   
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decodedToken = JSON.parse(atob(token.split('.')[1]));
            const nombre = decodeURIComponent(escape(decodedToken.nombre));
            const apellido = decodeURIComponent(escape(decodedToken.apellido));
            setIdu(decodeURIComponent(escape(decodedToken.idu))); 
            setUserName(`${nombre} ${apellido}`);
        } else {
			alert('Error en credenciales');
			
            navigate('/');
        }
        
    
        fetchIncidencia();
        fetchHistoria();
    }, [id, navigate]);
	
	const fetchIncidencia = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${process.env.REACT_APP_API_DET_INCI_ZN}/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setIncidencia(response.data); // Cambia esto para acceder directamente a response.data
            } catch (error) {
                console.error('Error al obtener la incidencia:', error);
                navigate('/'); // Redirige si hay un error
            }
        };
		
    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/'); 
    };
    
    const handleAddHisto = () => {
        navigate(`/agrega-histo/${id}`); 
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen); 
    };
    
    const fetchHistoria = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${process.env.REACT_APP_API_HIST_INCI_ZN}/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setDetalles(response.data); 
        } catch (error) {
            console.error('Error al obtener la historia de la incidencia:', error);
        }
    };
	
	const handleSaveRecepcion = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        
        formData.append('usuarioId', idu);
        formData.append('incidenciaId', id);
        
        const token = localStorage.getItem('token');
        
        try {
            await axios.post(process.env.REACT_APP_API_ADD_RECEPCION, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json',					
                },
            });
           
            setTemperaturaInicial(''); 
			fetchIncidencia();
            fetchHistoria(); 
        } catch (error) {
            console.error('Error al repecionar la incidencia:', error);
            alert('Error al guardar la temperatura.');
        }
    };
    
    const handleSaveObservation = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('observacion', observacion);
		
        
        if (archivo) {
            // Validación del archivo
            const validTypes = ['image/png', 'image/jpeg', 'image/jpg'];
            const maxSize = 2 * 1024 * 1024; // 2 MB

            if (!validTypes.includes(archivo.type)) {
                alert('Por favor, sube un archivo en formato PNG, JPG o JPEG.');
                return;
            }

            if (archivo.size > maxSize) {
                alert('El archivo debe ser menor a 2 MB.');
                return;
            }

            formData.append('archivo', archivo);
        }

        const token = localStorage.getItem('token');
        formData.append('usuarioId', idu);
        formData.append('incidenciaId', id);

        try {
            await axios.post(process.env.REACT_APP_API_ADD_HISTO_INCI_ZN, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            alert('Observación guardada correctamente.');
            setObservacion('');
            setArchivo(null);
            fetchHistoria();
                } catch (error) {
            console.error('Error al guardar la observación:', error);
            alert('Error al guardar la observación.');
        }
    };

   
    
    const handleEndInci = async () => {
        try {
            const token = localStorage.getItem('token');
            await axios.put(`${process.env.REACT_APP_API_END_INCI_ZN}/${id}/${idu}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            alert('Incidencia finalizada correctamente.');
			const lugar = localStorage.getItem('lugar'); 
			if (lugar === 'TITANIUM'){
				navigate('/titanium'); 
			} else if(lugar === 'CORPORATIVO'){
				navigate('/corporativo'); 
			} else if(lugar === 'TODOSE'){
				navigate('/admin-dashboard-zn'); 
			}else {
				navigate('/zona-norte'); 
			}
            
        } catch (error) {
            console.error('Error al finalizar la incidencia:', error);
            alert('Error al finalizar la incidencia.'); 
        }
    };

    // Columnas para la tabla de detalles
    const columns = [
        {
            name: 'Fecha',
            selector: row => new Date(row.fecha).toLocaleDateString(), 
            sortable: true,
        },
        {
            name: 'Responsable',
            selector: row => row.id_usuario, 
            sortable: true,
        },
        {
            name: 'Notas',
            selector: row => row.notas,
            sortable: true,
        },
        {
            name: 'Foto/Archivo',
            selector: row => (
                <img src={row.foto_uno} alt="Detalle" style={{ width: '50px', height: '50px' }} />
            ),
            sortable: false,
        },
    ];

    const generatePDF = async () => {
        const doc = new jsPDF();

       
        const addHeader = () => {
            doc.addImage(logo, 'PNG', 10, 10, 50, 10); 
            doc.setFontSize(15);
            doc.text('REPORTE INICIDENCIA', 10, 30);
        };

        // Detalle de la incidencia
        addHeader(); // Agregar encabezado
        doc.setFontSize(12);
        doc.text('Datos generales de la Incidencia', 10, 40);
        doc.autoTable({
            body: [
                ['ID', incidencia.id_incidencia],
                ['Generador', `${incidencia.nombres} ${incidencia.apellidos}`],
                ['Fecha solicitud', new Date(incidencia.fecha_solicitud).toLocaleString()],
                ['Tipo', incidencia.area],          
                ['Lugar', incidencia.instalacion],
                ['Descripción', incidencia.incidencia],
				['Fecha recepción', incidencia.fecha_recepcion ? new Date(incidencia.fecha_recepcion).toLocaleString() : 'No especificado'],
                ['Fecha de Fin', incidencia.fecha_fin ? new Date(incidencia.fecha_fin).toLocaleString() : 'No especificado'],
                ['Estado', incidencia.estado],
                
            ],
            startY: 50, 
            theme: 'grid',
            headStyles: { fillColor: [200, 200, 200] }, 
            margin: { top: 10, bottom: 10 },
        });

        // Historia de la incidencia
        doc.text('Historia de la Incidencia', 10, 170);
        
        // Crear un array para las filas de la tabla
        const historyRows = detalles.map(detalle => {
            const fecha = new Date(detalle.fecha).toLocaleDateString();
            const responsable = `${detalle.nombres} ${detalle.apellidos}`;
            const notas = detalle.notas;
            let foto = '';

            // Verificar si la foto es válida
            if (detalle.foto_uno && detalle.foto_uno !== "") {
                foto = detalle.foto_uno; // Asignar la URL de la foto
            }

            return [fecha, responsable, notas, foto];
        });

                doc.autoTable({
            head: [['Fecha', 'Responsable', 'Notas']],
            body: historyRows,
            startY: 180,
            theme: 'grid',
            headStyles: { fillColor: [200, 200, 200] }, // Color gris para el encabezado
            margin: { top: 10, bottom: 10 }, // Ajusta el margen para reducir el espaciado
        });

        const pageCount = doc.internal.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.setFontSize(10);
            const pageText = `Página ${i} de ${pageCount}`;
            const footerText = 'INCIDENCIAS ZONA NORTE- www.entelservices.cl';
            const pageWidth = doc.internal.pageSize.getWidth();
            const footerY = doc.internal.pageSize.height - 10;

            doc.text(pageText, (pageWidth - doc.getTextWidth(pageText)) / 2, footerY);
            doc.text(footerText, (pageWidth - doc.getTextWidth(footerText)) / 2, footerY + 10);
        }

        doc.save('incidencia.pdf');
    };

    return (
        <div className="det-incidencia-container">
            <div className="menu-container">
                <Menu userName={userName} isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} handleLogout={handleLogout} />
            </div>
            <div className="content-container">
                <div className="incidencia-details">
                    {incidencia ? (
                        <>
                            <h2>Detalles de la Incidencia Zona Norte</h2>
                            <div align="right">
                                <button onClick={generatePDF} className="btn-view-photo">
                                    <i className="fa-solid fa-file-pdf"></i>
                                </button>
                            </div>
                            <table className="details-table">
                                <tbody>
                                    <tr>
                                        <td><strong>ID:</strong></td>
                                        <td>{incidencia.id_incidencia}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Estado:</strong></td>
                                        <td>
											<span className={`pill ${incidencia.estadoincisala === 'FINALIZADO' || incidencia.estadoincisala === 'ESPERA AUTORIZAR' ? 'pill-finalized' : 'pill-active'}`}>
												{incidencia.estado}
											</span>
										</td>
                                    </tr>
                                    <tr>
                                        <td><strong>ID de Usuario:</strong></td>
                                        <td>{`${incidencia.nombres} ${incidencia.apellidos}`}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Solicitud:</strong></td>
                                        <td>{new Date(incidencia.fecha_solicitud).toLocaleString()}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Tipo:</strong></td>
                                        <td>{incidencia.area}</td>
                                    </tr>
                                    
                                                                      
                                    <tr>
                                        <td><strong>Lugar:</strong></td>
                                        <td>{incidencia.instalacion}</td>
                                    </tr>
                                    
                                    
                                    <tr>
                                        <td><strong>Descripción:</strong></td>
                                        <td>{incidencia.incidencia}</td>
                                    </tr>
									<tr>
                                        <td><strong>Fecha recepción:</strong></td>
                                        <td>{incidencia.fecha_recepcion ? new Date(incidencia.fecha_recepcion).toLocaleString() : 'No especificado'}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Fecha Fin:</strong></td>
                                        <td>{incidencia.fecha_fin ? new Date(incidencia.fecha_fin).toLocaleString() : 'No especificado'}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </>
                    ) : (
                        <p>Cargando detalles de la incidencia...</p>
                    )}
                </div>

                <div className="historia-incidencia">
                                       <h2>Historia de la Incidencia</h2>
                    <table className="history-table">
                        <thead>
                            <tr>
                                <th>Fecha</th>
                                <th>Responsable</th>
                                <th>Notas</th>
                                <th>Foto/Archivo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {detalles.map((detalle, index) => (
                                <tr key={index}>
                                    <td>{new Date(detalle.fecha).toLocaleDateString()}</td>
                                    <td>{`${detalle.nombres} ${detalle.apellidos}`}</td>
                                    <td style={{ maxHeight: '100px', overflowY: 'auto' }}>{detalle.notas}</td>
                                    <td>
                                        {detalle.foto_uno && detalle.foto_uno !== "../tecnicos/fotos_mante/-1." ? (
                                            <button 
                                                onClick={() => window.open(`${apiUrl}/${detalle.foto_uno}`, '_blank')}
                                                className="btn-view-photo"
                                            >
                                                <i className="fa fa-eye"></i> 
                                            </button>
                                        ) : (
                                            ''
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {incidencia && incidencia.estado != 'FINALIZADO' && (
                        <div align="right">
                            <p>&nbsp;</p>
                            {(!incidencia.fecha_recepcion || incidencia.fecha_recepcion === '0000-00-00 00:00:00') ? (
								<form onSubmit={handleSaveRecepcion}>
									<button type="submit" className="btn-add-incidencia">
										Recepcionar Incidencia
									</button>
								</form>
                            ) : (
                                <>
                                    <form onSubmit={handleSaveObservation}>
                                        <div>
                                            <label htmlFor="observacion">Agregar Observación a Incidencia:</label>
                                            <input
                                                type="text"
                                                id="observacion"
                                                value={observacion}
                                                onChange={(e) => setObservacion(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="archivo">Agregar Foto o Archivo (opcional):</label>
                                            <input
                                                type="file"
                                                id="archivo"
                                                onChange={(e) => setArchivo(e.target.files[0])}
                                            />
                                        </div>
                                        <button type="submit" className="btn-add-incidencia">
                                            Guardar Observación
                                        </button>
                                    </form>
                                    <p>Si TODOS los trabajos relacionados con la Incidencia han finalizado y está resuelta, puede finalizar la Incidencia pulsando el botón "Finalizar incidencia".</p>
                                    <button className="btn-add-incidencia" onClick={handleEndInci} aria-label="Finaliza Incidencia">
                                        <i className="fa-regular fa-circle-check"></i> &nbsp;Finalizar Incidencia
                                    </button>
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DetIncidencia;