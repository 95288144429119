import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/Clima.css'; // Asegúrate de que el nombre del archivo sea correcto
import axios from 'axios'; // Asegúrate de tener axios instalado
import Slider from 'react-slick'; // Importa el carrusel
import 'slick-carousel/slick/slick.css'; // Importa los estilos de slick
import 'slick-carousel/slick/slick-theme.css'; // Importa los estilos del tema de slick
import DataTable from 'react-data-table-component'; // Asegúrate de tener react-data-table-component instalado
import Menu from './Menu';

const Clima = () => {
    const [incidencias, setIncidencias] = useState([]);
    const [userName, setUserName] = useState('');
    const [isMenuOpen, setIsMenuOpen] = useState(false); // Estado para controlar el menú desplegable
    const navigate = useNavigate(); // Cambia esto

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/'); // Cambia esto
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen); 
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decodedToken = JSON.parse(atob(token.split('.')[1]));
            const nombre = decodeURIComponent(escape(decodedToken.nombre));
            const apellido = decodeURIComponent(escape(decodedToken.apellido));
            setUserName(`${nombre} ${apellido}`);
        } else {
            navigate('/'); // Cambia esto
        }

        const fetchIncidencias = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(process.env.REACT_APP_API_INCI, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const climaIncidencias = response.data.filter(inc => inc.tipoincisala === 'CLIMA');
                setIncidencias(climaIncidencias);
            } catch (error) {
                console.error('Error al obtener las incidencias:', error);
            }
        };

        fetchIncidencias();
    }, [navigate]); // Cambia esto

    // Configuración del carrusel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    // Columnas para la tabla
    const columns = [
        {
            name: 'Fecha',
            selector: row => {
                const date = new Date(row.fecha_ingreso);
                return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`; // Formato d-m-Y
            },
            sortable: true,
            width: '100px', // Ancho mínimo
        },
        {
            name: 'Descripción',
            selector: row => row.descripincisala, // Asegúrate de que esta propiedad exista
            sortable: true,
            cell: row => <div className="description">{row.descripincisala}</div>, // Aplica la clase CSS
            width: '500px', // Ancho fijo
        },
        {
            name: 'Piso',
            selector: row => row.pisoincisala, // Asegúrate de que esta propiedad exista
            sortable: true,
            width: '80px', // Ancho mínimo
        },
        {
            name: 'Estado',
            selector: row => (
                <span className={row.estadoincisala === 'ACTIVO' ? 'pill-active' : 'pill-finalized'}>
                    {row.estadoincisala}
                </span>
            ),
            sortable: true,
            width: '150px', // Ancho mínimo
        },
        {
            name: 'Seguimiento',
            cell: row => (
                <button 
                    onClick={() => navigate(`/DetIncidencia/${row.id_inci_sala}`)} // Cambia esto
                    style={{ 
                        backgroundColor: '#002eff', 
                        color: 'white', 
                        border: 'none', 
                        borderRadius: '5px', 
                        padding: '5px 10px', 
                        cursor: 'pointer' 
                    }}
                >
                    &nbsp;<i className="fa fa-eye" style={{ marginRight: '5px' }}></i>
                    
                </button>
            ),
            width: '120px', // Ancho mínimo
        },
    ];

    return (
        <div className="clima-container">
            <Menu userName={userName} isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} handleLogout={handleLogout} />
            <main className="content">
                <div className="dashboard-container">
                    <div className="tendencias">
                        <h2>&nbsp;</h2>
                                                <Slider {...settings}>
                            {/* Aquí puedes agregar los gráficos de tendencias cuando estén disponibles */}
                            <div>
                                <h3>Gráfico de Tendencia 1</h3>
                                {/* Gráfico vacío por ahora */}
                            </div>
                            <div>
                                <h3>Gráfico de Tendencia 2</h3>
                                {/* Gráfico vacío por ahora */}
                            </div>
                        </Slider>
                    </div>
                    <div className="incidencias-table">
                        <h2>Incidencias Clima</h2>
                        <DataTable
                            columns={columns}
                            data={incidencias} // Usa las incidencias obtenidas
                            pagination
                            sortServer
                            defaultSortFieldId="fecha_ingreso"
                            defaultSortAsc={false} // Ordenar de más reciente a más antigua
                            subHeader
                            subHeaderComponent={
                                <input
                                    type="text"
                                    placeholder="Buscar..."
                                    onChange={e => {
                                        const value = e.target.value.toLowerCase();
                                        const filteredData = incidencias.filter(incidencia => {
                                            const descripcion = incidencia.descripincisala ? incidencia.descripincisala.toLowerCase() : '';
                                            const piso = incidencia.pisoincisala ? incidencia.pisoincisala.toString() : ''; // Asegúrate de que 'piso' sea un número o cadena
                                            return descripcion.includes(value) || piso.includes(value);
                                        });
                                        setIncidencias(filteredData); // Cambia a setIncidencias para actualizar la tabla
                                    }}
                                    style={{
                                        marginBottom: '10px',
                                        padding: '5px',
                                        width: '100%',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc'
                                    }}
                                />
                            }
                        />
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Clima;