import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/AdminDashborad.css'; 
import logo from '../images/logo_grande.png';
import axios from 'axios'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'; 
import { Link } from 'react-router-dom';
import Menu from './Menu';

import { 
    faDroplet, 
    faUpDown, 
    faLock, 
    faSnowflake, 
    faWindowMaximize, 
    faBolt, 
    faFire, 
    faLightbulb, 
    faTree, 
    faChair, 
    faClipboardList, 
    faBuilding, 
    faTools, 
    faHandHoldingWater, 
    faHammer,
	faToilet
} from '@fortawesome/free-solid-svg-icons'; // Importa los íconos que necesites
import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend } from 'chart.js'; 
import Slider from 'react-slick'; // Importa el carrusel

// Registra los elementos de Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend);

const AdminDashboard = () => {
    const [userName, setUserName] = useState('');
    const [isMenuOpen, setIsMenuOpen] = useState(false); // Estado para controlar el menú desplegable
    const [incidencias, setIncidencias] = useState([]);
    const history = useNavigate();
	
	
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decodedToken = JSON.parse(atob(token.split('.')[1]));
            const nombre = decodeURIComponent(escape(decodedToken.nombre));
            const apellido = decodeURIComponent(escape(decodedToken.apellido));
            setUserName(`${nombre} ${apellido}`);
        } else {
            history.push('/');
        }

        fetchIncidencias();

        const intervalId = setInterval(() => {
            fetchIncidencias();
        }, 60000); // Actualiza cada minuto

        return () => clearInterval(intervalId); // Limpia el intervalo al desmontar
    }, [history]);

    const fetchIncidencias = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(process.env.REACT_APP_API_INCI, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setIncidencias(response.data);
            
        } catch (error) {
            console.error('Error al obtener las incidencias');
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        history.push('/');
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen); 
    };
	
	const handleAdd = () => {
        history('/agrega-incidencia'); 
    };

    // Función para contar incidencias por tipo
    const countIncidenciasByType = (type) => {
        const total = incidencias.filter(inc => inc.tipoincisala === type).length;
        const finalized = incidencias.filter(inc => inc.tipoincisala === type && inc.estadoincisala === 'FINALIZADO').length;
        return { total, finalized };
    };

    // Tipos de incidencias y sus íconos
    const tiposIncidencias = [
        { name: 'AGUA POTABLE', icon: faDroplet },
        { name: 'ASENSORES', icon: faUpDown },
        { name: 'CERRAJERÍA', icon: faLock },
        { name: 'CLIMA', icon: faSnowflake },
        { name: 'CORTINAS', icon: faWindowMaximize },
        { name: 'ELECTRICIDAD', icon: faBolt },
        { name: 'GASFITERÍA', icon: faHandHoldingWater },
        { name: 'ILUMINACIÓN', icon: faLightbulb },
        { name: 'INCENDIOS', icon: faFire },
        { name: 'JARDINERÍA', icon: faTree },
        { name: 'MOBILIARIO', icon: faChair },
        { name: 'LOCKERS', icon: faClipboardList },
        { name: 'SISTEMA SANITARIO', icon: faToilet },
        { name: 'OBRA CIVIL', icon: faHammer },
        { name: 'OBRAS MENORES', icon: faTools },
    ];

    const getBarChartData = () => {
        const labels = tiposIncidencias.map(tipo => tipo.name);
        const totalIncidencias = tiposIncidencias.map(tipo => countIncidenciasByType(tipo.name).total);

        return {
            labels: labels,
            datasets: [
                {
                    label: 'Total de Incidencias',
                    data: totalIncidencias,
                    backgroundColor: 'rgba(0, 46, 255, 0.6)', // Color de las barras
                    borderColor: 'rgba(0, 46, 255, 1)',
                    borderWidth: 1,
                },
            ],
        };
    };

    const getLineChartData = (tipo) => {
        const filteredIncidencias = incidencias.filter(inc => inc.tipoincisala === tipo);
        const labels = [...new Set(filteredIncidencias.map(inc => new Date(inc.fecha_ingreso).toLocaleDateString()))]; // Obtener fechas únicas
        const data = labels.map(date => {
            return filteredIncidencias.filter(inc => new Date(inc.fecha_ingreso).toLocaleDateString() === date).length; // Contar incidencias por fecha
        });

        return {
            labels: labels,
            datasets: [
                {
                    label: `Incidencias de ${tipo}`,
                    data: data,
                    fill: false,
                    backgroundColor: 'rgba(0, 46, 255, 0.6)',
                    borderColor: 'rgba(0, 46, 255, 1)',
                    tension: 0.1,
                },
            ],
        };
    };

    // Configuración del carrusel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    return (
        <div className="admin-dashboard">
            <Menu userName={userName} isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} handleLogout={handleLogout} />
            <main className="content">
                <div className="dashboard-container">
                    <div className="tendencias">
                        <h2>Incidencias Titanium</h2>
                        <Slider {...settings}>
                            <div className="chart-container">
                                <Bar data={getBarChartData()} options={{
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            position: 'top',
                                        },
                                        title: {
                                            display: true,
                                            text: 'Total de Incidencias por Tipo',
                                        },
                                    },
                                    scales: {
                                        y: {
                                            ticks: {												
                                                callback: function(value) {
                                                    return Number.isInteger(value) ? value : ''; 
                                                }
                                            }
                                        }
                                    }
                                }} />
                            </div>
                            {tiposIncidencias.map((tipo) => {
                                const { total } = countIncidenciasByType(tipo.name);
                                if (total > 0) { 
                                    return (
                                        <div key={tipo.name} className="line-chart-container">
                                            <h3>{tipo.name}</h3>
                                            <Line data={getLineChartData(tipo.name)} options={{
                                                responsive: true,
                                                scales: {
                                                    y: {
                                                        ticks: {
                                                            callback: function(value) {
                                                                return Number.isInteger(value) ? value : ''; // Muestra solo enteros
                                                            }
                                                        }
                                                    }
                                                }
                                            }} />
                                        </div>
                                    );
                                }
                                return null; 
                            })}
                        </Slider>
                        <div className="dashboard-container">
                            <p>&nbsp;</p>
                            <button className="btn-add-incidencia" onClick={handleAdd} aria-label="Agregar incidencia">
                                <i className="fa fa-plus"></i> &nbsp;Agregar Incidencia
                            </button>
                        </div>
                    </div>
                    <div className="incidencias-grid">
                        {tiposIncidencias.map((tipo) => {
                            const { total, finalized } = countIncidenciasByType(tipo.name);
                            const iconColor = finalized === total ? '#002eff' : '#ff3d00'; 
                            const textColor = finalized === total ? '#002eff' : '#ff3d00'; 
                            const textSize = { fontSize: '1.3em' }; 
                            
							
                            return (
                                <Link to={`/incidenciasgrupo/${tipo.name}`} key={tipo.name} className="incidencia-card"> {/*  */}
                                    <FontAwesomeIcon icon={tipo.icon} size="3x" style={{ color: iconColor }} />
                                    <h4>{tipo.name}</h4>
                                    <p style={{ color: textColor, ...textSize }}>{finalized}/{total}</p>
                                </Link>
                            );
                        })}
                    </div>
                </div>
            </main>
            
        </div>
    );
};

export default AdminDashboard;
