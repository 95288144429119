import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../styles/IncidenciasGrupo.css'; 
import axios from 'axios';
import Slider from 'react-slick'; 
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css'; 
import DataTable from 'react-data-table-component'; 
import Menu from './Menu';
import { Line, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, Title, Tooltip, Legend } from 'chart.js'; 


const EquiposGrupo = () => {
    const { tipo } = useParams(); 
    const [equipos, setEquipos] = useState([]);
    const [userName, setUserName] = useState('');
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/'); 
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen); 
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decodedToken = JSON.parse(atob(token.split('.')[1]));
            const nombre = decodeURIComponent(escape(decodedToken.nombre));
            const apellido = decodeURIComponent(escape(decodedToken.apellido));
            setUserName(`${nombre} ${apellido}`);
        } else {
            navigate('/'); 
        }

        const fetchEquipos = async () => {
			try {
				const token = localStorage.getItem('token');
				let response;
				let filteredEquipos = [];
				
				if (tipo === 'CLIMATIZACIÓN') {
					response = await axios.get(process.env.REACT_APP_API_MANTE_EQUIPOS_CLIMA_TITAN, {
						headers: {
							Authorization: `Bearer ${token}`,
						},
					});
					filteredEquipos = response.data;
				} else {
					response = await axios.get(process.env.REACT_APP_API_MANTE_EQUIPOS_TITAN, {
						headers: {
							Authorization: `Bearer ${token}`,
						},
					});
					filteredEquipos = response.data.filter(equ => equ.tipo === tipo);
				}
				
				const sortedEquipos = filteredEquipos.sort((a, b) => {
					if (a.realizado === 'NO' && b.realizado !== 'NO') return -1; 
					if (a.realizado !== 'NO' && b.realizado === 'NO') return 1; 
					 //
				});

				setEquipos(sortedEquipos);
			} catch (error) {
				console.error('Error al obtener los equipos:', error);
			}
		};

        fetchEquipos();
    }, [tipo, navigate]);
	
	// Función para obtener datos de equipos última semana
		const getWeeklyData = () => {
			const today = new Date();
			const lastWeekData = Array(7).fill(0);
			const lastWeekDates = Array.from({ length: 7 }, (_, i) => {
				const date = new Date();
				date.setDate(today.getDate() - i);
				return date.toLocaleDateString();
			});

			equipos.forEach(equ => {
				const incDate = new Date(equ.fecha_mant).toLocaleDateString();
				const index = lastWeekDates.indexOf(incDate);
				// Agregar condición para verificar que 'realizado' sea igual a 'SI'
				if (index !== -1 && equ.realizado === 'SI') {
					lastWeekData[index]++;
				}
			});

			return {
				labels: lastWeekDates.reverse(),
				data: lastWeekData.reverse(),
			};
		};
			
	

	// Función para obtener datos de mantenimientos del año en curso
	const getMonthlyData = () => {
		const currentYear = new Date().getFullYear();
		const monthlyData = Array(12).fill(0);
		const months = Array.from({ length: 12 }, (_, i) => new Date(currentYear, i).toLocaleString('default', { month: 'long' }));

		equipos.forEach(equ => {
			const incDate = new Date(equ.fecha_gantt);
			if (incDate.getFullYear() === currentYear  && 
				equ.realizado === 'SI') {
				monthlyData[incDate.getMonth()]++;
			}
		});

		// Filtrar los meses que han transcurrido hasta el día actual
		const currentMonth = new Date().getMonth();
		return {
			labels: months.slice(0, currentMonth + 1), // Solo mostrar meses hasta el actual
			data: monthlyData.slice(0, currentMonth + 1),
		};
	};
	
	

	// Función para obtener datos de equipos por piso en el último mes
    const getEquiposPorPiso = () => {
        const currentDate = new Date();
        const lastMonthData = {};
        
        equipos.forEach(equ => {
			const incDate = new Date(equ.fecha_gantt);
			
			if (incDate.getMonth() === currentDate.getMonth() && 
				incDate.getFullYear() === currentDate.getFullYear() && 
				equ.realizado === 'SI') {
				
				const piso = equ.piso;
				lastMonthData[piso] = (lastMonthData[piso] || 0) + 1;
			}
		});

        return {
            labels: Object.keys(lastMonthData),
            data: Object.values(lastMonthData),
        };
    };
	
	
	const weeklyData = getWeeklyData();
    const monthlyData = getMonthlyData();
	const equiposPorPiso = getEquiposPorPiso();
	
	
	const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    // Columnas para la tabla
    const columns = [
        
		{
			name: 'ID',
			selector: row => tipo === 'CLIMATIZACIÓN' ? row.id_equipo_clima : row.id_equipos_titan,
			sortable: true,
			cell: row => (
				<button 
					onClick={() => navigate(tipo === 'CLIMATIZACIÓN' ? `/detequipo-clima/${row.id_equipo_clima}?tipo=${row.tipo}` : `/detequipo-titan/${row.id_equipos_titan}?tipo=${row.checklist}`)} 
					style={{ 
						backgroundColor: 'transparent',
						color: 'inherit', 
						border: 'none', 
						borderRadius: '5px', 
						padding: '0',
						cursor: 'pointer' 
					}}
				>
					<span className={row.realizado === 'NO' ? 'pill-active' : 'pill-finalized'}>
						{tipo === 'CLIMATIZACIÓN' ? row.id_equipo_clima : row.id_equipos_titan}
					</span>
					
				</button>
			),
			width: '100px', 
		},
        {
			name: 'Equipo',
			selector: row => tipo === 'CLIMATIZACIÓN' ? `UNIDAD ${row.unidad}` : row.nombre_equipo,
			sortable: true,
			cell: row => <div className="description">{tipo === 'CLIMATIZACIÓN' ? `UNIDAD ${row.unidad}` : row.nombre_equipo}</div>,
			width: '150px', 
		},
        {
            name: 'Ubicación',
            selector: row => tipo === 'CLIMATIZACIÓN' ? row.ubicacion : row.nomenclatura,
            sortable: true,
            width: '400px', 
        },
		{
            name: 'piso',
            selector: row => tipo === 'CLIMATIZACIÓN' ? `P${row.piso}` : `P ${row.piso}`,
            sortable: true,
            width: '100px', 
        },
        {
            name: 'Estado',
            selector: row => (
                <span className={row.estado === 'FALLA' ? 'pill-active' : 'pill-finalized'}>
                    {row.estado}
                </span>
            ),
            sortable: true,
            width: '110px', 
        },
		
		
        
    ];

    return (
		<div className="incidencias-grupo-container">
            <Menu userName={userName} isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} handleLogout={handleLogout} />
            <main className="content">
                <div className="dashboard-container">
                    <div className="tendencias">
                        <h2>&nbsp;</h2>
                        <Slider {...settings}>
                            {/* Aquí puedes agregar los gráficos de tendencias cuando estén disponibles */}
                            <div>
                                <h3>Mantenimientos Diarios (Última Semana)</h3>
                                <Line 
                                    data={{
                                        labels: weeklyData.labels,
                                        datasets: [{
                                            label: 'Mantenimientos Diarios',
                                            data: weeklyData.data,
                                            backgroundColor: 'rgba(0, 46, 255, 0.6)',
                                            borderColor: 'rgba(0, 46, 255, 1)',
                                            borderWidth: 2,
                                            fill: false,
                                        }],
                                    }} 
                                    options={{
                                        responsive: true,
                                        plugins: {
                                            legend: {
												position: 'top',
                                            },
                                            title: {
                                                display: true,
                                                text: 'Tendencia de Mantenimientos',
                                            },
                                        },
                                        scales: {
                                            y: {
                                                beginAtZero: true,
                                                ticks: {
                                                    
                                                    callback: function(value) {
                                                        return Number.isInteger(value) ? value : '';
                                                    }
                                                }
                                            },
                                        },
                                    }} 
                                />
                            </div>
                            <div>
                               <h3>Mantenimientos 2025</h3>
                                <Bar 
                                    data={{
                                        labels: monthlyData.labels,
                                        datasets: [{
                                            label: 'Mantenimientos Mensuales',
                                            data: monthlyData.data,
                                            backgroundColor: 'rgba(0, 46, 255, 0.6)', 
											borderColor: 'rgba(0, 46, 255, 1)',
                                            borderWidth: 1,
                                        }],
                                    }} 
                                    options={{
                                        responsive: true,
                                        plugins: {
                                            legend: {
                                                position: 'top',
                                            },
                                            title: {
                                                display: true,
                                                text: 'Tendencia de Mantenimientos Mensuales',
                                            },
                                        },
                                        scales: {
                                            y: {
                                                beginAtZero: true,
                                                ticks: {
                                                    
                                                    callback: function(value) {
                                                        return Number.isInteger(value) ? value : '';
                                                    }
                                                }
                                            },
                                        },
                                    }} 
                                />
                            </div>
							<div>
								<h3>Mantenimientos por Piso (Último Mes)</h3>
                                <Bar 
                                    data={{
                                        labels: equiposPorPiso.labels,
                                        datasets: [{
                                            label: 'Mantenimientos por Piso',
                                            data: equiposPorPiso.data,
                                            backgroundColor: 'rgba(0, 46, 255, 0.6)', // Color de las barras
											borderColor: 'rgba(0, 46, 255, 1)',
                                            borderWidth: 1,
                                        }],
                                    }} 
                                    options={{
                                        responsive: true,
                                        plugins: {
                                            legend: {
                                                position: 'top',
                                            },
                                            title: {
                                                display: true,
                                                text: 'Mantenimientos por Piso en el Último Mes',
                                            },
                                        },
                                        scales: {
                                            y: {
                                                beginAtZero: true,
                                                ticks: {
                                                    
                                                    callback: function(value) {
                                                        return Number.isInteger(value) ? value : '';
                                                    }
                                                }
                                            },
                                        },
                                    }} 
                                />
							</div>
                        </Slider>
                    </div>
                    <div className="incidencias-table">
                        <h2>Mantenimientos {tipo} Edificio Titanium</h2>
                        <DataTable
                            columns={columns}
                            data={equipos} 
                            pagination
                            sortServer
                            defaultSortFieldId="ID"
                            defaultSortAsc={false} 
                            subHeader
                            subHeaderComponent={
                                <input
                                    type="text"
                                    placeholder="Buscar..."
                                    onChange={e => {
                                        const value = e.target.value.toLowerCase();
                                        const filteredData = equipos.filter(equipo => {
                                           
                                            const piso = equipo.piso ? equipo.piso.toString() : ''; 
                                            return piso.includes(value);
                                        });
                                        setEquipos(filteredData); 
                                    }}
                                    style={{
                                        marginBottom: '10px',
                                        padding: '5px',
                                        width: '100%',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc'
                                    }}
                                />
                            }
                        />
                    </div>
                </div>
            </main>
        </div>
    );
};

export default EquiposGrupo;