import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Menu from './Menu';
import '../styles/AgregaIncidencias.css'; 

const AgregarIncidenciaszn = () => {
    const navigate = useNavigate();
    const [tipoIncidencia, setTipoIncidencia] = useState('');
    const [afectaDisponibilidad, setAfectaDisponibilidad] = useState('');
    const [piso, setPiso] = useState('-5');
    const [equipo, setEquipo] = useState('');
    const [userName, setUserName] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [idu, setIdu] = useState(''); 
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [zonas, setZonas] = useState([]);
    const [instalaciones, setInstalaciones] = useState([]);
    const [areas, setAreas] = useState([]);
    const [selectedZona, setSelectedZona] = useState('');
    const [formData, setFormData] = useState({
        zona: '',
        instala: '',
        area: '',
        ticket: '',
        incidencia: '',
        
    });

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decodedToken = JSON.parse(atob(token.split('.')[1]));
            const nombre = decodeURIComponent(escape(decodedToken.nombre));
            const apellido = decodeURIComponent(escape(decodedToken.apellido));
            setIdu(decodeURIComponent(escape(decodedToken.idu))); 
            setUserName(`${nombre} ${apellido}`);
        } else {
            navigate('/'); 
        }
		
		
    }, [navigate]); 
	
	
	useEffect(() => {
		const fetchZonas = async () => {
			const token = localStorage.getItem('token');
			if (!token) {
				navigate('/');
				return;
			}

			try {
				const response = await fetch(process.env.REACT_APP_API_ZONAS, {
					headers: { 'Authorization': `Bearer ${token}` }
				});
				
				const data = await response.json();
				setZonas(data || []);
				
			} catch (error) {
				console.error('Error obteniendo zonas:', error);
				setZonas([]);
			}
		};

		fetchZonas();
	}, [navigate]);

    useEffect(() => {
		const controller = new AbortController();
		const { signal } = controller;

		const fetchInstalaciones = async () => {
			const token = localStorage.getItem('token');
			if (!token || !selectedZona) {
				setInstalaciones([]);
				return;
			}

			try {
				const response = await fetch(
					`${process.env.REACT_APP_API_INSTALACIONES_ZN}/${encodeURIComponent(selectedZona)}`, 
					{
						headers: { 
							'Authorization': `Bearer ${token}` 
						},
						signal
					}
				);

				if (!response.ok) throw new Error('Error en la respuesta del servidor');

				const data = await response.json();
				if (!signal.aborted) {
					setInstalaciones(Array.isArray(data) ? data : []);
				}

			} catch (error) {
				if (error.name !== 'AbortError') {
					console.error('Error fetching instalaciones:', error);
					setInstalaciones([]);
				}
			}
		};

		fetchInstalaciones();

		return () => controller.abort();
	}, [selectedZona, navigate]); 
	
	
	const handleChange = (e) => {
        const { name, value } = e.target; 
        if (name === 'zona') setSelectedZona(value);
        
        setFormData(prev => ({
            ...prev,
            [name]: value 
        }));
    };
	
	const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/'); // Cambia esto
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen); 
    };
	
	
	const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        const response = await fetch(`${process.env.REACT_APP_API_ADD_INCI_ZN}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                id_usuario: idu,
                tipoincisala: tipoIncidencia,
                zona: formData.zona,
                instala: formData.instala,
                ticket: formData.ticket,
                incidencia: formData.incidencia
            }) // Cuerpo simplificado
        });
        
        if (response.ok) {
            alert('Incidencia creada con éxito');
            navigate('/admin-dashboard-zn'); 
        } else {
            alert('Error al crear la incidencia');
        }
    };
	
    return (
        <div className="agregar-container">
            <Menu userName={userName} isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} handleLogout={handleLogout} />
            <main className="content">
                <div className="dashboard-container">
					<div className="form-container" >
						<h2>Crear Incidencia - Zona Norte</h2>
						
						<form onSubmit={handleSubmit}>
						  <label>
							{/* Selector de Zona */}
							<select 
								id="zona"
								name="zona"
								className="form-control"
								value={formData.zona}
								onChange={handleChange}
								required
							>
								<option value="">Seleccionar Zona</option>
								{zonas.map((zona, index) => (
									<option key={index} value={zona.zona}>{zona.zona}</option>
								))}
							</select>
							</label>
							
							<label>
							{/* Selector de Instalación (dependiente de Zona) */}
							<div className="selector-lasinsta">
								<select 
									id="instala"
									name="instala"
									className="form-control"
									value={formData.instala}
									onChange={handleChange}
									required
								>
									<option value="">Seleccionar Instalación</option>
									{instalaciones.map((instala, index) => (
										<option key={index} value={instala.instalacion}>{instala.instalacion}</option>
									))}
								</select>
							</div>
							
							</label>
							<label>
							{/* Selector de Área */}
							<select 
                                    value={tipoIncidencia} 
                                    onChange={(e) => setTipoIncidencia(e.target.value)} 
                                    required
                                    className="form-control"
                                >
                                    <option value="">Seleccionar</option>
                                    <option value="CERRAJERÍA">CERRAJERÍA</option>
                                    <option value="ASCENSORES">ASCENSORES</option>
                                    <option value="CLIMA">CLIMA</option>
                                    <option value="CORTINAS">CORTINAS</option>
                                    <option value="ELECTRICIDAD">ELECTRICIDAD</option>
                                    <option value="GASFITERÍA">GASFITERÍA</option>
                                    <option value="ILUMINACIÓN">ILUMINACIÓN</option>
                                    <option value="JARDINERÍA">JARDINERÍA</option>
                                    <option value="OBRA CIVIL">OBRA CIVIL</option>
                                    <option value="OBRAS MENORES">OBRAS MENORES</option>
                                </select>
                            </label>
							
							
							<label>
							{/* Campo de Ticket */}
							<input 
								type="text"
								name="ticket"
								id="ticket"
								className="form-control"
								placeholder="N° de Ticket (AR)"
								value={formData.ticket}
								onChange={handleChange}
								required
							/>
							</label>
							<label>
							{/* Descripción de Incidencia */}
							<input 
								type="text"
								name="incidencia"
								id="incidencia"
								className="form-control"
								placeholder="Descripción de la Incidencia"
								value={formData.incidencia}
								onChange={handleChange}
								required
							/>
							<br />
							</label>
							
							

							<button type="submit" className="btn-create-incidencia">Crear Incidencia</button>
						</form>
				</div>			
            </div>
			</main>
        </div>
		
    );
};

export default AgregarIncidenciaszn;	