import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Menu from './Menu';
import '../styles/AgregaIncidencias.css'; 

const AgregarMantenimiento = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const location = useLocation(); 
    const queryParams = new URLSearchParams(location.search); 
    const checklist = queryParams.get('tipo'); 
	const [userName, setUserName] = useState('');
    const [tipoMantenimiento, setTipoMantenimiento] = useState('');
    const [observacionInicial, setObservacionInicial] = useState('');
    const [fotoInicial, setFotoInicial] = useState(null);
    const [idu, setIdu] = useState(''); 
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [mantenimientoIniciado, setMantenimientoIniciado] = useState(false);
    const [idcheck, setIdCheck] = useState('');
	const [fotoLuminaria, setFotoLuminaria] = useState(null); 
	const [fotoClima, setFotoClima] = useState(null); 
	const [fotoEstanque, setFotoEstanque] = useState(null); 
	const [fotoEstanqueRegistro, setFotoRegistroEstanque] = useState(null); 	
	const [fotoInfraestructura, setFotoInfraestructura] = useState(null); 
	const [fotoGasfiteria, setFotoGasfiteria] = useState(null); 
	const [fotoTableros, setFotoTableros] = useState(null);
	const [fotoExtintores, setFotoExtintores] = useState(null);	
	const [fotoTermoelectrico, setFotoTermoelectrico] = useState(null);	
	const [fotoMontaplatos, setFotoMontaplatos] = useState(null);	
	const [fotoPuertasEmergenciaNorte, setFotoPuertasEmergenciaNorte] = useState(null);	
	const [fotoPuertasEmergenciaSur, setFotoPuertasEmergenciaSur] = useState(null);	
	const [fotoLimpiezaEstanque, setFotoLimpiezaEstanque] = useState(null);	
	const [fotoElectrodomesticos, setFotoElectrodomesticos] = useState(null);
	const [fotoPuertasGiratorias, setFotoPuertasGiratorias] = useState(null);
	const [fotoPuertasAutomaticas, setFotoPuertasAutomaticas] = useState(null);	
	const [fotoPuertasAbatibles, setFotoPuertasAbatibles] = useState(null);		
	
	const [checklistClima, setChecklistClima] = useState({
        limpieza: '',
        revision_control: '',
        revision_panel: '',
        limpieza_filtro: '',
        toma: '',
        lavado: '',
        contactores: '',
        tarjeta: '',
        ruidos: '',
        valvula: '',
        conexiones: '',
        filtracion: '',
        pintura: '',
        compresor1: '',
        inyeccion1: '',
        retorno1: '',
		voltaje1: '',
        presiona1: '',
        presionb1: '',
        compresor2: '',
        inyeccion2: '',
        retorno2: '',
        voltaje2: '',
		presiona2: '',
        presionb2: '',
		nominal: '',
		motor: '',
		moto: '',
		calefactor: '',
        observaciones_clima: '',
		estado_clima: '',
        foto_clima: null,
    });
	
    
    const [checklistIluminaria, setChecklistIluminaria] = useState({
        pantalla: '',
        difusor: '',
        lamparas: '',
        cuerpo_equipo: '',
        estado_enchufes: '',
        estado_lamparas: '',
        estado_difusor: '',
        estado_pantalla: '',
        estado_cuerpo_equipo: '',
        estado_conexiones_electricas: '',
        estado_ballasts: '',
        estado_puntos_electricos: '',
        estado_base_lamparas: '',
        conexiones_luminaria: '',
        componentes_defectuosos: '',
        estado_luminaria: '',
        observaciones_luminaria: '',
        foto_luminaria: null,
    });
    const [checklistEstanque, setChecklistEstanque] = useState({
        estanque: '',
		piping: '',
		valvulas: '',
		manometros: '', 
		presion_retorno: '',
		estado_estanque_exp: '', 
		observaciones_estanque_exp: '', 
		foto_estanque: null, 
		foto_registro_exp: null 
    });
	
	const [checklistInfraestructura, setChecklistInfraestructura] = useState({
		puerta_escalera_emergencia: '',
		portones_revision_lubricacion: '',
		puertas_acceso_engrase: '',
		puertas_acceso_aplica: '',
		cajon_percheros: '',
		inspeccion_mobiliario: '',
		piso_alfombras: '',
		marmol_ceramica: '',
		estado_general_cielo: '',
		revision_estado_pinturas: '',
		aperturas_cables: '',
		separador_modulos: '',
		espacio_almacenamiento: '',
		base_estable: '',
		reposapies: '',
		cajones_funcionales: '',
		perchero_espacio_abrigos: '',
		estado_infraestructura:'',
		observaciones_infraestructura:'',
		foto_infraestructura: null,
	});

	const [checklistGasfiteria, setChecklistGasfiteria] = useState({
		aireadores: '',
		boton_fluxometro: '',
		cuerpo_fluxometro: '',
		lubricacion_piezas_moviles: '',
		ajuste_cuerpo_wc_lavamanos: '',
		ajuste_fisico_boton_panel: '',
		ajuste_parametros_control_remoto: '',
		griferia: '',
		llave_automatica_vanitorio: '',
		trizaduras: '',
		valvula_descarga_inodoros: '',
		funcionamiento_llave_paso: '',
		flexibles: '',
		ausencia_filtaciones: '',
		bateria_transformador: '',
		mariposa_llave: '',
		registro_vertical_aguas_servidas: '',
		estado_gasfiteria:'',
		observaciones_gasfiteria:'',
		foto_gasfiteria: null,
	});
	
	const [checklistTableros, setChecklistTableros] = useState({
		revision_senal: '',
        revision_limpieza: '',
        revision_conductores: '',
        reapriete: '',
        revision_contactores: '',
        operar: '',
        revision_ilumina: '',
        revision_rotulacion: '',
        limpieza_sala: '',
        revision_conductores2: '',
        reapriete2: '',
        operar2: '',
        revision_ilumina2: '',
        revision_fuerza: '',
        consumo_linea: '',
        consumo_linea_s: '',
		consumo_linea_t: '',
        consumo_neutro: '',
        tension_fase: '',
        tension_fase_s: '',
        tension_fase_t: '',
        tension_neutro: '',
        tension_neutro_s: '',
        tension_neutro_t: '',
		tension_tierra: '',
        balanceo: '',
        planilla: '',
        observaciones_tableros: '',
		estado_tableros: '',
		foto_tableros: null,
	});

	const [checklistExtintores, setChecklistExtintores] = useState({
		esta_certificado: '',
		desmontaje_exterior: '',
		manometro: '',
		gatillo_percutor: '',
		etiqueta_modo_uso: '',
		extintor_en_lugar: '',
		completamente_cargado: '',
		acceso_libre_obstrucciones: '',
		sello_seguridad: '',
		pasador_seguridad: '',
		pintura_buen_estado: '',
		cilindro_presenta_oxidacion: '',
		manguera_roturas: '',
		corneta_presenta_fisuras: '',
		valvula_presenta_danos: '',
		calcomanias_legibles: '',
		foto_extintores: null,
	});
	
	

	useEffect(() => {
		console.log('Estado de checklsit actualizado:', checklistEstanque);
	}, [checklistEstanque]);
	
	
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decodedToken = JSON.parse(atob(token.split('.')[1]));
            setIdu(decodedToken.idu); 
        } else {
            navigate('/'); 
        }
        checkMantenimientoIniciado();
    }, [navigate]); 
	
	const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/'); // Cambia esto
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen); 
    };

    const checkMantenimientoIniciado = async () => {
        const token = localStorage.getItem('token'); 
        const formData = new FormData();
        formData.append('id_equipo', id);
        formData.append('checklist', checklist);
        
        try {
            const response = await fetch(`${process.env.REACT_APP_API_REACT_ESTADO_MANTENIMIENTO_EQUIPO_ZN}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            });

            if (response.ok) {
                const data = await response.json();
                if (data && data.data) {
                    setMantenimientoIniciado(data.data.mantenimientoIniciado); 
                    setIdCheck(data.data.id_check);
					console.error('se asigna idcheck', data.data.id_check);
                } else {
                    console.error('No se recibieron datos válidos');
                }
            } else {
                const errorData = await response.json();
                console.error('Error al verificar el estado del mantenimiento:', errorData.message || 'Error desconocido');
                alert('Error al verificar el estado del mantenimiento: ' + (errorData.message || 'Error desconocido'));
            }
        } catch (error) {
            console.error('Error en la solicitud:', error);
            alert('Error en la solicitud: ' + error.message);
        }
    };

    const handleSubmitMantenimiento = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token'); 
		console.log('checklist de envío'+checklist)
        const formData = new FormData();
        formData.append('id_usuario', idu);
        formData.append('id_equipo', id);
        formData.append('tipo_mantenimiento', tipoMantenimiento);
        formData.append('notas_inicio', observacionInicial);
        formData.append('archivo', fotoInicial);
		formData.append('checklist', checklist);
		

        const response = await fetch(`${process.env.REACT_APP_API_REACT_MANTENIMIENTO_EQUIPO_INICIO_ZN}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
				
            },
            body: formData,
        });

        if (response.ok) {
            alert('Mantenimiento iniciado con éxito');
            checkMantenimientoIniciado();
        } else {
            alert('Error al iniciar el mantenimiento, por favor intenta nuevamente');
        }
    };

    const handleChecklistChange = (e) => {
		const { name, value, type } = e.target; 

		if (type === 'file') {
			const file = e.target.files[0]; 
			if (file) {
				if (checklist === 'CLIMA') {
					setChecklistClima(prevState => ({
						...prevState,
						[name]: file, 
					}));
				} else if (checklist === 'ILUMINACIÓN') {
					setChecklistIluminaria(prevState => ({
						...prevState,
						[name]: file, 
					}));
				} else if (checklist === 'ESTANQUE') {
					setChecklistEstanque(prevState => ({
						...prevState,
						[name]: file, 
					}));
				} else if (checklist === 'INFRAESTRUCTURA') {
					setChecklistInfraestructura(prevState => ({
						...prevState,
						[name]: file, 
					}));
				} else if (checklist === 'GASFITERIA') {
					setChecklistGasfiteria(prevState => ({
						...prevState,
						[name]: file, 
					}));
				} else if (checklist === 'EXTINTOR') {
					setChecklistExtintores(prevState => ({
						...prevState,
						[name]: file, 
					}));
				} else if (checklist === 'TABLERO') {
					setChecklistTableros(prevState => ({
						...prevState,
						[name]: file, 
					}));
				} 
			}
		} else {
			if (checklist === 'CLIMA') {
				setChecklistClima(prevState => ({
					...prevState,
					[name]: value, 
				}));
			} else if (checklist === 'ILUMINACIÓN') {
				setChecklistIluminaria(prevState => ({
					...prevState,
					[name]: value, 
				}));
			} else if (checklist === 'ESTANQUE') {
				setChecklistEstanque(prevState => ({
					...prevState,
					[name]: value, 
				}));
			} else if (checklist === 'INFRAESTRUCTURA') {
				setChecklistInfraestructura(prevState => ({
					...prevState,
					[name]: value, 
				}));
			} else if (checklist === 'GASFITERIA') {
				setChecklistGasfiteria(prevState => ({
					...prevState,
					[name]: value, 
				}));
			} else if (checklist === 'TABLERO') {
				setChecklistTableros(prevState => ({
					...prevState,
					[name]: value, 
								}));
			} else if (checklist === 'EXTINTOR') {
				setChecklistExtintores(prevState => ({
					...prevState,
					[name]: value, 
				}));
			} 
		}
	};

    const handleSubmitChecklist = async (e) => {
		e.preventDefault();
		const token = localStorage.getItem('token'); 
		const formData = new FormData(); 


		formData.append('id_equipo', id);
		formData.append('id_usuario', idu);
		formData.append('checklist', checklist);
		formData.append('id_check', idcheck);

		
		if (checklist === 'CLIMA') {
			Object.entries(checklistClima).forEach(([key, value]) => {
				formData.append(key, value); 
			});
		} else if (checklist === 'ILUMINACIÓN') {
			Object.entries(checklistIluminaria).forEach(([key, value]) => {
				formData.append(key, value); 
			});
		} else if (checklist === 'ESTANQUE') {
			Object.entries(checklistEstanque).forEach(([key, value]) => {
				formData.append(key, value); 
			});
		} else if (checklist === 'GASFITERIA') {
			Object.entries(checklistGasfiteria).forEach(([key, value]) => {
				formData.append(key, value); 
			});
		} else if (checklist === 'INFRAESTRUCTURA') {
			Object.entries(checklistInfraestructura).forEach(([key, value]) => {
				formData.append(key, value); 
			});
		} else if (checklist === 'TABLERO') {
			Object.entries(checklistTableros).forEach(([key, value]) => {
				formData.append(key, value); 
			});
		} else if (checklist === 'EXTINTOR') {
			Object.entries(checklistExtintores).forEach(([key, value]) => {
				formData.append(key, value); 
			});
		} 
		

		// Agregar archivos al FormData
		if (fotoLuminaria) {
			
			formData.append('foto_luminaria', fotoLuminaria); 
		}
		if (fotoClima) {
			formData.append('foto_clima', fotoClima);
			
		}
		if (fotoInfraestructura) {
			formData.append('foto_infraestructura', fotoInfraestructura);
			
		}
		
		if (fotoGasfiteria) {
			formData.append('foto_gasfiteria', fotoGasfiteria);
			
		}
		
		if (fotoTableros) {
			formData.append('foto_tableros', fotoTableros);
			
		}
		if (fotoEstanque) {
			formData.append('foto_estanque', fotoEstanque);
			
		}
		
		if (fotoEstanqueRegistro) {
			formData.append('foto_estanque_registro', fotoEstanqueRegistro);
			
		}
		if (fotoExtintores) {
			formData.append('foto_extintores', fotoExtintores);
			
		}
		
		
		//console.log('Datos del FormData antes de enviar:', Array.from(formData.entries()));
		// Verifica el contenido de formData
		console.log('Datos del FormData:', Array.from(formData.entries())); // Muestra los datos que se están enviando
			console.log('idcheckparaenviarya',idcheck);
			console.log('foto_clima',fotoClima);
		// Realiza la solicitud POST para guardar el checklist
		try {
			
			const response = await fetch(`${process.env.REACT_APP_API_REACT_GUARDA_MANTENIMIENTO_ZN}`, {
				method: 'POST',
				headers: {
					'Authorization': `Bearer ${token}`, // Agrega el token de autorización
				},
				body: formData, // Envía el FormData con todos los datos
			});
			
			const responseText = await response.text(); // Obtiene la respuesta como texto
			console.log('Respuesta del servidor:', responseText); // Muestra la respuesta en la consola


			if (response.ok) {
				alert('Checklist guardado con éxito'); // Mensaje de éxito
				
				 navigate(`/detequipo-zn/${id}?tipo=${checklist}`); 
			} else {
				const errorData = await response.json(); // Obtiene los datos de error
				alert('Error al guardar el checklist: ' + (errorData.message || 'Por favor intenta nuevamente')); // Mensaje de error
			}
		} catch (error) {
			console.error('Error en la solicitud:', error); // Muestra el error en la consola
			alert('Error al guardar el checklist, por favor intenta nuevamente'); // Mensaje de error
		}
	};
    return (
        <div className="agregar-container" >
            <Menu userName={userName} isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} handleLogout={handleLogout} />
            <main className="content" >
                <div className="dashboard-container" >
					<div className="form-container" >
					 <h2>{mantenimientoIniciado ? 'Completar Checklist' : 'Iniciar Mantenimiento'}</h2>
                    {!mantenimientoIniciado ? (
                        <form onSubmit={handleSubmitMantenimiento}>
                            <label>
                                Tipo de mantenimiento:
                                <select value={tipoMantenimiento} onChange={(e) => setTipoMantenimiento(e.target.value)} required>
                                    <option value="">Seleccionar</option>
                                    <option value="MANTENIMIENTO PREVENTIVO">Mantenimiento Preventivo</option>
                                    <option value="MANTENIMIENTO CORRECTIVO">Mantenimiento Correctivo</option>
                                </select>
                            </label>

                            <label>
                                Observación inicial (Opcional):
                                <input
                                    type="text"
                                    value={observacionInicial}
                                    onChange={(e) => setObservacionInicial(e.target.value)}
                                    placeholder="Observación inicial"
                                />
                            </label>

                            <label>
                                Foto inicial:
                                <input
                                    type="file"
                                    accept=".png, .jpg, .jpeg"
                                    onChange={(e) => setFotoInicial(e.target.files[0])}
                                    required
                                />
                            </label>

                            <button type="submit" className="btn-create-incidencia" >Iniciar Mantenimiento</button>
                        </form>
                    ) : (
                        <form onSubmit={handleSubmitChecklist}>
                            {checklist === 'CLIMA' && (
								<>
									<h3>Checklist Equipos Clima Zona Norte</h3>
									<p><b>UNIDAD EXTERIOR</b></p><br />
									<div>
										<label>
											Limpieza:
											<select name="limpieza" value={checklistClima.limpieza} onChange={handleChecklistChange} required>
												<option value="">Seleccionar</option>
												<option value="OK">OK</option>
												<option value="SI">SI</option>
												<option value="NO">NO</option>
												<option value="NA">NA</option>
											</select>
										</label>
									</div>
									<div>
										<label>
											Revisión control remoto:
											<select name="revision_control" value={checklistClima.revision_control} onChange={handleChecklistChange} required>
												<option value="">Seleccionar</option>
												<option value="OK">OK</option>
												<option value="SI">SI</option>
												<option value="NO">NO</option>
												<option value="NA">NA</option>
											</select>
										</label>
									</div>
									<div>
										<label>
											Revisión panel led e indicadores:
											<select name="revision_panel" value={checklistClima.revision_panel} onChange={handleChecklistChange} required>
												<option value="">Seleccionar</option>
												<option value="OK">OK</option>
												<option value="SI">SI</option>
												<option value="NO">NO</option>
												<option value="NA">NA</option>
											</select>
										</label>
									</div>
									<div>
										<label>
											Limpieza filtros (Si los hay):
											<select name="limpieza_filtro" value={checklistClima.limpieza_filtro} onChange={handleChecklistChange} required>
												<option value="">Seleccionar</option>
												<option value="OK">OK</option>
												<option value="SI">SI</option>
												<option value="NO">NO</option>
												<option value="NA">NA</option>
											</select>
										</label>
									</div>
									<div>
										<label>
											Toma de temperatura inyección:
											<select name="toma" value={checklistClima.toma} onChange={handleChecklistChange} required>
												<option value="">Seleccionar</option>
												<option value="OK">OK</option>
												<option value="SI">SI</option>
												<option value="NO">NO</option>
												<option value="NA">NA</option>
											</select>
										</label>
									</div>
									<p><b>UNIDAD EXTERIOR</b></p><br />
									<div>
										<label>
											Lavado condensador y evaporador:
											<select name="lavado" value={checklistClima.lavado} onChange={handleChecklistChange} required>
												<option value="">Seleccionar</option>
												<option value="OK">OK</option>
												<option value="SI">SI</option>
												<option value="NO">NO</option>
												<option value="NA">NA</option>
											</select>
										</label>
									</div>
									<div>
										<label>
											Contactores y termostatos:
											<select name="contactores" value={checklistClima.contactores} onChange={handleChecklistChange} required>
												<option value="">Seleccionar</option>
												<option value="OK">OK</option>
												<option value="SI">SI</option>
												<option value="NO">NO</option>
												<option value="NA">NA</option>
											</select>
										</label>
									</div>
											<div>
										<label>
											Ruido anormales equipo y rodamientos:
											<select name="ruido_anormales" value={checklistClima.ruido_anormales} onChange={handleChecklistChange} required>
												<option value="">Seleccionar</option>
												<option value="OK">OK</option>
												<option value="SI">SI</option>
												<option value="NO">NO</option>
												<option value="NA">NA</option>
											</select>
										</label>
									</div>
									<div>
										<label>
											Tarjeta de control:
											<select name="tarjeta" value={checklistClima.tarjeta} onChange={handleChecklistChange} required>
												<option value="">Seleccionar</option>
												<option value="OK">OK</option>
												<option value="SI">SI</option>
												<option value="NO">NO</option>
												<option value="NA">NA</option>
											</select>
										</label>
									</div>
									<div>
										<label>
											Ruidos y vibraciones:
											<select name="ruidos" value={checklistClima.ruidos} onChange={handleChecklistChange} required>
												<option value="">Seleccionar</option>
												<option value="OK">OK</option>
												<option value="SI">SI</option>
												<option value="NO">NO</option>
												<option value="NA">NA</option>
											</select>
										</label>
									</div>
									<div>
										<label>
											Válvula reversible:
											<select name="valvula" value={checklistClima.valvula} onChange={handleChecklistChange} required>
												<option value="">Seleccionar</option>
												<option value="OK">OK</option>
												<option value="SI">SI</option>
												<option value="NO">NO</option>
												<option value="NA">NA</option>
											</select>
										</label>
									</div>
									<div>
										<label>
											Conexiones eléctricas:
											<select name="conexiones" value={checklistClima.conexiones} onChange={handleChecklistChange} required>
												<option value="">Seleccionar</option>
												<option value="OK">OK</option>
												<option value="SI">SI</option>
												<option value="NO">NO</option>
												<option value="NA">NA</option>
											</select>
										</label>
									</div>
									<div>
										<label>
											Filtración de refrigerante:
											<select name="filtracion" value={checklistClima.filtracion} onChange={handleChecklistChange} required>
												<option value="">Seleccionar</option>
												<option value="OK">OK</option>
												<option value="SI">SI</option>
												<option value="NO">NO</option>
												<option value="NA">NA</option>
											</select>
										</label>
									</div>
									<div>
										<label>
											Pintura equipo (Óxidos y pintura):
											<select name="pintura" value={checklistClima.pintura} onChange={handleChecklistChange} required>
												<option value="">Seleccionar</option>
												<option value="OK">OK</option>
												<option value="SI">SI</option>
												<option value="NO">NO</option>
												<option value="NA">NA</option>
											</select>
										</label>
									</div>
									<br />
									<p><b>REGISTRO CICLO FRÍO</b></p><br />
									<div>
										<label>
											Corriente compresor (AMP):
											<input
												type="number"
												step="0.01"
												name="compresor1"
												value={checklistClima.compresor1}
												onChange={handleChecklistChange}
												required
											/>
										</label>
									</div>
									<div>
										<label>
											Temperatura inyección (°C):
											<input
												type="number"
												step="0.01"
												name="inyeccion1"
												value={checklistClima.inyeccion1}
												onChange={handleChecklistChange}
												required
											/>
										</label>
									</div>
									<div>
										<label>
											Temperatura retorno (°C):
											<input
												type="number"
												step="0.01"
												name="retorno1"
												value={checklist                    .retorno1}
												onChange={handleChecklistChange}
												required
											/>
										</label>
									</div>
									<div>
										<label>
											Voltaje (V°):
											<input
												type="number"
												step="0.01"
												name="voltaje1"
												value={checklistClima.voltaje1}
												onChange={handleChecklistChange}
												required
											/>
										</label>
									</div>
									<div>
										<label>
											Presión Alta (PSI):
											<input
												type="number"
												step="0.01"
												name="presiona1"
												value={checklistClima.presiona1}
												onChange={handleChecklistChange}
												required
											/>
										</label>
									</div>
									<div>
										<label>
											Presión Baja (PSI):
											<input
												type="number"
												step="0.01"
												name="presionb1"
												value={checklistClima.presionb1}
												onChange={handleChecklistChange}
												required
											/>
										</label>
									</div>
									
									<br />
									<p><b>REGISTRO CICLO CALOR</b></p><br />
									<div>
										<label>
											Corriente compresor (AMP):
											<input
												type="number"
												step="0.01"
												name="compresor2"
												value={checklistClima.compresor2}
												onChange={handleChecklistChange}
												required
											/>
										</label>
									</div>
									<div>
										<label>
											Temperatura inyección (°C):
											<input
												type="number"
												step="0.01"
												name="inyeccion2"
												value={checklistClima.inyeccion2}
												onChange={handleChecklistChange}
												required
											/>
										</label>
									</div>
									<div>
										<label>
											Temperatura retorno (°C):
											<input
												type="number"
												step="0.01"
												name="retorno2"
												value={checklistClima.retorno2}
												onChange={handleChecklistChange}
												required
											/>
										</label>
									</div>
									<div>
										<label>
											Voltaje (V°):
											<input
												type="number"
												step="0.01"
												name="voltaje2"
												value={checklistClima.voltaje2}
												onChange={handleChecklistChange}
												required
											/>
										</label>
									</div>
									<div>
										<label>
											Presión Alta (PSI):
											<input
												type="number"
												step="0.01"
												name="presiona2"
												value={checklistClima.presiona2}
												onChange={handleChecklistChange}
												required
											/>
										</label>
									</div>
									<div>
										<label>
											Presión Baja (PSI):
											<input
												type="number"
												step="0.01"
												name="presionb2"
												value={checklistClima.presionb2}
												onChange={handleChecklistChange}
												required
											/>
										</label>
									</div>
									
									<br />
									<p><b>REGISTROS</b></p>
									<div>
										<label>
											Corriente nominal compresor (AMP):
											<input
												type="number"
												step="0.01"
												name="nominal"
												value={checklistClima.nominal}
												onChange={handleChecklistChange}
												required
											/>
										</label>
									</div>
									<div>
										<label>
											Corriente motor ventilador (AMP):
											<input
												type="number"
												step="0.01"
												name="motor"
												value={checklistClima.motor}
												onChange={handleChecklistChange}
												required
											/>
										</label>
									</div>
									<div>
										<label>
											Corriente nominal moto ventilador (AMP):
											<input
												type="number"
												step="0.01"
												name="moto"
												value={checklistClima.moto}
												onChange={handleChecklistChange}
												required
											/>
										</label>
									</div>
									<div>
										<label>
											Corriente Calefactor (si existe) (AMP):
											<input
												type="number"
												step="0.01"
												name="calefactor"
												value={checklistClima.calefactor}
												onChange={handleChecklistChange}
												required
											/>
										</label>
									</div>
									<div>
												   <label>
											Estado en que deja el equipo:
											<select name="estado_clima" value={checklistClima.estado_bombas} onChange={handleChecklistChange} required>
												<option value="">Seleccionar</option>
												<option value="OPERATIVO">OPERATIVO</option>
												<option value="FALLA">FALLA</option>
											</select>
										</label>
									</div>
									<br />
									
									<div>
										<label>
											Observaciones del mantenimiento:
											<input
												type="text"
												name="observaciones_clima"
												value={checklistClima.observaciones_clima || ''} 
												onChange={handleChecklistChange}
											/>
										</label>
									</div>
									<div>
										<label>
											Foto mantenimiento:
											<input
												type="file"
												accept=".png, .jpg, .jpeg"
												name="foto_clima"
												onChange={(e) => setFotoClima(e.target.files[0])} 
												required
											/>
										</label>
									</div>
								</>
							)}

                            {checklist === 'ILUMINACIÓN' && (
                                <>
                                    <h3>Checklist Iluminaria</h3>
                                    <div>
                                        <label>
                                            Pantalla:
                                            <select name="pantalla" value={checklistIluminaria.pantalla} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Difusor:
                                            <select name="difusor" value={checklistIluminaria.difusor} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Lámparas:
                                            <select name="lamparas" value={checklistIluminaria.lamparas} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Cuerpo equipo:
                                            <select name="cuerpo_equipo" value={checklistIluminaria.cuerpo_equipo} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Estado enchufes:
                                            <select name="estado_enchufes" value={checklistIluminaria.estado_enchufes} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Estado lámparas:
                                            <select name="estado_lamparas" value={checklistIluminaria.estado_lamparas} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Estado difusor:
                                            <select name="estado_difusor" value={checklistIluminaria.estado_difusor} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Estado pantalla:
                                                                                        <select name="estado_pantalla" value={checklistIluminaria.estado_pantalla} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Estado cuerpo equipo:
                                            <select name="estado_cuerpo_equipo" value={checklistIluminaria.estado_cuerpo_equipo} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Estado conexiones eléctricas:
                                            <select name="estado_conexiones_electricas" value={checklistIluminaria.estado_conexiones_electricas} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Estado ballasts:
                                            <select name="estado_ballasts" value={checklistIluminaria.estado_ballasts} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Estado puntos eléctricos (enchufes, tapas, etc.):
                                            <select name="estado_puntos_electricos" value={checklistIluminaria.estado_puntos_electricos} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Estado base lámparas:
                                            <select name="estado_base_lamparas" value={checklistIluminaria.estado_base_lamparas} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Conexiones:
                                            <select name="conexiones_luminaria" value={checklistIluminaria.conexiones_luminaria} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Componentes defectuosos:
                                            <select name="componentes_defectuosos" value={checklistIluminaria.componentes_defectuosos} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
									<div>
                                        <label>
                                            Estado en que deja el equipo:
                                            <select name="estado_luminaria" value={checklistIluminaria.estado_luminaria} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OPERATIVO">OPERATIVO</option>
                                                <option value="FALLA">FALLA</option>
                                               
                                            </select>
                                        </label>
                                    </div>
									<div>
										<label>
											Observaciones del mantenimiento:
											<input
												type="text"
												name="observaciones_luminaria"
												value={checklistIluminaria.observaciones_luminaria || ''} 
												onChange={handleChecklistChange}
											/>
										</label>
									</div>
									<div>
										<label>
											Foto mantenimiento:
											<input
											type="file"
											accept=".png, .jpg, .jpeg"
											name="foto_luminaria" 
											onChange={(e) => setFotoLuminaria(e.target.files[0])}
											
											required
										/>
										</label>
									</div>
                                </>
                            )}

                            {checklist === 'ESTANQUE' && (
                                <>
                                    <h3>Checklist Estanque de Expansión</h3>
                                    <div>
                                        <label>
                                            Registro fotográfico de placas de estanque:
                                            <input
                                                type="file"
												accept=".png, .jpg, .jpeg"
												name="foto_estanque_registro"
												onChange={(e) => setFotoRegistroEstanque(e.target.files[0])} 
												required
                                            />
                                        </label>
                                    </div>
                                    <div>
								<label>
                                            Limpieza superficie - Estanque:
                                            <select name="estanque" value={checklistEstanque.estanque} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Limpieza superficie - Piping:
                                            <select name="piping" value={checklistEstanque.piping} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Limpieza superficie - Válvulas:
                                            <select name="valvulas" value={checklistEstanque.valvulas} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Limpieza superficie - Manómetros:
                                            <select name="manometros" value={checklistEstanque.manometros} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OK">OK</option>
                                                <option value="SI">SI</option>
                                                <option value="NO">NO</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Presión de retorno de sistema secundario (en manifold de retorno):
                                            <input
                                                type="number"
                                                name="presion_retorno"
                                                value={checklistEstanque.presion_retorno}
                                                onChange={handleChecklistChange}
                                                placeholder="Ingrese la presión"
                                                required
                                            />
                                        </label>
                                    </div>
									<div>
                                        <label>
                                            Estado en que deja el equipo:
                                            <select name="estado_estanque_exp" value={checklistEstanque.estado_estanque_exp} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OPERATIVO">OPERATIVO</option>
                                                <option value="FALLA">FALLA</option>
                                               
                                            </select>
                                        </label>
                                    </div>
									<div>
										<label>
											Observaciones del mantenimiento:
											<input
												type="text"
												name="observaciones_estanque_exp"
												value={checklistEstanque.observaciones_estanque_exp || ''} 
												onChange={handleChecklistChange}
											/>
										</label>
									</div>
									<div>
										<label>
											Foto mantenimiento:
											<input
												type="file"
												accept=".png, .jpg, .jpeg"
												name="foto_estanque"
												onChange={(e) => setFotoEstanque(e.target.files[0])} 
												required
											/>
											
										</label>
									</div>
                                </>
                            )}
							
							{checklist === 'INFRAESTRUCTURA' && (
							<>
								<h3>Checklist Infraestructura</h3>
								<div>
									<label>
										Puerta de escalera de emergencia:
										<select name="puerta_escalera_emergencia" value={checklistInfraestructura.puerta_escalera_emergencia} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Portones (revisión y lubricación):
										<select name="portones_revision_lubricacion" value={checklistInfraestructura.portones_revision_lubricacion} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Puertas y acceso (engrase y/o lubricación de bujes):
										<select name="puertas_acceso_engrase" value={checklistInfraestructura.puertas_acceso_engrase} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Puertas y/o accesos (si aplica):
										<select name="puertas_acceso_aplica" value={checklistInfraestructura.puertas_acceso_aplica} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Cajón y percheros:
										<select name="cajon_percheros" value={checklistInfraestructura.cajon_percheros} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Inspección mobiliario (sillas, mesas, estantes, etc.):
										<select name="inspeccion_mobiliario" value={checklistInfraestructura.inspeccion_mobiliario} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Piso y alfombras:
										<select name="piso_alfombras" value={checklistInfraestructura.piso_alfombras} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Mármol y cerámica (si aplica):
										<select name="marmol_ceramica" value={checklistInfraestructura.marmol_ceramica} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Estado general de cielo:
										<select name="estado_general_cielo" value={checklistInfraestructura.estado_general_cielo} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Revisión estado general de pinturas:
										<select name="revision_estado_pinturas" value={checklistInfraestructura.revision_estado_pinturas} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Aperturas para cables:
										<select name="aperturas_cables" value={checklistInfraestructura.aperturas_cables} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Separador de módulos:
										<select name="separador_modulos" value={checklistInfraestructura.separador_modulos} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Espacio para almacenamiento:
										<select name="espacio_almacenamiento" value={checklistInfraestructura.espacio_almacenamiento} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Base estable:
										<select name="base_estable" value={checklistInfraestructura.base_estable} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Reposapiés:
										<select name="reposapies" value={checklistInfraestructura.reposapies} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Cajones funcionales:
										<select name="cajones_funcionales" value={checklistInfraestructura.cajones_funcionales} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Perchero o espacio para colgar abrigos:
										<select name="perchero_espacio_abrigos" value={checklistInfraestructura.perchero_espacio_abrigos} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
                                        <label>
                                            Estado en que deja el equipo:
                                            <select name="estado_infraestructura" value={checklistInfraestructura.estado_infraestructura} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OPERATIVO">OPERATIVO</option>
                                                <option value="FALLA">FALLA</option>
                                               
                                            </select>
                                        </label>
                                    </div>
									<div>
										<label>
											Observaciones del mantenimiento:
											<input
												type="text"
												name="observaciones_infraestructura"
												value={checklistInfraestructura.observaciones_infraestructura || ''} 
												onChange={handleChecklistChange}
											/>
										</label>
								</div>
								<div>
									<label>
										Foto Mantenimiento:
										<input
												type="file"
												accept=".png, .jpg, .jpeg"
												name="foto_infraestructura"
												onChange={(e) => setFotoInfraestructura(e.target.files[0])} 
												required
											/>
									</label>
								</div>
							</>
						)}

						{checklist === 'GASFITERIA' && (
							<>
								<h3>Checklist Gasfitería</h3>
								<div>
									<label>
										Aireadores (si aplica):
										<select name="aireadores" value={checklistGasfiteria.aireadores} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Botón de fluxómetro:
										<select name="boton_fluxometro" value={checklistGasfiteria.boton_fluxometro} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Cuerpo de fluxómetro (interior):
										<select name="cuerpo_fluxometro" value={checklistGasfiteria.cuerpo_fluxometro} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Lubricación piezas móviles (grasa silicona):
										<select name="lubricacion_piezas_moviles" value={checklistGasfiteria.lubricacion_piezas_moviles} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Ajuste cuerpo WC y lavamanos:
										<select name="ajuste_cuerpo_wc_lavamanos" value={checklistGasfiteria.ajuste_cuerpo_wc_lavamanos} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Ajuste físico de botón y panel:
										<select name="ajuste_fisico_boton_panel" value={checklistGasfiteria.ajuste_fisico_boton_panel} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Ajuste parámetros (control remoto):
										<select name="ajuste_parametros_control_remoto" value={checklistGasfiteria.ajuste_parametros_control_remoto} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Grifería:
										<select name="griferia" value={checklistGasfiteria.griferia} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Llave automática vanitorio:
										<select name="llave_automatica_vanitorio" value={checklistGasfiteria.llave_automatica_vanitorio} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Trizaduras:
										<select name="trizaduras" value={checklistGasfiteria.trizaduras} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Válvula descarga inodoros (si aplica):
										<select name="valvula_descarga_inodoros" value={checklistGasfiteria.valvula_descarga_inodoros} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Funcionamiento llave de paso:
										<select name="funcionamiento_llave_paso" value={checklistGasfiteria.funcionamiento_llave_paso} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Flexibles (si aplica):
										<select name="flexibles" value={checklistGasfiteria.flexibles} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Ausencia de filtraciones:
										<select name="ausencia_filtaciones" value={checklistGasfiteria.ausencia_filtaciones} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Batería y/o transformador:
										<select name="bateria_transformador" value={checklistGasfiteria.bateria_transformador} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Mariposa llave:
										<select name="mariposa_llave" value={checklistGasfiteria.mariposa_llave} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
									<label>
										Registro vertical aguas servidas (si aplica):
										<select name="registro_vertical_aguas_servidas" value={checklistGasfiteria.registro_vertical_aguas_servidas} onChange={handleChecklistChange} required>
											<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
										</select>
									</label>
								</div>
								<div>
                                        <label>
                                            Estado en que deja el equipo:
                                            <select name="estado_gasfiteria" value={checklistGasfiteria.estado_gasfiteria} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OPERATIVO">OPERATIVO</option>
                                                <option value="FALLA">FALLA</option>
                                               
                                            </select>
                                        </label>
                                    </div>
									<div>
										<label>
											Observaciones del mantenimiento:
											<input
												type="text"
												name="observaciones_gasfiteria"
												value={checklistGasfiteria.observaciones_gasfiteria || ''} 
												onChange={handleChecklistChange}
											/>
										</label>
								</div>
								<div>
									<label>
										Foto Mantenimiento:
										<input
												type="file"
												accept=".png, .jpg, .jpeg"
												name="foto_gasfiteria"
												onChange={(e) => setFotoGasfiteria(e.target.files[0])} 
												required
											/>
									</label>
								</div>
							</>
						)}
						
						{checklist === 'TABLERO' && (
						<>
							<h3>Checklist Tableros Zona Norte</h3>
							<br />
							<p><b>MANTENCIÓN SIN ENERGÍA</b></p><br />
							
							<div>
								<label>
									Revisión y asegurar con señaletica corte de energía:
									<select name="revision_senal" value={checklistTableros.revision_senal} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Revisión, limpieza interior y exterior del tablero (limpiar con paño, limpia contacto y soplador):
									<select name="revision_limpieza" value={checklistTableros.revision_limpieza} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Dispositivos interiores:
									<select name="dispositivos_interiores" value={checklistTableros.dispositivos_interiores} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Revisión estado físico de los conductores eléctricos y conexiones:
									<select name="revision_conductores" value={checklistTableros.revision_conductores} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Realizar reapriete de las conexiones eléctricas:
									<select name="reapriete" value={checklistTableros.reapriete} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Revisión y limpieza de contactores eléctricos y automáticos:
									<select name="revision_contactores" value={checklistTableros.revision_contactores} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Operar contactores y dispositivos de seguridad (protecciones):
									<select name="operar" value={checklistTableros.operar} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Revisión iluminación del recinto:
									<select name="revision_ilumina" value={checklistTableros.revision_ilumina} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Revisar estado de rotulación y señaletica del lugar:
									<select name="revision_rotulacion" value={checklistTableros.revision_rotulacion} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Limpieza general de la sala:
									<select name="limpieza_sala" value={checklistTableros.limpieza_sala} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<br />
							<p><b>MANTENCIÓN CON ENERGÍA</b></p><br />
							
							<div>
								<label>
									Revisión estado físico de los conductores eléctricos y conexiones:
									<select name="revision_conductores2" value={checklistTableros.revision_conductores2} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Realizar reapriete de conexiones eléctricas:
									<select name="reapriete2" value={checklistTableros.reapriete2} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Operar contactores y dispositivos de seguridad (protecciones):
									<select name="operar2" value={checklistTableros.operar2} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Revisión iluminación del reciento (luces piloto):
									<select name="revision_ilumina2" value={checklistTableros.revision_ilumina2} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Revisión de sistema de contactores de fuerza:
									<select name="revision_fuerza" value={checklistTableros.revision_fuerza} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Balanceo de fases:
									<select name="balanceo" value={checklistTableros.balanceo} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Consumos de Línea R (AMP):
									<input
										type="number"
										step="0.01"
										name="consumo_linea"
										value={checklistTableros.consumo_linea}
										onChange={handleChecklistChange}
										required
									/>
								</label>
							</div>
							<div>
								<label>
									Consumos de Línea S (AMP):
									<input
										type="number"
										step="0.01"
										name="consumo_linea_s"
										value={checklistTableros.consumo_linea_s}
										onChange={handleChecklistChange}
										required
									/>
								</label>
							</div>
							<div>
								<label>
									Consumos de Línea T (AMP):
									<input
										type="number"
										step="0.01"
										name="consumo_linea_t"
										value={checklistTableros.consumo_linea_t}
										onChange={handleChecklistChange}
										required
									/>
								</label>
							</div>
							<div>
								<label>
									Consumos de Línea Neutra (AMP):
									<input
										type="number"
										step="0.01"
										name="consumo_neutro"
										value={checklistTableros.consumo_neutro}
										onChange={handleChecklistChange}
										required
									/>
								</label>
							</div>
							<div>
								<label>
									Tensión Fases R (V):
									<input
										type="number"
										step="0.01"
										name="tension_fase"
										value={checklistTableros.tension_fase}
										onChange={handleChecklistChange}
										required
									/>
								</label>
							</div>
							<div>
								<label>
									Tensión Fases S (V):
									<input
										type="number"
										step="0.01"
										name="tension_fase_s"
										value={checklistTableros.tension_fase_s}
										onChange={handleChecklistChange}
										required
									/>
								</label>
							</div>
							<div>
								<label>
									Tensión Fases T (V):
									<input
										type="number"
										step="0.01"
										name="tension_fase_t"
										value={checklistTableros.tension_fase_t}
										onChange={handleChecklistChange}
										required
									/>
								</label>
							</div>
							<div>
								<label>
									Tensión Fase-Neutro R (V):
									<input
										type="number"
										step="0.01"
										name="tension_neutro"
										value={checklistTableros.tension_neutro}
										onChange={handleChecklistChange}
										required
									/>
								</label>
							</div>
							<div>
								<label>
									Tensión Fase-Neutro S (V):
									<input
										type="number"
										step="0.01"
										name="tension_neutro_s"
										value={checklistTableros.tension_neutro_s}
										onChange={handleChecklistChange}
										required
									/>
								</label>
							</div>
							<div>
								<label>
									Tensión Fase-Neutro T (V):
									<input
										type="number"
										step="0.01"
										name="tension_neutro_t"
										value={checklistTableros.tension_neutro_t}
										onChange={handleChecklistChange}
										required
									/>
								</label>
							</div>
							<div>
								<label>
									Tensión Neutro Tierra S (V):
									<input
										type="number"
										step="0.01"
										name="tension_tierra"
										value={checklistTableros.tension_tierra}
										onChange={handleChecklistChange}
										required
									/>
								</label>
							</div>
							<div>
                                        <label>
                                            Estado en que deja el equipo:
                                            <select name="estado_tableros" value={checklistTableros.estado_tableros} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OPERATIVO">OPERATIVO</option>
                                                <option value="FALLA">FALLA</option>
                                               
                                            </select>
                                        </label>
                                    </div>
									<div>
										<label>
											Observaciones del mantenimiento:
											<input
												type="text"
												name="observaciones_tableros"
												value={checklistTableros.observaciones_tableros || ''} 
												onChange={handleChecklistChange}
											/>
										</label>
								</div>
							<div>
								<label>
									Foto mantenimiento:
									<input
										type="file"
										accept=".png, .jpg, .jpeg"
										name="foto_tableros"
										onChange={handleChecklistChange}
									/>
								</label>
							</div>
						</>
					)}

					{checklist === 'EXTINTOR' && (
						<>
							<h3>Checklist Extintores</h3>
							<div>
								<label>
									Está certificado:
									<select name="esta_certificado" value={checklistExtintores.esta_certificado} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Desmontaje del exterior:
									<select name="desmontaje_exterior" value={checklistExtintores.desmontaje_exterior} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Manómetro:
									<select name="manometro" value={checklistExtintores.manometro} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Gatillo percutor:
									<select name="gatillo_percutor" value={checklistExtintores.gatillo_percutor} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Etiqueta de modo de uso:
									<select name="etiqueta_modo_uso" value={checklistExtintores.etiqueta_modo_uso} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Indicar si el extintor se encuentra en su lugar:
									<select name="extintor_en_lugar" value={checklistExtintores.extintor_en_lugar} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Indicar si se encuentra completamente cargado y operable:
									<select name="completamente_cargado" value={checklistExtintores.completamente_cargado} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Indicar si el acceso al extintor está libre de obstrucciones:
									<select name="acceso_libre_obstrucciones" value={checklistExtintores.acceso_libre_obstrucciones} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Indicar si tiene el sello de seguridad:
									<select name="sello_seguridad" value={checklistExtintores.sello_seguridad} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Indicar si tiene el pasador de seguridad:
									<select name="pasador_seguridad" value={checklistExtintores.pasador_seguridad} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Indicar si la pintura está en buen estado:
									<select name="pintura_buen_estado" value={checklistExtintores.pintura_buen_estado} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									El cilindro presenta oxidación, roturas, abolladuras, golpes o deformaciones:
									<select name="cilindro_presenta_oxidacion" value={checklistExtintores.cilindro_presenta_oxidacion} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Indicar si la manguera tiene roturas, poros, agrietamientos u obstrucciones:
									<select name="manguera_roturas" value={checklistExtintores.manguera_roturas} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Indicar si la corneta presenta fisuras, cristalización, y defectos en acoples:
									<select name="corneta_presenta_fisuras" value={checklistExtintores.corneta_presenta_fisuras} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									La válvula presenta oxidación, daños en la manija, deformaciones que impidan su funcionamiento:
									<select name="valvula_presenta_danos" value={checklistExtintores.valvula_presenta_danos} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Indicar si las calcomanías y las placas de instrucción están legibles:
									<select name="calcomanias_legibles" value={checklistExtintores.calcomanias_legibles} onChange={handleChecklistChange} required>
										<option value="">Seleccionar</option>
                                            <option value="OK">OK</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                            <option value="NA">NA</option>
									</select>
								</label>
							</div>
							<div>
                                        <label>
                                            Estado en que deja el equipo:
                                            <select name="estado_extintores" value={checklistExtintores.estado_extintores} onChange={handleChecklistChange} required>
                                                <option value="">Seleccionar</option>
                                                <option value="OPERATIVO">OPERATIVO</option>
                                                <option value="FALLA">FALLA</option>
                                               
                                            </select>
                                        </label>
                                    </div>
									<div>
										<label>
											Observaciones del mantenimiento:
											<input
												type="text"
												name="observaciones_extintores"
												value={checklistExtintores.observaciones_extintores || ''} 
												onChange={handleChecklistChange}
											/>
										</label>
								</div>
							<div>
								<label>
									Foto mantenimiento:
									<input
										type="file"
										accept=".png, .jpg, .jpeg"
										name="foto_extintores"
										onChange={handleChecklistChange}
									/>
								</label>
							</div>
						</>
					)}
					
					
								

                            <button type="submit" className="btn-create-incidencia">Guardar Checklist</button>
                        </form>
                    )}
					</div>
                </div>
            </main>
        </div>
    );
};

export default AgregarMantenimiento;