import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import '../styles/DetIncidencia.css'; 
import Menu from './Menu';


const DetEquipo = () => {
    const { id } = useParams();
	const location = useLocation(); 
    const queryParams = new URLSearchParams(location.search); 
    const tipo = queryParams.get('tipo'); 
    const [userName, setUserName] = useState('');
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [equipo, setEquipo] = useState(null);
    const [detalles, setDetalles] = useState([]);

    const [idu, setIdu] = useState(''); 

    const apiUrl = process.env.REACT_APP_API_FOTOS_INCI_SALA;
    const navigate = useNavigate();
	
	
    
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decodedToken = JSON.parse(atob(token.split('.')[1]));
            const nombre = decodeURIComponent(escape(decodedToken.nombre));
            const apellido = decodeURIComponent(escape(decodedToken.apellido));
            setIdu(decodeURIComponent(escape(decodedToken.idu))); 
            setUserName(`${nombre} ${apellido}`);
        } else {
            navigate('/');
        }
        
    
        fetchEquipo();
        fetchHistoriaEquipo();
    }, [id, tipo, navigate]);
	
	const fetchEquipo = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${process.env.REACT_APP_API_DET_EQUIPO_ZN}/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setEquipo(response.data); 
            } catch (error) {
                console.error('Error al obtener el equipo:', error);
                navigate('/');
            }
        };
		
    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/'); // Cambia esto
    };
    
    const agregaMantenimiento = () => {
        navigate(`/agrega-mantenimientozn/${id}?tipo=${tipo}`); 
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen); 
    };
    
    const fetchHistoriaEquipo = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${process.env.REACT_APP_API_HIST_EQUIPO_ZN}/${id}?tipo=${tipo}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
            setDetalles(response.data); 
        } catch (error) {
            console.error('Error al obtener la historia del equipo:', error);
        }
    };
    

    
    

    


    return (
        <div className="det-incidencia-container">
            <div className="menu-container">
                <Menu userName={userName} isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} handleLogout={handleLogout} />
            </div>
            <div className="content-container">
                <div className="incidencia-details">
                    {equipo ? (
                        <>
                            <h2>Detalles Equipo</h2>
                            <div align="right">
                                <button onClick={agregaMantenimiento} className="btn-add-incidencia">
                                    + Agregar Mantenimiento
                                </button>
                            </div>
                            <table className="details-table">
                                <tbody>
                                    <tr>
                                        <td><strong>ID Equipo:</strong></td>
                                        <td>{equipo.id_equipo}</td>
                                    </tr>
									<tr>
                                        <td><strong>Nomenclatura:</strong></td>
                                        <td>{equipo.nomenclatura}</td>
                                    </tr>
									<tr>
                                        <td><strong>Equipo:</strong></td>
                                        <td>{equipo.equipo}</td>
                                    </tr>
									
									<tr>
                                        <td><strong>Ubicación:</strong></td>
                                        <td>{equipo.instalacion}</td>
                                    </tr>
									
									<tr>
                                        <td><strong>Tipo:</strong></td>
                                        <td>{equipo.checklist}</td>
                                    </tr>
                                    <tr>
										<td><strong>Estado:</strong></td>
										<td>
											<span className={`pill ${['OK', 'OPERATIVO'].includes(equipo.estado_equipo) ? 'pill-finalized' : 'pill-active'}`}>
												{['OK', 'OPERATIVO'].includes(equipo.estado_equipo) ? 'OPERATIVO' : equipo.estado_equipo}
											</span>
										</td>
									</tr>

                                    
                                </tbody>
                            </table>
                        </>
                    ) : (
                        <p>Cargando detalles del equipo...</p>
                    )}
                </div>

                <div className="historia-incidencia">
                                       <h2>Historial de Mantenimiento</h2>
                    <table className="history-table">
                        <thead>
                            <tr>
                                <th>Fecha inicio</th>
								<th>Fecha fin</th>
                                <th>Responsable</th>
                                <th>Tipo Matenimiento</th>
                                <th>Detalles</th>
                            </tr>
                        </thead>
                        <tbody>
							{detalles.map((detalle, index) => (
								<tr key={index}>
									<td>
										{(() => {
											let detallef;

											switch (equipo.checklist) {
												case 'CLIMA':
												case 'TABLERO':	
													detallef = detalle.fecha_check;
													break;
												default:
													detallef = detalle.fecha_inicio; 	
											}

											return new Date(detallef).toLocaleString('es-ES', { 
												day: '2-digit', 
												month: '2-digit', 
												year: 'numeric', 
												hour: '2-digit', 
												minute: '2-digit', 
												second: '2-digit', 
												hour12: false 
											});
										})()}
									</td>

									<td>
										{detalle.fecha_fin ? 
											new Date(detalle.fecha_fin).toLocaleString('es-ES', { 
												day: '2-digit', 
												month: '2-digit', 
												year: 'numeric', 
												hour: '2-digit', 
												minute: '2-digit', 
												second: '2-digit', 
												hour12: false 
											}) 
											: 'REALIZANDO'}
									</td>	
									<td>{`${detalle.nombres} ${detalle.apellidos}`}</td>
									<td>{detalle.tipo_mantenimiento}</td>
									<td>
										{detalle.fecha_fin ? (
											<button 
												onClick={() => {
													let idCheck;
													switch (equipo.checklist) {
														case 'CLIMA':
															idCheck = detalle.id_check_cuatro;
															break;
														case 'ESTANQUE':
															idCheck = detalle.id_check_estanque_expzn;
															break;
														case 'EXTINTOR':
															idCheck = detalle.id_check_extintoreszn;
															break;
														case 'GASFITERIA':
															idCheck = detalle.id_check_gasfiteriazn;
															break;
														case 'ILUMINACIÓN':
															idCheck = detalle.id_luminariazn;
															break;
														case 'INFRAESTRUCTURA':
															idCheck = detalle.id_check_infraestructurazn;
															break;
														case 'TABLERO':
															idCheck = detalle.id_check_uno;
															break;														
														default:
															idCheck = null; 
													}
													if (idCheck) {
														navigate(`/detchecklist/${idCheck}?tipo=${equipo.checklist}`);
													} else {
														console.error('Tipo de mantenimiento no reconocido o ID no disponible', equipo.checklist);
													}
												}} 
												style={{ 
													backgroundColor: '#002eff', 
													color: 'white', 
													border: 'none', 
													borderRadius: '5px', 
													padding: '5px 10px', 
													cursor: 'pointer' 
												}}
											>
												&nbsp;<i className="fa fa-eye" style={{ marginRight: '5px' }}></i>
											</button>
										) : (
											// Si fecha_fin es nulo o vacío, no mostrar el botón
											<span></span> // Dejar el <td> en blanco
										)}
									</td>
								</tr>
							))}
						</tbody>
                    </table>
                    
                </div>
            </div>
        </div>
    );
};

export default DetEquipo;